// src/hooks/useUserProfileCheck.js
import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const REQUIRED_FIELDS = ['displayName', 'business', 'businessType', 'foundUs'];

export default function useUserProfileCheck({ user, loadingAuth, authError, db, contacts }) {
  const navigate = useNavigate();

  const [profileChecked, setProfileChecked] = useState(false);
  const [missingOptional, setMissingOptional] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [profileError, setProfileError] = useState('');

  useEffect(() => {
    const checkUserProfile = async () => {
      if (!user) return; // user not logged in yet

      try {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (!userSnap.exists()) return; // user doc doesn't exist

        const data = userSnap.data() || {};
        // Check if any required field is missing
        const isIncomplete = REQUIRED_FIELDS.some(
          (field) => !data[field] || data[field].trim() === ''
        );

        if (isIncomplete) {
          navigate('/dashboard');
          return;
        }

        // Check optional fields
        const optionalMissing =
          !data.phone && !data.about && (!data.address || !data.address.country);
        setMissingOptional(optionalMissing);
      } catch (err) {
        console.error('Error checking user profile:', err);
        setProfileError('Failed to load profile data.');
      } finally {
        setProfileChecked(true);
      }
    };

    // We only run this if user is defined or we know it's not loading.
    if (user && !loadingAuth) {
      checkUserProfile();
    } else if (!user && !loadingAuth) {
      // Not logged in
      setProfileChecked(true);
    }
  }, [user, loadingAuth, db, navigate]);

  // Determine if user is brand-new (i.e., has no contacts yet)
  useEffect(() => {
    if (profileChecked && user) {
      const hasContacts = contacts.length > 0;
      setIsNewUser(!hasContacts);
    }
  }, [contacts, profileChecked, user]);

  return {
    loadingAuth,
    authError,
    profileChecked,
    missingOptional,
    isNewUser,
    profileError,
  };
}
