// File: src/components/Navbar/BigResourcesMegaMenu.jsx

import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  Box,
  Menu,
  Fade,
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Grow,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  Link as MuiLink,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js'; // adjust if needed
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import BookIcon from '@mui/icons-material/Book';
import MapIcon from '@mui/icons-material/Map';
import BuildIcon from '@mui/icons-material/Build';
import { Link as RouterLink } from 'react-router-dom';

/**
 * Custom hook to debounce a value.
 */
function useDebounce(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

/**
 * Card component for a single resource.
 */
function ResourceCard({ title, description, url, onClose, index }) {
  return (
    <Grow
      in
      style={{ transformOrigin: '0 0 0' }}
      timeout={500 + index * 100}
    >
      <Box
        component="a"
        href={url}
        onClick={onClose}
        sx={{
          textDecoration: 'none',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          display: 'block',
          color: 'text.primary',
          transition: 'border-color 0.2s, box-shadow 0.2s',
          backgroundColor: 'background.paper',
          '&:hover': {
            borderColor: 'primary.main',
            boxShadow: 4,
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
          {description}
        </Typography>
      </Box>
    </Grow>
  );
}

/**
 * Helper function to filter resources based on title and description.
 */
function filterResources(items, query) {
  if (!query.trim()) return items;
  const lowerQuery = query.toLowerCase();
  return items.filter(
    (item) =>
      item.title.toLowerCase().includes(lowerQuery) ||
      item.description.toLowerCase().includes(lowerQuery)
  );
}

/**
 * Definition of resource tabs.
 */
const RESOURCE_TABS = [
  { label: 'Learn', value: 'learn', icon: <BookIcon fontSize="small" /> },
  { label: 'Discover', value: 'discover', icon: <MapIcon fontSize="small" /> },
  { label: 'Services', value: 'services', icon: <BuildIcon fontSize="small" /> },
];

/**
 * Search bar component for resources.
 */
function ResourceSearchBar({ searchTerm, onSearchChange }) {
  return (
    <TextField
      size="small"
      placeholder="Search resources..."
      value={searchTerm}
      onChange={onSearchChange}
      fullWidth
      sx={{
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.grey[50],
        boxShadow: 2,
        borderRadius: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />
  );
}

/**
 * Tabs component for resource categories.
 */
function ResourceTabs({ activeTab, onTabChange }) {
  return (
    <ButtonGroup variant="contained" sx={{ borderRadius: 2 }}>
      {RESOURCE_TABS.map((tab) => {
        const isActive = activeTab === tab.value;
        return (
          <Button
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            startIcon={tab.icon}
            sx={{
              textTransform: 'none',
              fontWeight: isActive ? 'bold' : 'medium',
              color: isActive ? 'white' : 'text.primary',
              backgroundColor: isActive ? 'primary.main' : 'inherit',
              '&:hover': {
                backgroundColor: isActive ? 'primary.dark' : 'grey.200',
              },
            }}
          >
            {tab.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

/**
 * Main component for the Resources Mega Menu.
 */
export default function BigResourcesMegaMenu({ anchorEl, open, onClose }) {
  const [activeTab, setActiveTab] = useState('learn');
  const [searchTerm, setSearchTerm] = useState('');
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Debounce search term to limit filtering frequency.
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Use a ref to avoid re-fetching resources every time the menu opens.
  const fetchedOnceRef = useRef(false);

  // Fetch resources from Firestore when the menu opens.
  useEffect(() => {
    async function fetchResources() {
      setLoading(true);
      setError(null);
      try {
        const snap = await getDocs(collection(db, 'resources'));
        const loaded = snap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setResources(loaded);
        fetchedOnceRef.current = true;
      } catch (err) {
        console.error('Error fetching resources:', err);
        setError('Unable to load resources. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    if (open && !fetchedOnceRef.current) {
      fetchResources();
    }
  }, [open]);

  // Filter resources based on active tab.
  const tabFilteredResources = useMemo(() => {
    return resources.filter((r) => r.category === activeTab);
  }, [resources, activeTab]);

  // Further filter resources based on the debounced search term.
  const displayedResources = useMemo(() => {
    return filterResources(tabFilteredResources, debouncedSearchTerm);
  }, [tabFilteredResources, debouncedSearchTerm]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: 900,
          maxWidth: '95vw',
          p: 0,
          borderRadius: 3,
          boxShadow: 8,
          maxHeight: '75vh',
          overflowY: 'auto',
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          background: (theme) =>
            `linear-gradient(120deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[100]} 100%)`,
        }}
      >
        {/* Top row: Search bar + Tabs */}
        <Box
          mb={3}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'stretch', md: 'center' }}
          justifyContent="space-between"
          gap={2}
        >
          <ResourceSearchBar
            searchTerm={searchTerm}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
          />
          <ResourceTabs activeTab={activeTab} onTabChange={setActiveTab} />
        </Box>

        {/* Loading and Error States */}
        {loading && (
          <Box textAlign="center" py={3}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {!loading && !error && (
          <>
            {displayedResources.length === 0 && (
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
                sx={{ py: 2 }}
              >
                No matching resources found.
              </Typography>
            )}

            <Fade in={!loading} timeout={350}>
              <Box>
                <Grid container spacing={2}>
                  {displayedResources.map((res, idx) => (
                    <Grid key={res.id} item xs={12} sm={6} md={4}>
                      <ResourceCard
                        title={res.title}
                        description={res.description}
                        url={res.url}
                        onClose={onClose}
                        index={idx}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Fade>
          </>
        )}

        {/* CTA Row */}
        <Divider sx={{ mt: 4, mb: 3 }} />
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            endIcon={<ArrowForwardIcon />}
            component={RouterLink}
            to="/signup"
          >
            Start Free
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            endIcon={<ArrowForwardIcon />}
            component={RouterLink}
            to="/contact-sales"
          >
            Contact Sales
          </Button>
        </Box>
      </Box>
    </Menu>
  );
}
