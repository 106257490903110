import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Paper,
  InputBase,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ClickAwayListener,
  Fade,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

// Helper functions (getPrimaryText and highlightMatch) remain the same...
const getPrimaryText = (result) => {
  return result.name || result.title || result.type || result.objectType || '';
};

function highlightMatch(text, query) {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} style={{ fontWeight: 'bold', color: '#1976d2' }}>
        {part}
      </span>
    ) : (
      part
    )
  );
}

function NavbarSearchBar({ userId, mobile = false }) {
  // ... state and data fetching (unchanged) ...
  const [inputValue, setInputValue] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [allObjects, setAllObjects] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();
  const maxVisibleResults = 10;

  // For mobile: whether the overlay is expanded.
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (userId) {
      const stored = localStorage.getItem(`recentSearches_${userId}`);
      if (stored) {
        setRecentSearches(JSON.parse(stored));
      }
    }
  }, [userId]);

  useEffect(() => {
    if (!userId) return;
    const q = query(collection(db, 'objects'), where('userId', '==', userId));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const objs = snapshot.docs.map((doc) => {
          const data = doc.data();
          const searchable = [data.name, data.title, data.type, data.objectType]
            .map((field) => (field ? field.toLowerCase() : ''))
            .join(' ');
          return { id: doc.id, ...data, _searchable: searchable };
        });
        setAllObjects(objs);
      },
      (error) => {
        console.error('Error fetching objects:', error);
      }
    );
    return () => unsubscribe();
  }, [userId]);

  const debouncedUpdate = useCallback(
    _.debounce((value) => {
      setDebouncedQuery(value);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedUpdate(inputValue);
    return () => debouncedUpdate.cancel();
  }, [inputValue, debouncedUpdate]);

  const filteredResults = useMemo(() => {
    if (!debouncedQuery.trim()) return [];
    const lowerQuery = debouncedQuery.toLowerCase();
    return allObjects.filter((obj) => obj._searchable.includes(lowerQuery));
  }, [allObjects, debouncedQuery]);

  const dropdownItems = debouncedQuery.trim() ? filteredResults : recentSearches;
  useEffect(() => {
    setSelectedIndex(-1);
  }, [dropdownItems]);

  const addRecentSearch = (term) => {
    if (!term.trim()) return;
    setRecentSearches((prev) => {
      const updated = [term, ...prev.filter((t) => t !== term)];
      const limited = updated.slice(0, 5);
      localStorage.setItem(`recentSearches_${userId}`, JSON.stringify(limited));
      return limited;
    });
  };

  // === Event Handlers ===
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setDropdownOpen(true);
  };

  const handleKeyDown = (e) => {
    if (!dropdownOpen) return;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex((prev) => Math.min(prev + 1, dropdownItems.length - 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex((prev) => Math.max(prev - 1, 0));
    } else if (e.key === 'Enter') {
      if (selectedIndex >= 0 && selectedIndex < dropdownItems.length) {
        e.preventDefault();
        const selectedItem = dropdownItems[selectedIndex];
        if (debouncedQuery.trim()) {
          addRecentSearch(debouncedQuery);
          setInputValue('');
          setDebouncedQuery('');
          setDropdownOpen(false);
          setExpanded(false);
          navigate(`/edit/${selectedItem.objectType}/${selectedItem.id}`);
        } else {
          setInputValue(selectedItem);
          setDropdownOpen(true);
        }
      }
    } else if (e.key === 'Escape') {
      setDropdownOpen(false);
      setSelectedIndex(-1);
    }
  };

  const handleClear = () => {
    setInputValue('');
    setDebouncedQuery('');
    setDropdownOpen(false);
  };

  const handleClearHistory = () => {
    localStorage.removeItem(`recentSearches_${userId}`);
    setRecentSearches([]);
  };

  // === Rendering ===
  // Mobile collapsed mode: return just the search icon as an IconButton.
  if (mobile && !expanded) {
    return (
      <IconButton onClick={() => setExpanded(true)} aria-label="open search" sx={{ color: 'white' }}>
        <SearchIcon />
      </IconButton>
    );
  }

  // Build the search input and dropdown as before.
  const searchInput = (
    <Paper
      component="form"
      role="combobox"
      aria-expanded={dropdownOpen}
      sx={{
        height: 40,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: 2,
        boxShadow: 1,
        backgroundColor: 'background.paper',
        px: 2,
      }}
      onSubmit={(e) => e.preventDefault()}
    >
      <InputBase
        role="searchbox"
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search objects' }}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setDropdownOpen(true)}
        onKeyDown={handleKeyDown}
      />
      {inputValue && (
        <IconButton onClick={handleClear} sx={{ p: '10px' }} aria-label="clear search">
          <ClearIcon />
        </IconButton>
      )}
      <IconButton type="submit" sx={{ p: '10px', color: 'inherit' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );

  const renderDropdownItems = () => {
    if (debouncedQuery.trim()) {
      if (filteredResults.length > 0) {
        const visibleResults = filteredResults.slice(0, maxVisibleResults);
        return (
          <>
            <List role="listbox" dense disablePadding>
              {visibleResults.map((result, index) => {
                const primaryText = getPrimaryText(result);
                return (
                  <ListItem key={result.id} disablePadding>
                    <ListItemButton
                      role="option"
                      aria-selected={index === selectedIndex}
                      selected={index === selectedIndex}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: 'action.hover' },
                      }}
                      onClick={() => {
                        addRecentSearch(debouncedQuery);
                        setInputValue('');
                        setDebouncedQuery('');
                        setDropdownOpen(false);
                        setExpanded(false);
                        navigate(`/edit/${result.objectType}/${result.id}`);
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography>
                            {highlightMatch(primaryText, debouncedQuery)}
                          </Typography>
                        }
                        secondary={result.objectType}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            {filteredResults.length > maxVisibleResults && (
              <Box sx={{ textAlign: 'center', mt: 1 }}>
                <Button
                  size="small"
                  onClick={() => {
                    setDropdownOpen(false);
                    setExpanded(false);
                    navigate(`/search?query=${encodeURIComponent(debouncedQuery)}`);
                  }}
                >
                  View All Results
                </Button>
              </Box>
            )}
          </>
        );
      } else {
        return (
          <Typography variant="body2" sx={{ p: 1, textAlign: 'center' }}>
            No results found. Try a different keyword.
          </Typography>
        );
      }
    } else {
      if (recentSearches.length > 0) {
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 1,
                py: 0.5,
                borderBottom: '1px solid',
                borderColor: 'divider',
              }}
            >
              <Typography variant="caption" color="text.secondary">
                Recent Searches
              </Typography>
              <Button onClick={handleClearHistory} size="small">
                Clear History
              </Button>
            </Box>
            <List role="listbox" dense disablePadding>
              {recentSearches.map((term, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    role="option"
                    aria-selected={index === selectedIndex}
                    selected={index === selectedIndex}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: 'action.hover' },
                    }}
                    onClick={() => {
                      setInputValue(term);
                      setDropdownOpen(true);
                    }}
                  >
                    <ListItemText primary={term} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        );
      }
    }
    return null;
  };

  const dropdownContent = renderDropdownItems();

  // For mobile expanded mode, render a full-screen overlay.
  if (mobile && expanded) {
    return (
      <ClickAwayListener onClickAway={() => setExpanded(false)}>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
            backgroundColor: 'rgba(0,0,0,0.8)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              px: 2,
              pt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography variant="h6" color="white">
              Search
            </Typography>
            <Button variant="text" color="inherit" onClick={() => setExpanded(false)}>
              Cancel
            </Button>
          </Box>
          <Box sx={{ width: '100%', p: 2, position: 'relative' }}>
            {searchInput}
            {dropdownOpen && dropdownContent && (
              <Fade in={dropdownOpen}>
                <Paper
                  sx={{
                    position: 'absolute',
                    top: '55px',
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    maxHeight: 300,
                    overflowY: 'auto',
                    mt: 0.5,
                    borderRadius: 2,
                    boxShadow: 3,
                    p: 1,
                  }}
                  elevation={3}
                >
                  {dropdownContent}
                </Paper>
              </Fade>
            )}
          </Box>
        </Box>
      </ClickAwayListener>
    );
  }

  // Desktop content.
  const desktopContent = (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <Box sx={{ position: 'relative', mt: 1, mb: 1, mr: 2 }}>
        {searchInput}
        {dropdownOpen && dropdownContent && (
          <Fade in={dropdownOpen}>
            <Paper
              sx={{
                position: 'absolute',
                top: '48px',
                left: 0,
                right: 0,
                zIndex: 1,
                maxHeight: 300,
                overflowY: 'auto',
                mt: 0.5,
                borderRadius: 2,
                boxShadow: 3,
                p: 1,
              }}
              elevation={3}
            >
              {dropdownContent}
            </Paper>
          </Fade>
        )}
      </Box>
    </ClickAwayListener>
  );

  return mobile ? null : desktopContent;
}

export default NavbarSearchBar;
