import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Menu,
  MenuList,
  MenuItem,
  Box,
  Grid,
  Typography,
  Link as MuiLink,
  CircularProgress,
  Alert,
  Button,
  TextField,
  InputAdornment,
  Fade,
  Chip,
  Divider,
  Autocomplete,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

/* ========================================================================
   Constants & Utility Functions
   ======================================================================== */

// Predefined plan options and hierarchy (lower index = lower tier).
const planOptions = ['Free','Basic', 'Pro', 'Enterprise'];
const planOrder = ['Free', 'Basic', 'Pro', 'Enterprise'];

// Categories to highlight from a marketing perspective.
const FEATURED_CATEGORIES = new Set(['AI', 'Automation']);

/**
 * Custom hook to debounce a changing value.
 */
function useDebounce(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

/**
 * Splits an array into N roughly equal chunks.
 */
function chunkCategories(categoriesArray, columns) {
  const perColumn = Math.ceil(categoriesArray.length / columns);
  const chunks = [];
  for (let i = 0; i < columns; i++) {
    const start = i * perColumn;
    chunks.push(categoriesArray.slice(start, start + perColumn));
  }
  return chunks;
}

/**
 * Highlights the first occurrence of `query` inside `text`.
 */
function HighlightedText({ text, query }) {
  if (!query) return <>{text}</>;

  const lowerText = text.toLowerCase();
  const lowerQuery = query.toLowerCase();
  const index = lowerText.indexOf(lowerQuery);
  if (index === -1) return <>{text}</>;

  const before = text.slice(0, index);
  const match = text.slice(index, index + query.length);
  const after = text.slice(index + query.length);

  return (
    <>
      {before}
      <strong>{match}</strong>
      {after}
    </>
  );
}

/**
 * Determines whether a feature matches the selected plan filters.
 *
 * If no plan filter is selected, returns true. Otherwise, a feature qualifies
 * if it has at least one available plan that is equal to or higher than the
 * highest (most advanced) selected plan.
 */
function featureMatchesPlan(feature, selectedPlans) {
  if (!selectedPlans || selectedPlans.length === 0) return true;
  const selectedMaxIndex = Math.max(...selectedPlans.map((p) => planOrder.indexOf(p)));
  return feature.availableInPlans?.some(
    (plan) => planOrder.indexOf(plan) >= selectedMaxIndex
  );
}

/* ========================================================================
   Presentational Components
   ======================================================================== */

const FIXED_HEIGHT = '40px';

/**
 * Search bar component.
 */
const SearchBar = ({ searchTerm, onSearchChange, onClear, inputRef }) => (
  <Box
    sx={{
      mb: 0,
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      p: 1,
      borderRadius: 1,
      backgroundColor: (theme) => theme.palette.grey[50],
    }}
  >
    <TextField
      size="small"
      placeholder="Search features..."
      value={searchTerm}
      onChange={onSearchChange}
      variant="outlined"
      fullWidth
      inputRef={inputRef}
      sx={{
        '& .MuiOutlinedInput-root': { height: FIXED_HEIGHT },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      aria-label="Search features"
    />
    {searchTerm && (
      <Button onClick={onClear} color="inherit">
        Clear
      </Button>
    )}
  </Box>
);

/**
 * Renders a single feature as a menu item.
 */
const FeatureItem = ({ feature, searchTerm, onClose }) => (
  <MenuItem
    key={feature.id}
    component={Link}
    to={`/features/${feature.id}`}
    onClick={onClose}
    sx={{
      py: 0.5,
      px: 0,
      borderRadius: 1,
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'primary.main',
        textDecoration: 'underline',
      },
    }}
  >
    {feature.iconUrl && (
      <Box
        component="img"
        src={feature.iconUrl}
        alt={feature.title}
        sx={{ width: 18, height: 18, objectFit: 'contain', mr: 1 }}
      />
    )}
    <MuiLink
      component="span"
      underline="hover"
      sx={{ display: 'inline-block', width: '100%', color: 'text.primary' }}
    >
      <HighlightedText text={feature.title || 'Untitled Feature'} query={searchTerm} />
      {feature.requiredPlan && (
        <Typography
          variant="caption"
          component="span"
          sx={{ ml: 1, color: 'error.main', fontWeight: 'bold' }}
        >
          (Requires {feature.requiredPlan} Plan)
        </Typography>
      )}
    </MuiLink>
  </MenuItem>
);

/**
 * Renders a category header along with its list of features.
 */
const FeatureCategory = ({ category, features, searchTerm, onClose, isFeatured }) => (
  <Box mb={3}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        mb: 1,
        borderLeft: (theme) =>
          `4px solid ${isFeatured ? theme.palette.warning.main : theme.palette.grey[400]}`,
        pl: 1,
      }}
    >
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        {category}
      </Typography>
      {isFeatured && (
        <Chip
          label="Featured"
          icon={<StarIcon />}
          color="warning"
          size="small"
          sx={{ ml: 1 }}
        />
      )}
    </Box>
    <MenuList dense disablePadding>
      {features.map((feature) => (
        <FeatureItem
          key={feature.id}
          feature={feature}
          searchTerm={searchTerm}
          onClose={onClose}
        />
      ))}
    </MenuList>
  </Box>
);

/* ========================================================================
   Main Component: BigProductMegaMenu
   ======================================================================== */

export default function BigProductMegaMenu({ anchorEl, open, onClose }) {
  // Feature fetching state.
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchedOnceRef = useRef(false);

  // Search and filter state.
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [planFilter, setPlanFilter] = useState([]);

  /**
   * Fetch features from Firestore.
   */
  const fetchFeatures = async () => {
    setLoading(true);
    setError(null);
    try {
      const snapshot = await getDocs(collection(db, 'crmFeatures'));
      const loadedFeatures = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFeatures(loadedFeatures);
    } catch (err) {
      console.error('Error fetching CRM features:', err);
      setError('Unable to load features. Please try again later.');
    } finally {
      setLoading(false);
      fetchedOnceRef.current = true;
    }
  };

  // Fetch data and focus the search field when the menu opens.
  useEffect(() => {
    if (open && anchorEl && !fetchedOnceRef.current) {
      fetchFeatures();
    }
    if (open) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 200);
    }
  }, [open, anchorEl]);

  const handleRetry = () => {
    fetchedOnceRef.current = false;
    fetchFeatures();
  };

  /**
   * Filter features based on search term and plan filter.
   */
  const filteredFeatures = useMemo(() => {
    let result = features;
    const query = debouncedSearchTerm.trim().toLowerCase();
    if (query) {
      result = result.filter((feat) => {
        const title = feat.title?.toLowerCase() || '';
        const desc = feat.description?.toLowerCase() || '';
        return title.includes(query) || desc.includes(query);
      });
    }
    if (planFilter.length > 0) {
      result = result.filter((feat) => featureMatchesPlan(feat, planFilter));
    }
    return result;
  }, [features, debouncedSearchTerm, planFilter]);

  /**
   * Group features by their first module (or "Other" if not provided),
   * then sort the module categories alphabetically.
   */
  const groupedByModule = useMemo(() => {
    const map = new Map();
    filteredFeatures.forEach((feat) => {
      const moduleCategory =
        feat.modules && feat.modules.length > 0 ? feat.modules[0] : 'Other';
      if (!map.has(moduleCategory)) {
        map.set(moduleCategory, []);
      }
      map.get(moduleCategory).push(feat);
    });
    return Array.from(map.entries()).sort((a, b) =>
      a[0].localeCompare(b[0])
    );
  }, [filteredFeatures]);

  // Evenly split the module categories into 3 columns.
  const columns = useMemo(() => {
    const moduleNames = groupedByModule.map(([name]) => name);
    return chunkCategories(moduleNames, 3);
  }, [groupedByModule]);

  // Helper to retrieve features for a given module category.
  const getFeaturesByCategory = (moduleName) => {
    const found = groupedByModule.find(([name]) => name === moduleName);
    return found ? found[1] : [];
  };

  const totalFeatures = features.length;
  const totalFiltered = filteredFeatures.length;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          // Use a full-width layout on extra-small screens, and 900px on medium and larger screens.
          width: { xs: '95vw', md: '900px' },
          maxWidth: '95vw',
          p: 0,
          borderRadius: 2,
          boxShadow: 6,
          maxHeight: '75vh',
          overflowY: 'auto',
          background: (theme) =>
            `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        },
      }}
      MenuListProps={{ 'aria-label': 'Feature categories' }}
    >
      <Box sx={{ p: { xs: 2, md: 2 }, backgroundColor: 'background.paper' }}>
        {/* Container with search and filter in line */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row', // Always keep in line
            alignItems: 'center',
            gap: 2,
            mb: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={(e) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm('')}
              inputRef={searchInputRef}
            />
          </Box>
          <Box sx={{ minWidth: { xs: '150px', md: 250 } }}>
            <Autocomplete
              multiple
              options={planOptions}
              value={planFilter}
              onChange={(event, newValue) => setPlanFilter(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select plan(s)"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': { height: FIXED_HEIGHT },
                    '& .MuiInputLabel-root': {
                      lineHeight: FIXED_HEIGHT,
                      transform: 'translate(14px, 0)',
                    },
                  }}
                />
              )}
            />
          </Box>
        </Box>

        {/* Loading Indicator */}
        {loading && !error && (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size={24} />
          </Box>
        )}

        {/* Error Message */}
        {error && (
          <Box sx={{ py: 1 }}>
            <Alert severity="error" sx={{ mb: 1 }}>
              {error}
            </Alert>
            <Button variant="contained" startIcon={<RefreshIcon />} onClick={handleRetry}>
              Retry
            </Button>
          </Box>
        )}

        {/* Main Content */}
        {!loading && !error && (
          <>
            {totalFeatures === 0 ? (
              <Box textAlign="center" py={2}>
                <Typography variant="body1">
                  No features found. Please try again later.
                </Typography>
              </Box>
            ) : totalFiltered === 0 ? (
              <Box textAlign="center" py={2}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  No matching features found for “{searchTerm}”.
                </Typography>
                <Button variant="text" onClick={() => setSearchTerm('')} size="small">
                  Reset search
                </Button>
              </Box>
            ) : (
              <>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  {columns.map((moduleChunk, idx) => (
                    <Grid item xs={12} sm={4} key={idx}>
                      {moduleChunk.map((moduleName) => {
                        const featsInModule = getFeaturesByCategory(moduleName);
                        if (!featsInModule.length) return null;
                        const isFeatured = FEATURED_CATEGORIES.has(moduleName);
                        return (
                          <FeatureCategory
                            key={moduleName}
                            category={moduleName}
                            features={featsInModule}
                            searchTerm={searchTerm}
                            onClose={onClose}
                            isFeatured={isFeatured}
                          />
                        );
                      })}
                    </Grid>
                  ))}
                </Grid>

                <Divider sx={{ my: { xs: 2, md: 3 } }} />

                {/* Marketing CTA */}
                <Box textAlign="center" mb={2}>
                  <Box
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent="center"
                    gap={2}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClose}
                      component={Link}
                      to="/signup"
                      endIcon={<ArrowForwardIcon />}
                    >
                      Start Free
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={onClose}
                      component={Link}
                      to="/features"
                      endIcon={<ArrowForwardIcon />}
                    >
                      All Features
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Menu>
  );
}
