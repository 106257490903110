// File: src/components/Navbar/MobileMenu.jsx

import React, { useState, useEffect } from 'react';
import {
  Menu,
  Divider,
  Box,
  Typography,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, auth } from '/Users/willradley/orthinas-crm/src/firebase.js';
import { signOut } from 'firebase/auth';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeleteIcon from '@mui/icons-material/Delete';

// A simple UserGreeting component.
function UserGreeting({ userNameOrEmail, userEmail }) {
  return (
    <Box px={2} pb={1}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
        Hello, {userNameOrEmail}
      </Typography>
      {userEmail && (
        <Typography variant="caption" color="text.secondary">
          {userEmail}
        </Typography>
      )}
    </Box>
  );
}

export default function MobileMenu(props) {
  const { anchorEl, open, onClose, currentUser, userNameOrEmail, userEmail } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // --------------------------------------------------------------------------
  // Fetch solutions (from Firestore) – these represent modules like "Sales", etc.
  // --------------------------------------------------------------------------
  const [solutions, setSolutions] = useState([]);
  useEffect(() => {
    const solutionsQuery = query(
      collection(db, 'solutions'),
      where('type', '==', 'product')
    );
    const unsubscribe = onSnapshot(
      solutionsQuery,
      (snapshot) => {
        const solData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSolutions(solData);
      },
      (error) => {
        console.error('Error fetching solutions:', error);
      }
    );
    return () => unsubscribe();
  }, []);

  // --------------------------------------------------------------------------
  // Fetch object types – these are your individual lists (like "Docs for Sales")
  // Filter by the current user's UID.
  // --------------------------------------------------------------------------
  const [objectTypes, setObjectTypes] = useState([]);
  useEffect(() => {
    if (!currentUser?.uid) return;
    const userId = currentUser.uid;
    const objectTypesQuery = query(
      collection(db, 'objectTypes'),
      where('userId', '==', userId)
    );
    const unsubscribe = onSnapshot(
      objectTypesQuery,
      (snapshot) => {
        const typesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setObjectTypes(typesData);
      },
      (error) => {
        console.error('Error fetching objectTypes:', error);
      }
    );
    return () => unsubscribe();
  }, [currentUser]);

  // --------------------------------------------------------------------------
  // Helper: For a given solution title, return all matching object types.
  // We match based on the "module" field (case-insensitive).
  // --------------------------------------------------------------------------
  const getSubItemsForSolution = (solutionTitle) => {
    return objectTypes.filter(
      (ot) =>
        ot.module &&
        ot.module.toLowerCase() === solutionTitle.toLowerCase()
    );
  };

  // --------------------------------------------------------------------------
  // Build nav items: Always include "Dashboard" first; then, for each solution,
  // attach matching object types as sub‑items.
  // --------------------------------------------------------------------------
  const navItems = [
    {
      label: 'Dashboard',
      path: '/dashboard',
      hasSubmenu: false,
    },
    ...solutions.map((solution) => {
      const subItems = getSubItemsForSolution(solution.title);
      return {
        label: solution.title, // e.g. "Sales"
        path: `/dashboard?view=${encodeURIComponent(solution.title)}`,
        submenu: subItems,
        hasSubmenu: subItems.length > 0,
      };
    }),
  ];

  // --------------------------------------------------------------------------
  // Filter out any system object types from submenus so they don't appear twice.
  // (If an object type has system === true, it will not be shown in the mobile menu.)
  // --------------------------------------------------------------------------
  const filteredNavItems = navItems.map((item) => {
    if (item.hasSubmenu) {
      const filteredSubmenu = item.submenu.filter((ot) => !ot.system);
      return { ...item, submenu: filteredSubmenu, hasSubmenu: filteredSubmenu.length > 0 };
    }
    return item;
  });

  // --------------------------------------------------------------------------
  // Handle logout action.
  // --------------------------------------------------------------------------
  const handleLogout = async () => {
    try {
      await signOut(auth);
      onClose();
      navigate('/signin', { replace: true });
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{
        '& .MuiMenu-paper': {
          width: isMobile ? '100%' : 260,
          p: 1,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
          backgroundColor: '#212121',
          color: '#fff',
          '& .MuiMenuItem-root': {
            color: '#fff',
            '&:hover': { backgroundColor: '#424242' },
          },
          '& .MuiDivider-root': {
            backgroundColor: 'rgba(255,255,255,0.2)',
          },
        },
      }}
      aria-label="Mobile menu"
    >
      {currentUser && (
        <>
          <UserGreeting userNameOrEmail={userNameOrEmail} userEmail={userEmail} />
          <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.2)' }} />
        </>
      )}

      {/* Render the new nav items */}
      {filteredNavItems.map((item) => (
        <Box key={item.label}>
          <MenuItem
            component={Link}
            to={item.path}
            onClick={onClose}
            sx={{ color: '#fff' }}
          >
            {item.label}
          </MenuItem>
          {item.hasSubmenu &&
            item.submenu.map((subItem) => (
              <MenuItem
                key={subItem.id}
                component={Link}
                to={`/list/${subItem.name || subItem.id}`}
                onClick={onClose}
                sx={{ color: '#fff', pl: 4 }} // indent sub-items to show hierarchy
              >
                {subItem.title || subItem.name || 'Unnamed'}
              </MenuItem>
            ))}
          <Divider sx={{ my: 1, backgroundColor: 'rgba(255,255,255,0.2)' }} />
        </Box>
      ))}

      {/* Additional static items */}
      <MenuItem
        component={Link}
        to="/account"
        onClick={onClose}
        sx={{
          background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
          borderRadius: '20px',
          color: 'white',
          mx: 1,
          my: 1,
          textTransform: 'none',
          fontWeight: 'bold',
          '&:hover': {
            background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
          },
        }}
      >
        Upgrade
      </MenuItem>
      <MenuItem
        component={Link}
        to="/account"
        onClick={onClose}
        sx={{ color: '#fff' }}
      >
        Your Account
      </MenuItem>
      <MenuItem
        component={Link}
        to="/settings"
        onClick={onClose}
        sx={{ color: '#fff' }}
      >
        Settings
      </MenuItem>
      <MenuItem onClick={handleLogout} sx={{ color: '#fff' }}>
        Logout
      </MenuItem>
    </Menu>
  );
}
