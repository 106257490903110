// File: src/pages/admin/MeetingsTab.jsx
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  TextField,
  TableSortLabel,
  TablePagination,
  IconButton,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';

export default function MeetingsTab() {
  const [meetings, setMeetings] = useState([]);
  const [meetingsLoading, setMeetingsLoading] = useState(true);
  const [meetingsError, setMeetingsError] = useState(null);
  const [meetingFilterText, setMeetingFilterText] = useState('');
  const [meetingSortConfig, setMeetingSortConfig] = useState({ key: 'meetingDate', direction: 'asc' });
  const [meetingPage, setMeetingPage] = useState(0);
  const [meetingRowsPerPage, setMeetingRowsPerPage] = useState(10);

  const fetchMeetings = async () => {
    setMeetingsLoading(true);
    try {
      const meetingsRef = collection(db, 'requestedMeetings');
      const q = query(meetingsRef, orderBy('meetingDate', meetingSortConfig.direction));
      const querySnapshot = await getDocs(q);
      const meetingsData = querySnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
      setMeetings(meetingsData);
      setMeetingsError(null);
    } catch (err) {
      console.error(err);
      setMeetingsError('Failed to load scheduled meetings.');
    } finally {
      setMeetingsLoading(false);
    }
  };

  useEffect(() => {
    fetchMeetings();
  }, [meetingSortConfig]);

  const handleMeetingSort = useCallback((key) => {
    setMeetingSortConfig(prev => {
      const direction = prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  }, []);

  const filteredMeetings = useMemo(() => {
    if (!meetingFilterText) return meetings;
    return meetings.filter(meeting =>
      (meeting.name || '').toLowerCase().includes(meetingFilterText.toLowerCase()) ||
      (meeting.email || '').toLowerCase().includes(meetingFilterText.toLowerCase())
    );
  }, [meetings, meetingFilterText]);

  const paginatedMeetings = useMemo(() => {
    const start = meetingPage * meetingRowsPerPage;
    return filteredMeetings.slice(start, start + meetingRowsPerPage);
  }, [filteredMeetings, meetingPage, meetingRowsPerPage]);

  const handleDeleteMeeting = async (id) => {
    try {
      await deleteDoc(doc(db, 'requestedMeetings', id));
      setMeetings(prev => prev.filter(meeting => meeting.id !== id));
    } catch (err) {
      console.error(err);
      alert('Error deleting meeting.');
    }
  };

  const exportMeetings = () => {
    alert('Export not implemented.');
  };

  return (
    <Box>
      <Typography variant="h5">Meetings</Typography>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1} mb={2}>
        <Button variant="outlined" onClick={fetchMeetings} sx={{ mr: 1 }}>Refresh</Button>
        <Button variant="contained" color="primary" onClick={exportMeetings}>Export</Button>
      </Box>
      <TextField
        label="Search Meetings"
        variant="outlined"
        fullWidth
        value={meetingFilterText}
        onChange={(e) => setMeetingFilterText(e.target.value)}
        sx={{ mb: 2 }}
      />
      {meetingsLoading ? (
        <Box textAlign="center" my={4}>
          <CircularProgress />
        </Box>
      ) : meetingsError ? (
        <Alert severity="error">{meetingsError}</Alert>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={meetingSortConfig.key === 'id'}
                      direction={meetingSortConfig.direction}
                      onClick={() => handleMeetingSort('id')}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={meetingSortConfig.key === 'name'}
                      direction={meetingSortConfig.direction}
                      onClick={() => handleMeetingSort('name')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={meetingSortConfig.key === 'meetingDate'}
                      direction={meetingSortConfig.direction}
                      onClick={() => handleMeetingSort('meetingDate')}
                    >
                      Meeting Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedMeetings.map(meeting => (
                  <TableRow key={meeting.id}>
                    <TableCell>{meeting.id}</TableCell>
                    <TableCell>{meeting.name || 'N/A'}</TableCell>
                    <TableCell>{meeting.email || 'N/A'}</TableCell>
                    <TableCell>{meeting.phone || 'N/A'}</TableCell>
                    <TableCell>{meeting.meetingDate ? new Date(meeting.meetingDate).toLocaleString() : 'N/A'}</TableCell>
                    <TableCell>{meeting.createdAt ? new Date(meeting.createdAt).toLocaleString() : 'N/A'}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteMeeting(meeting.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {paginatedMeetings.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">No meetings found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredMeetings.length}
            page={meetingPage}
            onPageChange={(_, newPage) => setMeetingPage(newPage)}
            rowsPerPage={meetingRowsPerPage}
            onRowsPerPageChange={(e) => { setMeetingRowsPerPage(parseInt(e.target.value, 10)); setMeetingPage(0); }}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </>
      )}
    </Box>
  );
}
