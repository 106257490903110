// File: src/App.js
import React, { Suspense } from 'react';
import { Routes, Route, useLocation, matchRoutes } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import ThemeProviderComponent from './context/ThemeProviderComponent';
import { AuthProvider } from './context/AuthContext';
import { SubscriptionProvider } from './context/SubscriptionContext';
import ChatWidget from './components/ChatWidget/ChatWidget';

// Layout Components
import Navbar from './components/layout/Components/Navbar';
import Footer from './components/layout/Components/Footer'; // Public Footer
import AppFooter from '/Users/willradley/orthinas-crm/src/components/Navbar/AppFooter.js'; // New App Footer for protected routes

// Routing & Guards
import { publicRoutes } from './routes/publicRoutes';
import { protectedRoutes } from './routes/protectedRoutes';
import ProtectedRoute from './components/ProtectedRoute';
import LoadingSpinner from './components/LoadingSpinner';

// New Logging Out Page
import LoggingOutPage from './components/LoggingOutPage';

// Utilities
import { getSectionConfig } from './utils/sectionUtils';

function LoadingWrapper() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <LoadingSpinner aria-label="Loading" />
    </Box>
  );
}

function AppRoutes() {
  return (
    <Suspense fallback={<LoadingWrapper />}>
      <Routes>
        {/* Logging Out route accessible to all users */}
        <Route path="/logging-out" element={<LoggingOutPage />} />

        {/* Public routes */}
        {publicRoutes.map((r) => (
          <Route key={r.path} path={r.path} element={r.element} />
        ))}

        {/* Protected routes */}
        {protectedRoutes.map((r) => (
          <Route key={r.path} path={r.path} element={r.element} />
        ))}
      </Routes>
    </Suspense>
  );
}

export default function App() {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Official MUI AppBar height for current breakpoint
  const navHeight = theme.mixins.toolbar.minHeight;

  // Sub-nav detection (if applicable)
  const section = getSectionConfig(location.pathname);
  const isSubNavShowing = !!section;
  const navbarOffset = 0; // adjust if needed
  let contentOffset = navHeight;
  if (isSubNavShowing) {
    contentOffset += 64;
  }

  // Existing logic to hide layout (Navbar/ChatWidget) on some pages.
  const hideLayout =
    location.pathname.startsWith('/public/') ||
    (location.pathname.startsWith('/engagehub/') &&
      !location.pathname.startsWith('/engagehub/create') &&
      !location.pathname.startsWith('/engagehub/edit'));

  // Determine if the current route is a protected route using matchRoutes.
  const isProtectedRoute = Boolean(matchRoutes(protectedRoutes, location));

  return (
    <ThemeProviderComponent>
      <AuthProvider>
        <SubscriptionProvider>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            {/* Render Navbar if not hidden */}
            {!hideLayout && (
              <Navbar logo="./RCFOrthinasLight.png" navbarOffset={navbarOffset} />
            )}

            {/* Main content */}
            <Box
              component="main"
              flex={1}
              sx={{ mt: !hideLayout ? `${contentOffset}px` : 0 }}
            >
              <AppRoutes />
            </Box>

            {/* Conditionally render footers:
                - Public Footer on public routes
                - App Footer on protected routes */}
            {isProtectedRoute ? <AppFooter /> : <Footer />}
          </Box>

          {/* Render ChatWidget if layout is not hidden */}
          {!hideLayout && <ChatWidget />}
        </SubscriptionProvider>
      </AuthProvider>
    </ThemeProviderComponent>
  );
}
