// File: BigSupportMegaMenu.jsx

import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Menu,
  Fade,
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Grow,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  Chip,
  Paper,
  Fade as MuiFade,
} from '@mui/material';
import { Link, useLocation, Link as RouterLink, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import GroupsIcon from '@mui/icons-material/Groups';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// New import for the Learning tab icon.
import SchoolIcon from '@mui/icons-material/School';

// --- New Imports for Article Detail Rendering ---
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

/**
 * A common card style object for support cards and help article cards.
 */
const cardStyle = {
  textDecoration: 'none',
  border: '1px solid',
  borderColor: 'divider',
  borderRadius: 2,
  p: 2,
  display: 'block',
  color: 'text.primary',
  backgroundColor: 'background.paper',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease',
  boxShadow: 1,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: 4,
    borderColor: 'primary.main',
  },
};

/**
 * Card for support items.
 */
function SupportCard({ title, description, slug, url, onClose, index }) {
  const finalLink = url || `/support/${slug}`;
  const isExternal = url?.startsWith('http');

  const content = (
    <>
      <Typography variant="subtitle1" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
        {description}
      </Typography>
    </>
  );

  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500 + index * 100}>
      {isExternal ? (
        <Box component="a" href={finalLink} onClick={onClose} sx={cardStyle}>
          {content}
        </Box>
      ) : (
        <Box component={Link} to={finalLink} onClick={onClose} sx={cardStyle}>
          {content}
        </Box>
      )}
    </Grow>
  );
}

/**
 * Card component for help articles.
 */
function HelpArticleCard({ article, onSelect, index }) {
  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500 + index * 100}>
      <Box onClick={() => onSelect(article)} sx={{ ...cardStyle, cursor: 'pointer' }}>
        <Typography variant="subtitle1" fontWeight="bold">
          {article.title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
          {article.description || (article.content && article.content.slice(0, 100) + '...')}
        </Typography>
      </Box>
    </Grow>
  );
}

/**
 * Inline full-article detail view for a selected help article.
 */
function HelpArticleDetail({ article, onBack }) {
  const [feature, setFeature] = useState(null);

  useEffect(() => {
    async function fetchFeature() {
      if (article.linkedFeature) {
        try {
          const featureRef = doc(db, 'crmFeatures', article.linkedFeature);
          const featureSnap = await getDoc(featureRef);
          if (featureSnap.exists()) {
            setFeature({ id: featureSnap.id, ...featureSnap.data() });
          }
        } catch (err) {
          console.error('Error fetching linked feature:', err);
        }
      }
    }
    fetchFeature();
  }, [article]);

  return (
    <Box sx={{ p: 3 }}>
      <Button
        onClick={onBack}
        variant="outlined"
        startIcon={<ArrowBackIosNewIcon />}
        sx={{ mb: 2 }}
      >
        Back to Articles
      </Button>

      <Typography variant="h4" fontWeight="bold" gutterBottom>
        {article.title}
      </Typography>
      {article.category && (
        <Chip
          label={article.category.toUpperCase()}
          color="primary"
          size="small"
          sx={{ mb: 2 }}
        />
      )}
      <Divider sx={{ my: 2 }} />

      <Box sx={{ mt: 3 }}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw, rehypeSanitize]}
        >
          {article.content || ''}
        </ReactMarkdown>
      </Box>

      {article.linkedFeature && feature && (
        <Box sx={{ mt: 3, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Related Feature
          </Typography>
          <Box display="flex" alignItems="center">
            {feature.iconUrl && (
              <Box
                component="img"
                src={feature.iconUrl}
                alt={feature.title}
                sx={{ width: 50, height: 50, mr: 2 }}
              />
            )}
            <Box>
              <Typography variant="h6">{feature.title}</Typography>
              <Typography variant="body2" color="text.secondary">
                {feature.description}
              </Typography>
            </Box>
          </Box>
          <Button
            component={RouterLink}
            to={`/features/${feature.id}`}
            variant="outlined"
            sx={{ mt: 2 }}
          >
            Learn More
          </Button>
        </Box>
      )}

      <Box mt={3} display="flex" justifyContent="space-between" flexWrap="wrap" gap={2}>
        <Button variant="outlined" onClick={onBack} startIcon={<ArrowBackIosNewIcon />}>
          Back to Articles
        </Button>
        <Button variant="contained" component={Link} to={`/knowledge-base/article/${article.id}`}>
          Open Full Article
        </Button>
      </Box>
    </Box>
  );
}

/**
 * Card component for courses.
 */
function CourseCard({ course, onClose, index }) {
  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500 + index * 100}>
      <Box
        component={Link}
        to={`/learning/course/${course.id}`} // Updated route to use learning URL.
        onClick={onClose}
        sx={cardStyle}
      >
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          {course.title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
          {course.description}
        </Typography>
        {course.category && (
          <Chip label={course.category} size="small" sx={{ mb: 1 }} />
        )}
      </Box>
    </Grow>
  );
}

/**
 * Helper to filter support items based on a search query.
 */
function filterSupportItems(items, queryStr) {
  if (!queryStr.trim()) return items;
  const lowerQuery = queryStr.toLowerCase();
  return items.filter(
    (item) =>
      item.title?.toLowerCase().includes(lowerQuery) ||
      item.description?.toLowerCase().includes(lowerQuery)
  );
}

// Extend the existing tabs array to include "Learning"
const SUPPORT_TABS = [
  { label: 'Help Docs', value: 'help-docs', icon: <HelpIcon fontSize="small" /> },
  { label: 'Communities', value: 'communities', icon: <GroupsIcon fontSize="small" /> },
  { label: 'Success', value: 'customer-success', icon: <SupportAgentIcon fontSize="small" /> },
  { label: 'Learning', value: 'learning', icon: <SchoolIcon fontSize="small" /> },
];

export default function BigSupportMegaMenu({ anchorEl, open, onClose }) {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [activeTab, setActiveTab] = useState('help-docs');
  const [searchTerm, setSearchTerm] = useState('');
  const [supportItems, setSupportItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [helpArticles, setHelpArticles] = useState([]);
  const [helpLoading, setHelpLoading] = useState(false);
  const [helpError, setHelpError] = useState(null);

  // New state for courses
  const [courses, setCourses] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [coursesError, setCoursesError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  // Fetch support items when the menu opens.
  useEffect(() => {
    async function fetchSupportItems() {
      setLoading(true);
      setError(null);
      try {
        const snap = await getDocs(collection(db, 'support'));
        const loaded = [];
        snap.forEach((doc) => {
          loaded.push({
            id: doc.id,
            slug: doc.id, // Fallback for internal routing.
            ...doc.data(),
          });
        });
        setSupportItems(loaded);
      } catch (err) {
        console.error('Error fetching support items:', err);
        setError('Unable to load support resources. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    if (open) {
      fetchSupportItems();
    }
  }, [open]);

  // Fetch help articles when the menu opens.
  useEffect(() => {
    async function fetchHelpArticles() {
      setHelpLoading(true);
      setHelpError(null);
      try {
        const snap = await getDocs(collection(db, 'helpArticles'));
        const loaded = [];
        snap.forEach((doc) => {
          loaded.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setHelpArticles(loaded);
      } catch (err) {
        console.error('Error fetching help articles:', err);
        setHelpError('Unable to load related help articles. Please try again later.');
      } finally {
        setHelpLoading(false);
      }
    }
    if (open) {
      fetchHelpArticles();
    }
  }, [open]);

  // --- Fetch courses for the Learning tab ---
  useEffect(() => {
    async function fetchCourses() {
      setCoursesLoading(true);
      setCoursesError(null);
      try {
        // Now fetching courses from the "objects" collection filtering for courses objectType.
        const coursesQuery = query(
          collection(db, 'objects'),
          where('objectType', '==', 'courses-vSZfXz')
        );
        const snap = await getDocs(coursesQuery);
        const loaded = [];
        snap.forEach((doc) => {
          loaded.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setCourses(loaded);
      } catch (err) {
        console.error('Error fetching courses:', err);
        setCoursesError('Unable to load courses. Please try again later.');
      } finally {
        setCoursesLoading(false);
      }
    }
    if (open && activeTab === 'learning') {
      fetchCourses();
    }
  }, [open, activeTab]);

  const tabFilteredItems = useMemo(() => {
    return supportItems.filter((item) => item.type === activeTab);
  }, [supportItems, activeTab]);

  const displayedItems = useMemo(() => {
    return filterSupportItems(tabFilteredItems, searchTerm);
  }, [tabFilteredItems, searchTerm]);

  // For courses, we can also filter by the search term.
  const displayedCourses = useMemo(() => {
    return filterSupportItems(courses, searchTerm);
  }, [courses, searchTerm]);

  const segments = location.pathname.split('/').filter(Boolean);
  const pageKeyword = segments.length > 0 ? segments[0].toLowerCase() : '';

  const relatedHelpArticles = useMemo(() => {
    if (!pageKeyword) return [];
    return helpArticles.filter((article) => {
      return article.relatedPage && article.relatedPage.trim().toLowerCase() === pageKeyword;
    });
  }, [helpArticles, pageKeyword]);

  // Trigger tour on current page by updating URL query params.
  const handleStartTour = () => {
    const params = new URLSearchParams(location.search);
    params.set('tour', 'true');
    navigate(`${location.pathname}?${params.toString()}`);
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        setSelectedArticle(null);
        onClose();
      }}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: { xs: '90vw', md: 900 },
          maxWidth: '95vw',
          p: 0,
          borderRadius: 3,
          boxShadow: 3,
          maxHeight: '75vh',
          overflowY: 'auto',
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 3,
          backgroundColor: 'background.default',
        }}
      >
        {/* Header with Start Tour button */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5" fontWeight="bold">
            Support Resources
          </Typography>
          <Button variant="outlined" size="small" onClick={handleStartTour}>
            Start Tour
          </Button>
        </Box>

        {selectedArticle ? (
          <HelpArticleDetail
            article={selectedArticle}
            onBack={() => setSelectedArticle(null)}
          />
        ) : (
          <>
            <Box
              mb={3}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'stretch', md: 'center' }}
              justifyContent="space-between"
              gap={2}
            >
              <TextField
                size="small"
                placeholder="Search support resources..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                sx={{
                  flexGrow: 1,
                  backgroundColor: 'grey.50',
                  boxShadow: 1,
                  borderRadius: 1,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
              <ButtonGroup
                variant="contained"
                sx={{
                  backgroundColor: 'background.paper',
                  boxShadow: 2,
                  borderRadius: 2,
                  flexShrink: 0,
                }}
              >
                {SUPPORT_TABS.map((tab) => {
                  const isActive = activeTab === tab.value;
                  return (
                    <Button
                      key={tab.value}
                      onClick={() => setActiveTab(tab.value)}
                      startIcon={tab.icon}
                      sx={{
                        textTransform: 'none',
                        fontWeight: isActive ? 'bold' : 'medium',
                        color: isActive ? 'white' : 'text.primary',
                        backgroundColor: isActive ? 'primary.main' : 'inherit',
                        '&:hover': {
                          backgroundColor: isActive ? 'primary.dark' : 'grey.200',
                        },
                      }}
                    >
                      {tab.label}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Box>

            {activeTab === 'learning' ? (
              <>
                {coursesLoading && (
                  <Box textAlign="center" py={3}>
                    <CircularProgress />
                  </Box>
                )}
                {coursesError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {coursesError}
                  </Alert>
                )}
                {!coursesLoading && !coursesError && (
                  <MuiFade in timeout={350}>
                    <Box>
                      <Grid container spacing={2}>
                        {displayedCourses.map((course, idx) => (
                          <Grid key={course.id} item xs={12} sm={6} md={4}>
                            <CourseCard course={course} onClose={onClose} index={idx} />
                          </Grid>
                        ))}
                        {displayedCourses.length === 0 && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              textAlign="center"
                              sx={{ py: 2 }}
                            >
                              No matching courses found.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </MuiFade>
                )}
              </>
            ) : (
              <>
                {loading && (
                  <Box textAlign="center" py={3}>
                    <CircularProgress />
                  </Box>
                )}
                {error && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                  </Alert>
                )}
                {!loading && !error && (
                  <MuiFade in timeout={350}>
                    <Box>
                      <Grid container spacing={2}>
                        {displayedItems.map((item, idx) => (
                          <Grid key={item.id} item xs={12} sm={6} md={4}>
                            <SupportCard
                              title={item.title}
                              description={item.description}
                              slug={item.slug}
                              url={item.url}
                              onClose={onClose}
                              index={idx}
                            />
                          </Grid>
                        ))}
                        {displayedItems.length === 0 && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              textAlign="center"
                              sx={{ py: 2 }}
                            >
                              No matching support resources found.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </MuiFade>
                )}
              </>
            )}

            {/* Only show help articles for non-learning tabs */}
            {activeTab !== 'learning' && (
              <>
                {helpLoading && (
                  <Box textAlign="center" py={3}>
                    <CircularProgress />
                  </Box>
                )}
                {helpError && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {helpError}
                  </Alert>
                )}
                {!helpLoading && !helpError && relatedHelpArticles.length > 0 && (
                  <>
                    <Box mt={4} mb={2}>
                      <Typography variant="h6">Related Help Articles</Typography>
                    </Box>
                    <MuiFade in timeout={350}>
                      <Box>
                        <Grid container spacing={2}>
                          {relatedHelpArticles.map((article, idx) => (
                            <Grid key={article.id} item xs={12} sm={6} md={4}>
                              <HelpArticleCard
                                article={article}
                                onSelect={(art) => setSelectedArticle(art)}
                                index={idx}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </MuiFade>
                  </>
                )}
              </>
            )}

            <Divider sx={{ mt: 4, mb: 3 }} />
            <Box display="flex" justifyContent="center" gap={2} flexWrap="wrap">
              <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                component={Link}
                to="/support/open-ticket"
                endIcon={<ArrowForwardIcon />}
              >
                Open a Support Ticket
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onClose}
                component={Link}
                to="/support/contact"
                endIcon={<ArrowForwardIcon />}
              >
                Contact Support
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Menu>
  );
}
