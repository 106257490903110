import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import {
  Tooltip,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  IconButton,
  Typography,
  Divider,
  TextField,
  Snackbar,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Button as MuiButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import { AuthContext } from '/Users/willradley/orthinas-crm/src/context/AuthContext.js';

// Custom hook to fetch a Firestore collection based on a query.
function useFirestoreCollection(q) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!q) return; // if no query is provided, do nothing
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(docs);
      },
      (error) => {
        console.error('Error fetching data from Firestore:', error);
      }
    );
    return () => unsubscribe();
  }, [q]);
  return data;
}

function DesktopLoggedInNavItems({ buttonStyles }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Retrieve the current user from AuthContext.
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser?.uid;

  // --- (Optional) Admin Check ---
  // Although admin status is determined here, for the navbar we now disregard admin status.
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (currentUser) {
      currentUser
        .getIdTokenResult(true)
        .then((idTokenResult) => {
          setIsAdmin(!!idTokenResult.claims.isAdmin);
        })
        .catch((error) => {
          console.error('Error fetching admin status:', error);
          setIsAdmin(false);
        });
    }
  }, [currentUser]);

  // --- Firestore queries for solutions ---
  const solutionsQuery = useMemo(
    () => query(collection(db, 'solutions'), where('type', '==', 'product')),
    []
  );
  const solutions = useFirestoreCollection(solutionsQuery);

  // --- Firestore queries for objectTypes ---
  // Query for user-created objectTypes:
  const userObjectTypesQuery = useMemo(() => {
    return userId
      ? query(collection(db, 'objectTypes'), where('userId', '==', userId))
      : null;
  }, [userId]);
  const userObjectTypes = useFirestoreCollection(userObjectTypesQuery);

  // Query for system-defined objectTypes (available to all users):
  const systemObjectTypesQuery = useMemo(
    () => query(collection(db, 'objectTypes'), where('system', '==', true)),
    []
  );
  const systemObjectTypes = useFirestoreCollection(systemObjectTypesQuery);

  // ---------------------------------------------------
  // Combine both sets of objectTypes and deduplicate.
  // NEW: Filter out object types marked as adminViewOnly regardless of admin status.
  // ---------------------------------------------------
  const combinedObjectTypes = useMemo(() => {
    const combined = [...userObjectTypes, ...systemObjectTypes];
    const deduped = [];
    const seenIds = new Set();
    combined.forEach((obj) => {
      if (!seenIds.has(obj.id)) {
        // Only add the object type if it is NOT marked as adminViewOnly.
        if (!obj.adminViewOnly) {
          deduped.push(obj);
        }
        seenIds.add(obj.id);
      }
    });
    return deduped;
  }, [userObjectTypes, systemObjectTypes]);

  // --- Helper to get submenu items for a given solution ---
  const getSubItemsForSolution = useCallback(
    (solutionTitle) =>
      combinedObjectTypes.filter(
        (ot) =>
          ot.module &&
          ot.module.toLowerCase() === solutionTitle.toLowerCase()
      ),
    [combinedObjectTypes]
  );

  // --- Build nav items (from Firestore data) ---
  const navItems = useMemo(() => {
    const baseItems = [
      { label: 'Dashboard', path: '/dashboard', hasSubmenu: false },
    ];
    const solutionItems = solutions.map((solution) => {
      const subItems = getSubItemsForSolution(solution.title);
      return {
        label: solution.title,
        path: `/dashboard?view=${encodeURIComponent(solution.title)}`,
        submenu: subItems,
        hasSubmenu: subItems.length > 0,
      };
    });
    return [...baseItems, ...solutionItems];
  }, [solutions, getSubItemsForSolution]);

  // --- State for customizing which items are visible ---
  const [selectedNavItems, setSelectedNavItems] = useState(new Set());
  // Load saved selection from localStorage as a fallback.
  useEffect(() => {
    const stored = localStorage.getItem('navCustomization');
    if (stored) {
      try {
        const arr = JSON.parse(stored);
        if (Array.isArray(arr)) {
          setSelectedNavItems(new Set(arr));
        }
      } catch (err) {
        console.error('Error parsing navCustomization from localStorage', err);
      }
    }
  }, []);
  useEffect(() => {
    localStorage.setItem(
      'navCustomization',
      JSON.stringify([...selectedNavItems])
    );
  }, [selectedNavItems]);

  // --- State for ordering ---
  const [customizationOrder, setCustomizationOrder] = useState([]);
  // Load saved order from localStorage as a fallback.
  useEffect(() => {
    const storedOrder = localStorage.getItem('navCustomizationOrder');
    if (storedOrder) {
      try {
        const arr = JSON.parse(storedOrder);
        if (Array.isArray(arr)) {
          setCustomizationOrder(arr);
        }
      } catch (err) {
        console.error('Error parsing navCustomizationOrder from localStorage', err);
      }
    }
  }, []);
  useEffect(() => {
    localStorage.setItem(
      'navCustomizationOrder',
      JSON.stringify(customizationOrder)
    );
  }, [customizationOrder]);
  useEffect(() => {
    setCustomizationOrder((prevOrder) => {
      const currentLabels = navItems.map((item) => item.label);
      const newOrder = prevOrder.filter((label) =>
        currentLabels.includes(label)
      );
      currentLabels.forEach((label) => {
        if (!newOrder.includes(label)) {
          newOrder.push(label);
        }
      });
      return newOrder;
    });
  }, [navItems]);

  // --- Effective selection fallback ---
  const effectiveSelectedNavItems = useMemo(() => {
    return selectedNavItems.size > 0
      ? selectedNavItems
      : new Set(navItems.map((item) => item.label));
  }, [selectedNavItems, navItems]);

  // --- Saved Views from Firestore ---
  const [savedViews, setSavedViews] = useState([]);
  const [viewsLoading, setViewsLoading] = useState(true);
  useEffect(() => {
    if (!userId) return;
    const savedViewsQuery = query(
      collection(db, 'userNavViews'),
      where('userId', '==', userId)
    );
    const unsubscribe = onSnapshot(savedViewsQuery, (snapshot) => {
      const views = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSavedViews(views);
      setViewsLoading(false);
    });
    return () => unsubscribe();
  }, [userId]);

  // Auto‑load default view if no customization exists.
  useEffect(() => {
    if (selectedNavItems.size === 0 && savedViews.length > 0) {
      const defaultView = savedViews.find((v) => v.isDefault);
      if (defaultView) {
        setSelectedNavItems(new Set(defaultView.selectedNavItems));
        setCustomizationOrder(defaultView.customizationOrder);
      }
    }
  }, [savedViews, selectedNavItems]);

  // --- "Customize" menu state ---
  const [customizeAnchorEl, setCustomizeAnchorEl] = useState(null);
  const handleCustomizeMenuOpen = (event) => {
    setCustomizeAnchorEl(event.currentTarget);
  };
  const handleCustomizeMenuClose = () => {
    setCustomizeAnchorEl(null);
    setCustomizeSearchQuery('');
  };

  // --- Search filter state for Customize menu ---
  const [customizeSearchQuery, setCustomizeSearchQuery] = useState('');

  // --- State for naming the view to save ---
  const [viewName, setViewName] = useState('');

  // --- Handler for toggling a nav item's visibility ---
  const toggleNavItemSelection = (label) => {
    setSelectedNavItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(label)) {
        newSet.delete(label);
      } else {
        newSet.add(label);
      }
      return newSet;
    });
  };

  // --- Handlers for reordering ---
  const moveItemUp = (label) => {
    setCustomizationOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      const index = newOrder.indexOf(label);
      if (index > 0) {
        [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
      }
      return newOrder;
    });
  };

  const moveItemDown = (label) => {
    setCustomizationOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      const index = newOrder.indexOf(label);
      if (index !== -1 && index < newOrder.length - 1) {
        [newOrder[index], newOrder[index + 1]] = [newOrder[index + 1], newOrder[index]];
      }
      return newOrder;
    });
  };

  // --- Reset customization to default ---
  const handleResetCustomization = () => {
    if (window.confirm('Are you sure you want to reset to default? This will remove all your customisations.')) {
      const allItems = navItems.map((item) => item.label);
      setSelectedNavItems(new Set(allItems));
      setCustomizationOrder(allItems);
      setCustomizeSearchQuery('');
    }
  };

  // --- Save view to Firestore ---
  const [viewSaved, setViewSaved] = useState(false);
  const handleSaveView = async () => {
    const effectiveSelection = effectiveSelectedNavItems;
    if (!viewName.trim()) {
      alert('Please enter a name for your view.');
      return;
    }
    try {
      await addDoc(collection(db, 'userNavViews'), {
        userId,
        viewName: viewName.trim(),
        selectedNavItems: [...effectiveSelection],
        customizationOrder,
        savedAt: new Date().toISOString(),
        isDefault: false,
      });
      setViewSaved(true);
      setTimeout(() => setViewSaved(false), 2000);
      setViewName('');
    } catch (err) {
      console.error('Error saving view:', err);
    }
  };

  // --- Delete a saved view from Firestore ---
  const handleDeleteView = async (viewId) => {
    if (window.confirm('Are you sure you want to delete this view?')) {
      try {
        await deleteDoc(doc(db, 'userNavViews', viewId));
      } catch (err) {
        console.error('Error deleting view:', err);
      }
    }
  };

  // --- Mark a view as default ---
  const handleMarkDefault = async (viewId) => {
    try {
      await Promise.all(
        savedViews.map(async (view) => {
          const viewRef = doc(db, 'userNavViews', view.id);
          if (view.id === viewId) {
            await updateDoc(viewRef, { isDefault: true });
          } else {
            await updateDoc(viewRef, { isDefault: false });
          }
        })
      );
    } catch (err) {
      console.error('Error marking view as default:', err);
    }
  };

  // --- Load a saved view from Firestore ---
  const handleLoadView = (view) => {
    setSelectedNavItems(new Set(view.selectedNavItems));
    setCustomizationOrder(view.customizationOrder);
    handleCustomizeMenuClose();
  };

  // --- Compute ordered list for main nav based on order and effective selection ---
  const orderedFilteredNavItems = useMemo(() => {
    const filtered = navItems.filter((item) =>
      effectiveSelectedNavItems.has(item.label)
    );
    return [...filtered].sort(
      (a, b) =>
        customizationOrder.indexOf(a.label) - customizationOrder.indexOf(b.label)
    );
  }, [navItems, effectiveSelectedNavItems, customizationOrder]);

  const MAX_INLINE_ITEMS = 5;
  const inlineNavItemsMain = orderedFilteredNavItems.slice(0, MAX_INLINE_ITEMS);
  const extraNavItemsMain = orderedFilteredNavItems.slice(MAX_INLINE_ITEMS);

  // --- Dropdown Menu State & Handlers for submenu and "More" ---
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [submenuSolution, setSubmenuSolution] = useState(null);
  const handleSubmenuOpen = (event, navItem) => {
    event.stopPropagation();
    setSubmenuAnchorEl(event.currentTarget);
    setSubmenuSolution(navItem);
  };
  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
    setSubmenuSolution(null);
  };

  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const handleMoreMenuOpen = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };
  const handleMoreMenuClose = () => {
    setMoreAnchorEl(null);
    handleExtraSubmenuClose();
  };

  const [extraSubmenuAnchorEl, setExtraSubmenuAnchorEl] = useState(null);
  const [extraSubmenuItem, setExtraSubmenuItem] = useState(null);
  const handleExtraSubmenuOpen = (event, navItem) => {
    setExtraSubmenuAnchorEl(event.currentTarget);
    setExtraSubmenuItem(navItem);
  };
  const handleExtraSubmenuClose = () => {
    setExtraSubmenuAnchorEl(null);
    setExtraSubmenuItem(null);
  };

  // --- Customize Menu List Filtering & Ordering ---
  const customizeListItems = useMemo(() => {
    const filtered = navItems.filter((item) =>
      item.label.toLowerCase().includes(customizeSearchQuery.toLowerCase())
    );
    return [...filtered].sort(
      (a, b) =>
        customizationOrder.indexOf(a.label) - customizationOrder.indexOf(b.label)
    );
  }, [navItems, customizeSearchQuery, customizationOrder]);

  // --- Rendering individual nav items for the main navbar ---
  const renderNavItem = (navItem) => {
    if (navItem.hasSubmenu) {
      return (
        <Tooltip key={navItem.label} title={navItem.label} arrow>
          <ButtonGroup
            variant="outlined"
            size="small"
            sx={{
              ml: 1,
              border: 'none',
              '& .MuiButtonGroup-grouped': {
                border: 'none',
                boxShadow: 'none',
              },
            }}
          >
            <Button component={Link} to={navItem.path} sx={navButtonStyles}>
              {navItem.label}
            </Button>
            <Button
              onClick={(event) => handleSubmenuOpen(event, navItem)}
              sx={navButtonStyles}
              aria-haspopup="true"
              aria-controls="submenu-menu"
            >
              <ArrowDropDownIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </Tooltip>
      );
    }
    return (
      <Tooltip key={navItem.label} title={navItem.label} arrow>
        <Button
          component={Link}
          to={navItem.path}
          sx={{ ml: 1, ...navButtonStyles }}
        >
          {navItem.label}
        </Button>
      </Tooltip>
    );
  };

  // Common nav button styles:
  const navButtonStyles = {
    textTransform: 'none',
    ...buttonStyles,
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    border: 'none',
    boxShadow: 'none',
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {/* Left section: Nav Items */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {inlineNavItemsMain.map(renderNavItem)}
        {extraNavItemsMain.length > 0 && (
          <Tooltip title="More" arrow>
            <Button
              onClick={handleMoreMenuOpen}
              sx={{ ml: 1, ...navButtonStyles }}
              endIcon={<ArrowDropDownIcon fontSize="small" />}
            >
              More
            </Button>
          </Tooltip>
        )}
      </Box>

      {/* Right section: Customize IconButton */}
      <Box sx={{ ml: 'auto' }}>
        <Tooltip title="Customise Navigation">
          <IconButton
            onClick={handleCustomizeMenuOpen}
            sx={{
              color: theme.palette.common.white,
              transition: 'color 0.3s ease',
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Customize Menu */}
      <Menu
        id="customize-menu"
        anchorEl={customizeAnchorEl}
        open={Boolean(customizeAnchorEl)}
        onClose={handleCustomizeMenuClose}
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Typography variant="subtitle1">Customise Navigation</Typography>
        </Box>
        <Divider />
        <Box sx={{ px: 2, py: 1 }}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Search..."
            value={customizeSearchQuery}
            onChange={(e) => setCustomizeSearchQuery(e.target.value)}
          />
        </Box>
        {/* Save New View Section */}
        <Box
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="View Name"
            value={viewName}
            onChange={(e) => setViewName(e.target.value)}
            InputProps={{ sx: { height: '40px' } }}
          />
          <MuiButton
            variant="contained"
            size="small"
            onClick={handleSaveView}
            sx={{ height: '40px' }}
          >
            Save
          </MuiButton>
        </Box>
        <Divider />
        {/* Saved Views Section */}
        {viewsLoading ? (
          <Box sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        ) : savedViews.length > 0 && (
          <>
            <Box sx={{ px: 2, py: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
              >
                Saved Views
              </Typography>
            </Box>
            <List sx={{ pt: 0, pb: 0, maxHeight: 150, overflowY: 'auto' }}>
              {savedViews.map((view) => (
                <ListItem
                  key={view.id}
                  button
                  onClick={() => handleLoadView(view)}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant="body2">{view.viewName}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={view.isDefault ? 'Default View' : 'Set as Default'}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMarkDefault(view.id);
                        }}
                        size="small"
                        aria-label="Mark as default"
                      >
                        {view.isDefault ? (
                          <StarIcon fontSize="small" color="warning" />
                        ) : (
                          <StarBorderIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteView(view.id);
                      }}
                      size="small"
                      aria-label="Delete view"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Divider />
          </>
        )}
        {/* Reset Button */}
        <Box sx={{ px: 2, py: 1 }}>
          <MuiButton variant="outlined" size="small" onClick={handleResetCustomization}>
            Reset to Default
          </MuiButton>
        </Box>
        <Divider />
        {/* Customize List with Reordering */}
        <List sx={{ pt: 0, pb: 0, maxHeight: 300, overflowY: 'auto' }}>
          {customizeListItems.map((item) => (
            <ListItem
              key={item.label}
              dense
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedNavItems.has(item.label)}
                    onChange={() => toggleNavItemSelection(item.label)}
                  />
                }
                label={item.label}
                sx={{ flex: 1 }}
              />
              <IconButton
                onClick={() => moveItemUp(item.label)}
                disabled={customizationOrder.indexOf(item.label) === 0}
                size="small"
                aria-label="Move item up"
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => moveItemDown(item.label)}
                disabled={
                  customizationOrder.indexOf(item.label) === customizationOrder.length - 1
                }
                size="small"
                aria-label="Move item down"
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Menu>

      {/* Snackbar for Save View confirmation */}
      <Snackbar
        open={viewSaved}
        message="View saved"
        autoHideDuration={2000}
        onClose={() => setViewSaved(false)}
      />

      {/* Menu for inline nav items with submenu */}
      {submenuSolution && (
        <Menu
          id="submenu-menu"
          anchorEl={submenuAnchorEl}
          open={Boolean(submenuAnchorEl)}
          onClose={handleSubmenuClose}
        >
          {submenuSolution.submenu.map((item) => (
            <MenuItem
              key={item.id}
              component={Link}
              to={`/list/${item.name || item.id}`}
              onClick={handleSubmenuClose}
            >
              {item.title || item.name || 'Unnamed'}
            </MenuItem>
          ))}
        </Menu>
      )}

      {/* "More" menu for extra nav items */}
      <Menu
        id="more-menu"
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={handleMoreMenuClose}
      >
        {extraNavItemsMain.map((navItem) => {
          if (navItem.hasSubmenu) {
            return (
              <MenuItem
                key={navItem.label}
                onClick={(event) => handleExtraSubmenuOpen(event, navItem)}
              >
                {navItem.label}
                <ArrowRightIcon fontSize="small" sx={{ ml: 1 }} />
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={navItem.label}
              component={Link}
              to={navItem.path}
              onClick={handleMoreMenuClose}
            >
              {navItem.label}
            </MenuItem>
          );
        })}
      </Menu>

      {/* Nested submenu for extra nav items inside the "More" menu */}
      {extraSubmenuItem && (
        <Menu
          id="extra-submenu-menu"
          anchorEl={extraSubmenuAnchorEl}
          open={Boolean(extraSubmenuAnchorEl)}
          onClose={handleExtraSubmenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {extraSubmenuItem.submenu.map((subItem) => (
            <MenuItem
              key={subItem.id}
              component={Link}
              to={`/list/${subItem.name || subItem.id}`}
              onClick={() => {
                handleExtraSubmenuClose();
                handleMoreMenuClose();
              }}
            >
              {subItem.title || subItem.name || 'Unnamed'}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
}

export default DesktopLoggedInNavItems;
