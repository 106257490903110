import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  Box,
  Menu,
  Fade,
  Grid,
  Typography,
  Button,
  ButtonGroup,
  Grow,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js'; // adjust as needed
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import SearchIcon from '@mui/icons-material/Search';

/** 
 * Custom hook to debounce a value.
 */
function useDebounce(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

/**
 * Default card component (used for Teams tab).
 */
function SolutionCard({ title, subtitle, slug, onClose, index }) {
  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500 + index * 100}>
      <Box
        component={Link}
        to={`/solutions/${slug}`}
        onClick={onClose}
        sx={{
          textDecoration: 'none',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          display: 'block',
          color: 'text.primary',
          transition: 'border-color 0.2s, box-shadow 0.2s',
          backgroundColor: 'background.paper',
          '&:hover': {
            borderColor: 'primary.main',
            boxShadow: 4,
          },
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5, color: 'text.secondary' }}>
          {subtitle}
        </Typography>
      </Box>
    </Grow>
  );
}

/**
 * Card component for industry solutions.
 * Renders only the title and (if available) an icon image.
 */
function IndustryCard({ title, slug, iconUrl, onClose, index }) {
  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500 + index * 100}>
      <Box
        component={Link}
        to={`/solutions/${slug}`}
        onClick={onClose}
        sx={{
          textDecoration: 'none',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          color: 'text.primary',
          transition: 'border-color 0.2s, box-shadow 0.2s',
          backgroundColor: 'background.paper',
          '&:hover': {
            borderColor: 'primary.main',
            boxShadow: 4,
          },
        }}
      >
        {iconUrl && (
          <Box
            component="img"
            src={iconUrl}
            alt={title}
            sx={{ width: 40, height: 40, mr: 2, objectFit: 'contain' }}
          />
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>
    </Grow>
  );
}

/**
 * New ProductCard component for the Products tab.
 * Shows only the icon (if available) and title in a different layout.
 */
function ProductCard({ title, slug, iconUrl, onClose, index }) {
  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500 + index * 100}>
      <Box
        component={Link}
        to={`/solutions/${slug}`}
        onClick={onClose}
        sx={{
          textDecoration: 'none',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text.primary',
          transition: 'border-color 0.2s, box-shadow 0.2s',
          backgroundColor: 'background.paper',
          '&:hover': {
            borderColor: 'primary.main',
            boxShadow: 4,
          },
          minHeight: 150,
        }}
      >
        {iconUrl && (
          <Box
            component="img"
            src={iconUrl}
            alt={title}
            sx={{ width: 50, height: 50, mb: 1, objectFit: 'contain' }}
          />
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          {title}
        </Typography>
      </Box>
    </Grow>
  );
}

/**
 * Helper function to filter solutions by title and subtitle.
 */
function filterSolutions(items, query) {
  if (!query.trim()) return items;
  const lowerQuery = query.toLowerCase();
  return items.filter(
    (item) =>
      item.title.toLowerCase().includes(lowerQuery) ||
      (item.subtitle && item.subtitle.toLowerCase().includes(lowerQuery))
  );
}

/** 
 * Tab definitions (should match the "type" field in Firestore).
 */
const SOLUTION_TABS = [
  { label: 'Products', value: 'product', icon: <AutoAwesomeMosaicIcon fontSize="small" /> },
  { label: 'Teams', value: 'team', icon: <GroupIcon fontSize="small" /> },
  { label: 'Industries', value: 'company', icon: <BusinessIcon fontSize="small" /> },
];

/**
 * Search bar component.
 */
function SolutionSearchBar({ searchTerm, onSearchChange }) {
  return (
    <TextField
      size="small"
      placeholder="Search solutions..."
      value={searchTerm}
      onChange={onSearchChange}
      fullWidth
      sx={{
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.grey[50],
        boxShadow: 2,
        borderRadius: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />
  );
}

/**
 * Tabs component to switch between solution types.
 */
function SolutionTabs({ activeTab, onTabChange }) {
  return (
    <ButtonGroup
      variant="contained"
      sx={{
        backgroundColor: 'background.paper',
        boxShadow: 3,
        borderRadius: 2,
        flexShrink: 0,
      }}
    >
      {SOLUTION_TABS.map((tab) => {
        const isActive = activeTab === tab.value;
        return (
          <Button
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            startIcon={tab.icon}
            sx={{
              textTransform: 'none',
              fontWeight: isActive ? 'bold' : 'medium',
              color: isActive ? 'white' : 'text.primary',
              backgroundColor: isActive ? 'primary.main' : 'inherit',
              '&:hover': {
                backgroundColor: isActive ? 'primary.dark' : 'grey.200',
              },
            }}
          >
            {tab.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

/**
 * The main Solutions Mega Menu component.
 */
export default function BigSolutionsMegaMenu({ anchorEl, open, onClose }) {
  const [activeTab, setActiveTab] = useState('team');
  const [searchTerm, setSearchTerm] = useState('');
  const [solutions, setSolutions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Debounce the search term to reduce filtering frequency.
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Avoid re-fetching solutions if they’ve already been loaded.
  const fetchedOnceRef = useRef(false);

  // Fetch solutions from Firestore when the menu is opened.
  useEffect(() => {
    async function fetchSolutions() {
      setLoading(true);
      setError(null);
      try {
        const snap = await getDocs(collection(db, 'solutions'));
        const loaded = snap.docs.map((doc) => ({
          id: doc.id, // Using doc ID as the slug
          slug: doc.id,
          ...doc.data(),
        }));
        setSolutions(loaded);
        fetchedOnceRef.current = true;
      } catch (err) {
        console.error('Error fetching solutions:', err);
        setError('Unable to load solutions. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    if (open && !fetchedOnceRef.current) {
      fetchSolutions();
    }
  }, [open]);

  // Filter solutions based on the active tab.
  const tabFilteredSolutions = useMemo(() => {
    return solutions.filter((s) => s.type === activeTab);
  }, [solutions, activeTab]);

  // Further filter solutions based on the (debounced) search term.
  const displayedSolutions = useMemo(() => {
    return filterSolutions(tabFilteredSolutions, debouncedSearchTerm);
  }, [tabFilteredSolutions, debouncedSearchTerm]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: '900px',
          maxWidth: '95vw',
          p: 0,
          borderRadius: 3,
          boxShadow: 8,
          maxHeight: '75vh',
          overflowY: 'auto',
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          // Subtle gradient background
          background: (theme) =>
            `linear-gradient(120deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[100]} 100%)`,
        }}
      >
        {/* Top Row: Search Bar + Tabs */}
        <Box
          mb={3}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'stretch', md: 'center' }}
          justifyContent="space-between"
          gap={2}
        >
          <SolutionSearchBar
            searchTerm={searchTerm}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
          />
          <SolutionTabs activeTab={activeTab} onTabChange={setActiveTab} />
        </Box>

        {/* Loading and Error States */}
        {loading && (
          <Box textAlign="center" py={3}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Solutions Grid */}
        {!loading && !error && (
          <Fade in timeout={350}>
            <Box>
              <Grid container spacing={2}>
                {displayedSolutions.map((item, idx) => (
                  <Grid key={item.id} item xs={12} sm={6} md={4}>
                    {activeTab === 'company' ? (
                      <IndustryCard
                        title={item.title}
                        slug={item.slug}
                        iconUrl={item.iconUrl} // New icon field from Firestore
                        onClose={onClose}
                        index={idx}
                      />
                    ) : activeTab === 'product' ? (
                      <ProductCard
                        title={item.title}
                        slug={item.slug}
                        iconUrl={item.iconUrl} // Show icon in products
                        onClose={onClose}
                        index={idx}
                      />
                    ) : (
                      <SolutionCard
                        title={item.title}
                        subtitle={item.subtitle}
                        slug={item.slug}
                        onClose={onClose}
                        index={idx}
                      />
                    )}
                  </Grid>
                ))}
                {displayedSolutions.length === 0 && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textAlign="center"
                      sx={{ py: 2 }}
                    >
                      No matching solutions found.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Fade>
        )}

        {/* Divider & CTA */}
        <Divider sx={{ mt: 4, mb: 3 }} />
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            component={Link}
            to="/signup"
            endIcon={<ArrowForwardIcon />}
          >
            Start Free
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            component={Link}
            to="/contact-sales"
            endIcon={<ArrowForwardIcon />}
          >
            Contact Sales
          </Button>
        </Box>
      </Box>
    </Menu>
  );
}
