// File: src/components/Footer.js
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Box,
  Typography,
  Link as MuiLink,
  Grid,
  TextField,
  IconButton,
  Button,
  Container,
  Divider,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Zoom,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
  Public as PublicIcon,
  AlternateEmail as AlternateEmailIcon,
  MusicNote as MusicNoteIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getApp } from 'firebase/app';

/** ------------------ Styled Components ------------------ **/

const FooterWrapper = styled('footer')(({ theme }) => ({
  background: `linear-gradient(120deg, ${theme.palette.grey[900]} 0%, ${theme.palette.grey[800]} 100%)`,
  color: theme.palette.grey[100],
  marginTop: theme.spacing(12),
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(8),
  position: 'relative',
  overflow: 'hidden',

  // Top Wave
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -50,
    left: 0,
    width: '100%',
    height: 50,
    background: `url('/images/wave.svg') no-repeat center top`,
    backgroundSize: 'cover',
    pointerEvents: 'none',
    opacity: 0.3,
  },

  // Bottom Wave
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -50,
    left: 0,
    width: '100%',
    height: 50,
    background: `url('/images/wave.svg') no-repeat center bottom`,
    backgroundSize: 'cover',
    pointerEvents: 'none',
    opacity: 0.3,
  },
}));

const FooterLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.grey[400],
  textDecoration: 'none',
  marginBottom: theme.spacing(1),
  fontSize: '0.95rem',
  display: 'block',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.light,
  },
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginRight: theme.spacing(1.5),
  transition: 'color 0.2s ease',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

// Reusable styles for TextField inputs
const textFieldStyles = {
  input: {
    color: '#fff',
    '::placeholder': { color: 'rgba(255,255,255,0.7)' },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255,255,255,0.5)',
    },
    '&:hover fieldset': {
      borderColor: '#fff',
    },
  },
};

// Reusable styles for outlined buttons
const outlinedButtonStyles = {
  textTransform: 'none',
  fontWeight: 'bold',
  borderRadius: 20,
  borderColor: 'rgba(255,255,255,0.5)',
  color: '#fff',
  '&:hover': {
    borderColor: '#fff',
  },
};

/** ------------------ Social Media Links ------------------ **/
const SocialLinks = () => {
  const socialItems = useMemo(
    () => [
      { href: 'https://www.facebook.com/orthinas/', icon: <FacebookIcon />, label: 'Facebook' },
      { href: 'https://www.instagram.com/orthinas/', icon: <InstagramIcon />, label: 'Instagram' },
      { href: 'https://www.threads.net/@orthinas', icon: <AlternateEmailIcon />, label: 'Threads' },
      { href: 'https://x.com/orthinas', icon: <TwitterIcon />, label: 'X (Twitter)' },
      { href: 'https://www.tiktok.com/@orthinas', icon: <MusicNoteIcon />, label: 'TikTok' },
      { href: 'https://www.youtube.com/channel/UCTMdfrrWF2neRxVNV7yL7Kg', icon: <YouTubeIcon />, label: 'YouTube' },
      { href: 'https://www.linkedin.com/company/105839041', icon: <LinkedInIcon />, label: 'LinkedIn' },
      { href: 'https://bsky.app/profile/orthinas.bsky.social', icon: <PublicIcon />, label: 'BlueSky' },
    ],
    []
  );

  return (
    <Box>
      {socialItems.map(({ href, icon, label }) => (
        <SocialIconButton
          key={label}
          component="a"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={label}
        >
          {icon}
        </SocialIconButton>
      ))}
    </Box>
  );
};

/** ------------------ Newsletter Form ------------------ **/
const NewsletterForm = ({ onCancel, onSubmit, isSubmitting, error }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleSubscribe = useCallback(() => {
    onSubmit({ email, name });
  }, [email, name, onSubmit]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 3 }}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={textFieldStyles}
      />
      <TextField
        variant="outlined"
        size="small"
        placeholder="Your Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={textFieldStyles}
      />
      {error && (
        <Typography variant="body2" sx={{ color: 'red', mb: 1 }}>
          {error}
        </Typography>
      )}
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          variant="outlined"
          size="small"
          sx={outlinedButtonStyles}
          onClick={handleSubscribe}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={16} sx={{ color: '#fff' }} /> : 'Subscribe'}
        </Button>
        <Button
          variant="text"
          size="small"
          color="inherit"
          onClick={onCancel}
          sx={{
            textTransform: 'none',
            color: '#fff',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

/** ------------------ Back-to-Top Button ------------------ **/
const BackToTopButton = ({ show, onClick }) => (
  <Zoom in={show}>
    <Box
      onClick={onClick}
      sx={{
        position: 'fixed',
        bottom: 32,
        right: 32,
        zIndex: 1300,
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: '#fff',
        borderRadius: '50%',
        width: 48,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': { backgroundColor: 'rgba(0,0,0,0.9)' },
      }}
    >
      <KeyboardArrowUpIcon />
    </Box>
  </Zoom>
);

/** ------------------ Footer Component ------------------ **/
function Footer() {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  // Newsletter form state
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState('');

  const firestoreDb = getFirestore(getApp());

  const handleToggleForm = useCallback(() => {
    setShowForm((prev) => !prev);
    setError('');
    setIsSubscribed(false);
  }, []);

  const handleSubscribe = useCallback(
    async ({ email, name }) => {
      if (!email || !name) {
        setError('Please enter both your name and email.');
        return;
      }
      setIsSubmitting(true);
      setError('');
      try {
        await addDoc(collection(firestoreDb, 'registrations'), {
          emailAddress: email,
          firstName: name,
        });
        setIsSubscribed(true);
        setShowForm(false);
      } catch (err) {
        console.error(err);
        setError('An error occurred. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    },
    [firestoreDb]
  );

  // Back-to-top button state
  const [showTopButton, setShowTopButton] = useState(false);
  const handleScroll = useCallback(() => {
    setShowTopButton(window.scrollY > 400);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <FooterWrapper>
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="space-between">
            {/* About / Company Info */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
                RCF Orthinas
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, opacity: 0.85, lineHeight: 1.6 }}>
                Empower your business with AI-driven CRM solutions that streamline customer interactions and accelerate growth. We adapt to your workflow so you can build lasting relationships.
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.75 }}>
                © {currentYear} RCF Orthinas. All rights reserved.
              </Typography>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
                Quick Links
              </Typography>
              <FooterLink component={RouterLink} to="/contact-sales">
                Contact Sales
              </FooterLink>
              
              <FooterLink component={RouterLink} to="/support/help-center">
                Help & Support
              </FooterLink>
              <FooterLink component={RouterLink} to="/downloads">
                Sponsor Downloads
              </FooterLink>
            </Grid>

            {/* Legal & Account */}
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
                Legal
              </Typography>
              <FooterLink component={RouterLink} to="/policy/privacy">
                Privacy Policy
              </FooterLink>
              <FooterLink component={RouterLink} to="/policy/terms">
                Terms of Service
              </FooterLink>
              <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.2)' }} />
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
                Account
              </Typography>
              <FooterLink component={RouterLink} to="/login">
                Login
              </FooterLink>
              <FooterLink component={RouterLink} to="/signup">
                Sign Up
              </FooterLink>
            </Grid>

            {/* Newsletter & Social */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
                Stay in the Loop
              </Typography>
              {!isSubscribed && !showForm && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" sx={{ mb: 2, opacity: 0.85, lineHeight: 1.6 }}>
                    Get the latest on Orthinas CRM—features, tips, and special offers—delivered to your inbox.
                  </Typography>
                  <Button variant="outlined" size="small" sx={outlinedButtonStyles} onClick={handleToggleForm}>
                    Subscribe
                  </Button>
                </Box>
              )}
              {showForm && !isSubscribed && (
                <NewsletterForm
                  onCancel={handleToggleForm}
                  onSubmit={handleSubscribe}
                  isSubmitting={isSubmitting}
                  error={error}
                />
              )}
              {isSubscribed && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" sx={{ color: 'lightgreen', mb: 1 }}>
                    Thank you for subscribing!
                  </Typography>
                </Box>
              )}
              <SocialLinks />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 8, mb: 4, backgroundColor: 'rgba(255,255,255,0.2)' }} />
          <Typography variant="body2" textAlign="center" sx={{ opacity: 0.75 }}>
            Orthinas is proudly crafted by RCF in the UK.
          </Typography>
        </Container>
      </FooterWrapper>
    </>
  );
}

export default Footer;
