// File: src/hooks/useNotifications.js
import { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js'; // Adjust the import as needed

export function useNotifications(userId) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) return; // Do nothing if no user is provided

    // Reference the top-level notifications collection
    const notificationsRef = collection(db, 'notifications');

    // Query notifications ordered by timestamp (most recent first)
    const q = query(notificationsRef, orderBy('timestamp', 'desc'));

    // Set up the real-time listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Optionally, you can further filter client‑side based on userId/segment if needed.
      setNotifications(data);
      setLoading(false);
    });

    // Cleanup on unmount or when userId changes
    return () => unsubscribe();
  }, [userId]);

  return { notifications, loading };
}
