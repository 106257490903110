// File: src/pages/admin/ArticlesTab.jsx
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  TextField,
  TableSortLabel,
  TablePagination,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { collection, getDocs, query, orderBy, doc, deleteDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

const CATEGORY_OPTIONS = ['help-docs', 'communities', 'customer-success'];

/**
 * ArticleDialog component handles editing of an article.
 * It includes all fields from creation (title, description, content, category, relatedPage, and linkedFeature).
 * The linkedFeature field is rendered as a dropdown using the features list passed via props.
 */
function ArticleDialog({ open, onClose, article, onSaved, features }) {
  const [title, setTitle] = useState(article ? article.title : '');
  const [description, setDescription] = useState(article ? article.description : '');
  const [content, setContent] = useState(article ? article.content : '');
  const [category, setCategory] = useState(article ? article.category : '');
  const [relatedPage, setRelatedPage] = useState(article ? article.relatedPage : '');
  const [linkedFeature, setLinkedFeature] = useState(article ? article.linkedFeature : '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Update form values when article prop changes
  useEffect(() => {
    if (article) {
      setTitle(article.title);
      setDescription(article.description);
      setContent(article.content);
      setCategory(article.category);
      setRelatedPage(article.relatedPage);
      setLinkedFeature(article.linkedFeature);
    }
  }, [article]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    if (!title.trim() || !content.trim()) {
      setError('Title and content are required.');
      return;
    }
    try {
      setLoading(true);
      setError('');
      const articleRef = doc(db, 'helpArticles', article.id);
      await updateDoc(articleRef, {
        title: title.trim(),
        description: description.trim() || '',
        content: content.trim(),
        category: category || '',
        relatedPage: relatedPage.trim() || '',
        linkedFeature: linkedFeature || '',
        updatedAt: serverTimestamp(),
      });
      if (onSaved) onSaved();
      onClose();
    } catch (err) {
      console.error('Error updating article:', err);
      setError('Failed to update article. Please try again.');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Help Article</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Title"
            variant="outlined"
            required
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Short Description (optional)"
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            select
            label="Category (optional)"
            fullWidth
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            helperText="Select a category or leave blank"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {CATEGORY_OPTIONS.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Related Page (optional)"
            variant="outlined"
            fullWidth
            value={relatedPage}
            onChange={(e) => setRelatedPage(e.target.value)}
            helperText="Enter the page identifier (e.g., 'onboarding') if applicable"
          />
          {/* Linked Feature Dropdown */}
          <TextField
            select
            label="Linked Feature (optional)"
            variant="outlined"
            fullWidth
            value={linkedFeature}
            onChange={(e) => setLinkedFeature(e.target.value)}
            helperText="Select a feature to link this article to"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {features.map((feat) => (
              <MenuItem key={feat.id} value={feat.id}>
                {feat.title}
              </MenuItem>
            ))}
          </TextField>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Content (Markdown)
            </Typography>
            <SimpleMDE
              value={content}
              onChange={(value) => setContent(value)}
              options={{
                spellChecker: false,
                maxHeight: '300px',
                placeholder: 'Edit your article in Markdown...',
              }}
            />
          </Box>
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ArticlesTab() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filterText, setFilterText] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingArticle, setEditingArticle] = useState(null);
  const [openArticleDialog, setOpenArticleDialog] = useState(false);
  // Load features for the linked feature dropdown (shared with creation and editing)
  const [features, setFeatures] = useState([]);
  const [featuresLoading, setFeaturesLoading] = useState(false);

  const fetchArticles = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'helpArticles'), orderBy(sortConfig.key, sortConfig.direction));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((docSnap) => ({ id: docSnap.id, ...docSnap.data() }));
      setArticles(data);
      setError('');
    } catch (err) {
      console.error(err);
      setError('Failed to load articles.');
    } finally {
      setLoading(false);
    }
  };

  const fetchFeatures = async () => {
    setFeaturesLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'crmFeatures'));
      const featuresData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFeatures(featuresData);
    } catch (err) {
      console.error('Error fetching features:', err);
    } finally {
      setFeaturesLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [sortConfig]);

  useEffect(() => {
    fetchFeatures();
  }, []);

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const filteredArticles = useMemo(() => {
    if (!filterText) return articles;
    const lower = filterText.toLowerCase();
    return articles.filter(
      (article) =>
        (article.title && article.title.toLowerCase().includes(lower)) ||
        (article.description && article.description.toLowerCase().includes(lower))
    );
  }, [articles, filterText]);

  const paginatedArticles = useMemo(() => {
    const start = page * rowsPerPage;
    return filteredArticles.slice(start, start + rowsPerPage);
  }, [filteredArticles, page, rowsPerPage]);

  const handleDeleteArticle = async (id) => {
    try {
      await deleteDoc(doc(db, 'helpArticles', id));
      setArticles((prev) => prev.filter((article) => article.id !== id));
    } catch (err) {
      console.error(err);
      alert('Error deleting article.');
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Help Articles
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label="Search Articles"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ mr: 2, flexGrow: 1 }}
        />
        <Button variant="contained" onClick={fetchArticles} sx={{ mr: 2 }}>
          Refresh
        </Button>
      </Box>
      {loading ? (
        <Box textAlign="center" my={4}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'title'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('title')}
                    >
                      Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'category'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('category')}
                    >
                      Category
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedArticles.map((article) => (
                  <TableRow key={article.id}>
                    <TableCell>{article.title}</TableCell>
                    <TableCell>{article.description || '-'}</TableCell>
                    <TableCell>{article.category || '-'}</TableCell>
                    <TableCell>
                      {article.createdAt
                        ? new Date(article.createdAt.seconds * 1000).toLocaleString()
                        : '-'}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          setEditingArticle(article);
                          setOpenArticleDialog(true);
                        }}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteArticle(article.id)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {paginatedArticles.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No articles found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredArticles.length}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </>
      )}
      {/* Edit Article Dialog */}
      {openArticleDialog && editingArticle && (
        <ArticleDialog
          open={openArticleDialog}
          onClose={() => setOpenArticleDialog(false)}
          article={editingArticle}
          onSaved={fetchArticles}
          features={features} // Pass the features list for the dropdown
        />
      )}
    </Box>
  );
}
