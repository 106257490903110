import React, { useContext, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Box,
  useMediaQuery,
  styled,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { signOut } from 'firebase/auth';
import BigSupportMegaMenu from '/Users/willradley/orthinas-crm/src/components/layout/Components/Navbar/BigSupportMegaMenu.jsx';
// Icons
import { Menu as MenuIcon } from '@mui/icons-material';

// Context & Services
import { AuthContext } from '../../../context/AuthContext';
import { SubscriptionContext } from '../../../context/SubscriptionContext';

import { auth } from '/Users/willradley/orthinas-crm/src/firebase.js';

// Example subcomponents (adjust your import paths as needed)
import CommerceNavBar from '../../MultiSectionSubNav';
import ProfileMenu from './ProfileMenu';

import MobileMenu from '../Mobile/MobileMenu.jsx';
import DesktopLoggedInNavItems from '../Desktop/DesktopLoggedInNavItems.jsx';
import DesktopLoggedInRightItems from '../Desktop/DesktopLoggedInRightItems.jsx';
import MobileLoggedInView from '../Mobile/MobileLoggedInView.jsx';
import DesktopLoggedOutNavItems from '../Desktop/DesktopLoggedOutNavItems.jsx';
import DesktopLoggedOutRightItems from '../Desktop/DesktopLoggedOutRightItems.jsx';
import MobileLoggedOutView from '../Mobile/MobileLoggedOutView.jsx';

// Dark gradient background for AppBar
const GRADIENT_BG = 'linear-gradient(90deg, #212121, #424242)';

// Style objects for buttons/icons/etc.
export const buttonStyles = {
  textTransform: 'none',
  fontWeight: 'bold',
  borderRadius: '20px',
  px: 2,
  color: '#ffffff',
  boxShadow: 'none',
  backgroundColor: 'rgba(255,255,255,0.2)',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
};

export const iconButtonStyles = {
  color: '#fff',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.15)',
  },
};

export const avatarStyles = {
  bgcolor: 'rgba(255,255,255,0.3)',
  color: '#fff',
  fontWeight: 'bold',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    bgcolor: 'rgba(255,255,255,0.5)',
  },
};

/**
 * StyledAppBar uses a style callback that reads `navbarOffset` from props.
 */
const StyledAppBar = styled(AppBar)(({ theme, navbarOffset }) => ({
  position: 'fixed',
  top: navbarOffset,
  zIndex: theme.zIndex.drawer + 1,
  background: GRADIENT_BG,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 0,
  boxShadow: '0px 2px 10px rgba(0,0,0,0.5)',
}));

/**
 * Navbar
 * @param {string} logo - Path to your logo image
 * @param {number} navbarOffset - Offset from the top (e.g., 64 if banner is open, 0 if not)
 */
const Navbar = ({ logo, navbarOffset = 64 }) => {
  // Pull data from contexts
  const { currentUser } = useContext(AuthContext);
  const { currentPlan, planLoading, trialDaysRemaining } = useContext(SubscriptionContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const isCommerceSection = location.pathname.startsWith('/commerce/');

  // State for various menus
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [workAnchorEl, setWorkAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);

  // Submenus (not modified here)
  const [salesAnchorEl, setSalesAnchorEl] = useState(null);
  const [marketingAnchorEl, setMarketingAnchorEl] = useState(null);
  const [serviceAnchorEl, setServiceAnchorEl] = useState(null);
  const [contentAnchorEl, setContentAnchorEl] = useState(null);
  const [operationsAnchorEl, setOperationsAnchorEl] = useState(null);
  const [commerceAnchorEl, setCommerceAnchorEl] = useState(null);

  // Sign-out handler
  const handleSignOut = useCallback(async () => {
    await signOut(auth);
  }, []);

  // Menu handlers
  const handleProfileMenuOpen = (e) => setProfileAnchorEl(e.currentTarget);
  const handleProfileMenuClose = () => setProfileAnchorEl(null);

  const handleMobileMenuOpen = (e) => setMobileAnchorEl(e.currentTarget);
  const handleMobileMenuClose = () => setMobileAnchorEl(null);
  const handleHelpMenuOpen = (e) => setHelpAnchorEl(e.currentTarget);
  const handleHelpMenuClose = () => setHelpAnchorEl(null);
  const handleSalesMenuOpen = (e) => setSalesAnchorEl(e.currentTarget);
  const handleSalesMenuClose = () => setSalesAnchorEl(null);
  const handleMarketingMenuOpen = (e) => setMarketingAnchorEl(e.currentTarget);
  const handleMarketingMenuClose = () => setMarketingAnchorEl(null);
  const handleServiceMenuOpen = (e) => setServiceAnchorEl(e.currentTarget);
  const handleServiceMenuClose = () => setServiceAnchorEl(null);
  const handleContentMenuOpen = (e) => setContentAnchorEl(e.currentTarget);
  const handleContentMenuClose = () => setContentAnchorEl(null);
  const handleOperationsMenuOpen = (e) => setOperationsAnchorEl(e.currentTarget);
  const handleOperationsMenuClose = () => setOperationsAnchorEl(null);
  const handleCommerceMenuOpen = (e) => setCommerceAnchorEl(e.currentTarget);
  const handleCommerceMenuClose = () => setCommerceAnchorEl(null);

  // Derive user initial for avatar
  const userInitial = useMemo(() => {
    if (!currentUser) return 'U';
    return (currentUser.displayName?.[0] || currentUser.email?.[0] || 'U').toUpperCase();
  }, [currentUser]);

  const userNameOrEmail = currentUser?.displayName || currentUser?.email || 'User';
  const userEmail = currentUser?.email;

  // Desktop: Logged-in Nav
  const desktopLoggedInNavItems = (
    <DesktopLoggedInNavItems
      buttonStyles={buttonStyles}
      handleSalesMenuOpen={handleSalesMenuOpen}
      salesAnchorEl={salesAnchorEl}
      handleSalesMenuClose={handleSalesMenuClose}
      handleMarketingMenuOpen={handleMarketingMenuOpen}
      marketingAnchorEl={marketingAnchorEl}
      handleMarketingMenuClose={handleMarketingMenuClose}
      handleServiceMenuOpen={handleServiceMenuOpen}
      serviceAnchorEl={serviceAnchorEl}
      handleServiceMenuClose={handleServiceMenuClose}
      handleContentMenuOpen={handleContentMenuOpen}
      contentAnchorEl={contentAnchorEl}
      handleContentMenuClose={handleContentMenuClose}
      handleOperationsMenuOpen={handleOperationsMenuOpen}
      operationsAnchorEl={operationsAnchorEl}
      handleOperationsMenuClose={handleOperationsMenuClose}
      handleCommerceMenuOpen={handleCommerceMenuOpen}
      commerceAnchorEl={commerceAnchorEl}
      handleCommerceMenuClose={handleCommerceMenuClose}
    />
  );

  // Desktop: Logged-in Right Items – note that we now pass the currentUser.uid, photoURL, and displayName
  const desktopLoggedInRightItems = currentUser && (
    <DesktopLoggedInRightItems
      userName={currentUser.displayName || currentUser.email}
      userEmail={currentUser.email}
      userAvatar={currentUser.photoURL}
      userId={currentUser.uid}
    />
  );

  // Mobile: Logged-in view – pass userId so the search bar works
  const mobileLoggedInView = currentUser && (
    <MobileLoggedInView
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
      mobileAnchorEl={mobileAnchorEl}
      handleMobileMenuOpen={handleMobileMenuOpen}
      userId={currentUser.uid}
    />
  );

  // Logged-out views (unchanged)
  const desktopLoggedOutNavItems = (
    <DesktopLoggedOutNavItems buttonStyles={buttonStyles} />
  );
  const desktopLoggedOutRightItems = (
    <DesktopLoggedOutRightItems
      buttonStyles={buttonStyles}
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
    />
  );
  const mobileLoggedOutView = (
    <MobileLoggedOutView
      iconButtonStyles={iconButtonStyles}
      helpAnchorEl={helpAnchorEl}
      handleHelpMenuOpen={handleHelpMenuOpen}
      mobileAnchorEl={mobileAnchorEl}
      handleMobileMenuOpen={handleMobileMenuOpen}
    />
  );

  return (
    <>
      {/* Pass `navbarOffset` to position the AppBar below the banner (if open) */}
      <StyledAppBar navbarOffset={navbarOffset} elevation={4}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {/* LEFT: Logo + (Desktop Nav if not mobile) */}
          <Box display="flex" alignItems="center" gap={2}>
            <Link
              to="/"
              style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
            >
              {logo && (
                <Box
                  component="img"
                  src={logo}
                  alt="Logo"
                  sx={{ height: 22, width: 'auto', mr: 1 }}
                />
              )}
            </Link>

            {/* Conditionally render full navigation items (desktop) */}
            {currentUser && !isMobile && desktopLoggedInNavItems}
            {!currentUser && !isMobile && desktopLoggedOutNavItems}
          </Box>

          {/* RIGHT: Either logged in or logged out */}
          <Box display="flex" alignItems="center" gap={2}>
            {currentUser
              ? isMobile
                ? mobileLoggedInView
                : desktopLoggedInRightItems
              : isMobile
              ? mobileLoggedOutView
              : desktopLoggedOutRightItems}
          </Box>
        </Toolbar>

        {/* Mobile Menu */}
        <MobileMenu
          anchorEl={mobileAnchorEl}
          open={Boolean(mobileAnchorEl)}
          onClose={handleMobileMenuClose}
          currentUser={currentUser}
          userNameOrEmail={userNameOrEmail}
          userEmail={userEmail}
          handleSignOut={handleSignOut}
          tasksAnchorEl={workAnchorEl}
        />

        {/* Profile Menu (only if logged in) */}
        {currentUser && (
          <ProfileMenu
            anchorEl={profileAnchorEl}
            open={Boolean(profileAnchorEl)}
            onClose={handleProfileMenuClose}
            userNameOrEmail={userNameOrEmail}
            userEmail={userEmail}
            currentPlan={currentPlan}
            planLoading={planLoading}
            trialDaysRemaining={trialDaysRemaining}
            handleSignOut={handleSignOut}
          />
        )}

       
        {/* Help Menu */}
        <BigSupportMegaMenu
          anchorEl={helpAnchorEl}
          open={Boolean(helpAnchorEl)}
          onClose={handleHelpMenuClose}
        />
      </StyledAppBar>

      {/* If user is in /commerce path, render CommerceNavBar below main Nav */}
      {isCommerceSection && <CommerceNavBar />}
    </>
  );
};

Navbar.propTypes = {
  logo: PropTypes.string,
  navbarOffset: PropTypes.number,
};

// Set default logo to your provided URL
Navbar.defaultProps = {
  logo:
    'https://firebasestorage.googleapis.com/v0/b/finli-efed8.firebasestorage.app/o/RCFOrthinasLight.png?alt=media&token=a7e2d893-dd56-47c4-9328-9a64d4be8f21',
};

export default React.memo(Navbar);
