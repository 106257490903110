// File: src/components/Navbar/DesktopLoggedOutNavItems.jsx
import React, { useState } from 'react';
import { Stack, Tooltip, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

// Mega-menu components for each category
import BigProductMegaMenu from '../Components/Navbar/BigProductMegaMenu';
import BigSolutionsMegaMenu from '../Components/Navbar/BigSolutionsMegaMenu';
import BigResourcesMegaMenu from '../Components/Navbar/BigResourcesMegaMenu';
import BigCustomersMegaMenu from '../Components/Navbar/BigCustomersMegaMenu';
import BigCompanyMegaMenu from '../Components/Navbar/BigCompanyMegaMenu';

// Define the menu items (which are easy to modify later)
const menuItems = [
  { label: 'Product', MegaMenuComponent: BigProductMegaMenu },
  { label: 'Solutions', MegaMenuComponent: BigSolutionsMegaMenu },
  { label: 'Resources', MegaMenuComponent: BigResourcesMegaMenu },
  { label: 'Customers', MegaMenuComponent: BigCustomersMegaMenu },
  { label: 'Company', MegaMenuComponent: BigCompanyMegaMenu },
];

export default function DesktopLoggedOutNavItems({ buttonStyles }) {
  // Use a single state object for all anchor elements keyed by menu label
  const [menuAnchorEls, setMenuAnchorEls] = useState({});

  const handleMenuOpen = (label, event) => {
    setMenuAnchorEls((prev) => ({ ...prev, [label]: event.currentTarget }));
  };

  const handleMenuClose = (label) => {
    setMenuAnchorEls((prev) => ({ ...prev, [label]: null }));
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {menuItems.map(({ label, MegaMenuComponent }) => {
        const open = Boolean(menuAnchorEls[label]);
        return (
          <Box key={label}>
            <Tooltip title={label} arrow>
              <Button
                onClick={(e) => handleMenuOpen(label, e)}
                sx={{
                  ...buttonStyles,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  transition: 'color 0.3s, border-bottom 0.3s, transform 0.3s',
                  '&:hover': {
                    color: '#fff',              // Use white for text color on hover
                    borderBottom: '2px solid #fff', // White underline on hover
                    transform: 'translateY(-1px)',
                  },
                }}
              >
                {label}
              </Button>
            </Tooltip>
            <MegaMenuComponent
              anchorEl={menuAnchorEls[label]}
              open={open}
              onClose={() => handleMenuClose(label)}
            />
          </Box>
        );
      })}
    </Stack>
  );
}
