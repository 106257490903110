import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  Paper,
  Slide,
  CircularProgress,
  Tooltip,
  Divider,
  Avatar,
  Fade,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import { collection, addDoc } from 'firebase/firestore';
import { AuthContext } from '/Users/willradley/orthinas-crm/src/context/AuthContext.js';
import ArticleSearch from '/Users/willradley/orthinas-crm/src/components/ChatWidget/ArticleSearch.jsx';

// Define your route constants
const LINKS = {
  helpCenter: '/support/help-center',
  pricing: '/signup',
  community: '/orthinaut-community',
};

// Simple translations (expand with more languages as needed)
const translations = {
  en: {
    homeTitle: "How can we help?",
    homeWelcome: "Welcome!",
    homeSubtext: "Explore our resources or start a chat.",
    startChat: "Speak to a human",
    chatTitle: "Contact Our Team",
    chatSubtext: "Tell us about your needs. We’ll send your inquiry to our team, and they’ll get back to you ASAP.",
    personalizedGreeting: "Hi {name}, how can we help you today?",
    sending: "Sending...",
    sent: "Sent!",
    sendInquiry: "Send Inquiry",
    newInquiry: "New Inquiry",
    close: "Close",
  },
};

const initialContactDetails = {
  name: '',
  email: '',
  reason: '',
  message: '',
};

export default function ChatWidget() {
  // ----------------------------
  // Widget and Form States
  // ----------------------------
  const [open, setOpen] = useState(false);
  const [minimized, setMinimized] = useState(false);
  // activeView: 'home' or 'chat'
  const [activeView, setActiveView] = useState('home');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [contactDetails, setContactDetails] = useState(initialContactDetails);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [sendingInfo, setSendingInfo] = useState(false);

  // ----------------------------
  // Auth Context for Personalization
  // ----------------------------
  const { currentUser, userProfile } = useContext(AuthContext);
  // Determine language (default to English)
  const language = userProfile?.language || 'en';
  const t = translations[language];

  // Pre-fill name and email if available
  useEffect(() => {
    if (userProfile) {
      setContactDetails((prev) => ({
        ...prev,
        name: prev.name || userProfile.displayName || '',
        email: prev.email || userProfile.email || '',
      }));
    }
  }, [userProfile]);

  // ----------------------------
  // Refs and Auto-Focus
  // ----------------------------
  const nameInputRef = useRef(null);
  useEffect(() => {
    if (open && !minimized && activeView === 'chat' && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [open, minimized, activeView]);

  // ----------------------------
  // Keyboard Shortcuts (Escape key)
  // ----------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleCloseWidget();
      }
    };
    if (open) window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [open]);

  // ----------------------------
  // Handler: Input Changes
  // ----------------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prev) => ({ ...prev, [name]: value }));
    if (errorMessage) setErrorMessage('');
  };

  // ----------------------------
  // Handler: Validate Form Details
  // ----------------------------
  const validateDetails = () => {
    if (!contactDetails.name.trim()) return 'Please enter your name.';
    if (!contactDetails.email.trim()) return 'Please enter your email.';
    if (!/\S+@\S+\.\S+/.test(contactDetails.email))
      return 'Please enter a valid email address.';
    if (!contactDetails.reason.trim())
      return 'Please select a reason for contacting us.';
    if (!contactDetails.message.trim()) return 'Please enter a message.';
    return '';
  };

  // ----------------------------
  // Handler: Reset Form
  // ----------------------------
  const resetForm = () => {
    setContactDetails(initialContactDetails);
    setErrorMessage('');
    setFormSubmitted(false);
    setEmailSent(false);
  };

  // ----------------------------
  // Handler: Close Widget
  // ----------------------------
  const handleCloseWidget = () => {
    setOpen(false);
    setMinimized(false);
    resetForm();
    setActiveView('home'); // Reset view to home when widget closes.
  };

  // ----------------------------
  // Handler: Toggle Minimize/Expand
  // ----------------------------
  const handleToggleMinimize = () => {
    setMinimized((prev) => !prev);
  };

  // ----------------------------
  // Handler: Form Submission
  // ----------------------------
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    const validationError = validateDetails();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }
    try {
      setSendingInfo(true);
      setErrorMessage('');
      setEmailSent(false);
      const mailRef = collection(db, 'mail');
      await addDoc(mailRef, {
        to: 'willradley@rcfgroup.org',
        message: {
          subject: 'New Sales Inquiry',
          html: `
            <h2>New Inquiry from Website Contact Form</h2>
            <p>Details:</p>
            <ul>
              <li><strong>Name:</strong> ${contactDetails.name}</li>
              <li><strong>Email:</strong> ${contactDetails.email}</li>
              <li><strong>Reason:</strong> ${contactDetails.reason}</li>
              <li><strong>Message:</strong> ${contactDetails.message}</li>
            </ul>
          `,
        },
      });
      setEmailSent(true);
      setFormSubmitted(true);
    } catch (err) {
      console.error('Error sending contact details:', err);
      setErrorMessage('Unable to send your details. Please try again later.');
    } finally {
      setSendingInfo(false);
    }
  };

  return (
    <>
      {/* Floating Chat Button */}
      {!open && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1300,
            '&:hover': { transform: 'scale(1.1)' },
            transition: 'transform 0.2s',
          }}
        >
          <Tooltip title="Open Chat" arrow>
            <IconButton
              color="primary"
              onClick={() => setOpen(true)}
              aria-label="Open chat widget"
              sx={{
                backgroundColor: 'white',
                boxShadow: 4,
              }}
            >
              <ChatIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      {/* Chat Widget Panel */}
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Paper
          elevation={8}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            width: { xs: '90vw', sm: 360 },
            height: minimized ? 50 : { xs: '70vh', sm: 540 },
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1300,
            transition: 'height 0.3s ease',
            overflow: 'hidden',
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 2,
              background: 'linear-gradient(45deg, #2575fc, #6a11cb)',
              color: '#fff',
              boxShadow: 3,
            }}
          >
            {activeView === 'chat' && (
              <Tooltip title="Back" arrow>
                <IconButton
                  onClick={() => setActiveView('home')}
                  aria-label="Back to home"
                  sx={{ color: 'white' }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              {userProfile?.photoURL && (
                <Avatar
                  src={userProfile.photoURL}
                  alt={userProfile.displayName || 'User'}
                  sx={{ width: 36, height: 36, mr: 1 }}
                />
              )}
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {activeView === 'home' ? t.homeTitle : t.chatTitle}
              </Typography>
            </Box>
            <Tooltip title={minimized ? 'Expand' : 'Minimize'} arrow>
              <IconButton
                size="small"
                onClick={handleToggleMinimize}
                aria-label={minimized ? 'Expand chat' : 'Minimize chat'}
                sx={{ color: 'white' }}
              >
                <MinimizeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close" arrow>
              <IconButton
                size="small"
                onClick={handleCloseWidget}
                aria-label="Close chat widget"
                sx={{ color: 'white' }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Content */}
          {!minimized && (
            <>
              {/* Home View */}
              <Fade in={activeView === 'home'} timeout={500} unmountOnExit>
                <Box
                  sx={{
                    p: 3,
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    overflowY: 'auto',
                  }}
                >
                  {activeView === 'home' && (
                    <>
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', textAlign: 'center' }}>
                        {t.homeWelcome}
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary', textAlign: 'center' }}>
                        {t.homeSubtext}
                      </Typography>

                      {/* Resource Buttons */}
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            '&:hover': { transform: 'scale(1.02)', boxShadow: 3 },
                          }}
                          onClick={() => window.open(LINKS.helpCenter, '_blank')}
                        >
                          Help Center
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            '&:hover': { transform: 'scale(1.02)', boxShadow: 3 },
                          }}
                          onClick={() => window.open(LINKS.pricing, '_blank')}
                        >
                          Pricing
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            '&:hover': { transform: 'scale(1.02)', boxShadow: 3 },
                          }}
                          onClick={() => window.open(LINKS.community, '_blank')}
                        >
                          Community
                        </Button>
                      </Box>

                      <Divider sx={{ my: 2 }} />

                      {/* Helpful Articles Section */}
                      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                        Helpful Articles
                      </Typography>
                      <ArticleSearch />

                      <Divider sx={{ my: 2 }} />

                     
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        sx={{
                          textTransform: 'none',
                          '&:hover': { transform: 'scale(1.02)' },
                        }}
                        onClick={() => setActiveView('chat')}
                      >
                        {t.startChat}
                      </Button>
                    </>
                  )}
                </Box>
              </Fade>

              {/* Chat View */}
              <Fade in={activeView === 'chat'} timeout={500} unmountOnExit>
                <Box
                  sx={{
                    p: 3,
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    overflowY: 'auto',
                  }}
                >
                  {activeView === 'chat' && (
                    <>
                      {userProfile?.displayName && (
                        <Typography variant="body1" sx={{ mb: 2, fontWeight: 'medium' }}>
                          {t.personalizedGreeting.replace('{name}', userProfile.displayName)}
                        </Typography>
                      )}
                      {!formSubmitted ? (
                        <form onSubmit={handleSubmit} noValidate>
                          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                            {t.chatSubtext}
                          </Typography>
                          {errorMessage && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                              {errorMessage}
                            </Alert>
                          )}
                          <TextField
                            name="name"
                            label="Full Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ mb: 2 }}
                            placeholder="e.g. John Doe"
                            value={contactDetails.name}
                            onChange={handleInputChange}
                            inputRef={nameInputRef}
                          />
                          <TextField
                            name="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            size="small"
                            sx={{ mb: 2 }}
                            type="email"
                            placeholder="e.g. john@mycompany.com"
                            value={contactDetails.email}
                            onChange={handleInputChange}
                          />
                          <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                            <InputLabel id="reason-label">Reason for Contact</InputLabel>
                            <Select
                              labelId="reason-label"
                              name="reason"
                              label="Reason for Contact"
                              value={contactDetails.reason}
                              onChange={handleInputChange}
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                transformOrigin: { vertical: 'top', horizontal: 'left' },
                                PaperProps: { style: { zIndex: 9999 } },
                              }}
                            >
                              <MenuItem value="">
                                <em>-- Select --</em>
                              </MenuItem>
                              <MenuItem value="Sales">Sales</MenuItem>
                              <MenuItem value="Support">Support</MenuItem>
                              <MenuItem value="Feedback">Feedback</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            name="message"
                            label="Your Message"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder="Let us know how we can help..."
                            value={contactDetails.message}
                            onChange={handleInputChange}
                            sx={{ mb: 2 }}
                          />
                          <Box sx={{ position: 'relative' }}>
                            <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              disabled={sendingInfo || emailSent}
                              sx={{ textTransform: 'none' }}
                            >
                              {sendingInfo ? t.sending : emailSent ? t.sent : t.sendInquiry}
                            </Button>
                            {sendingInfo && (
                              <CircularProgress
                                size={24}
                                sx={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              />
                            )}
                          </Box>
                        </form>
                      ) : (
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                          <Alert severity="success" sx={{ mb: 2 }}>
                            We've sent your inquiry. We’ll be in touch shortly!
                          </Alert>
                          <Typography variant="body2" sx={{ mb: 2 }}>
                            Thank you for reaching out.
                          </Typography>
                          <Box display="flex" justifyContent="space-between">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={resetForm}
                              sx={{ textTransform: 'none' }}
                            >
                              {t.newInquiry}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleCloseWidget}
                              sx={{ textTransform: 'none' }}
                            >
                              {t.close}
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Fade>
            </>
          )}
        </Paper>
      </Slide>
    </>
  );
}
