// File: src/components/Navbar/MobileLoggedOutView.jsx
import React, { useState } from 'react';
import {
  Tooltip,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Divider,
  Box,
  Typography,
} from '@mui/material';
import {
  HelpOutline as HelpOutlineIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

import BigProductMegaMenu from '../Components/Navbar/BigProductMegaMenu';
import BigSolutionsMegaMenu from '../Components/Navbar/BigSolutionsMegaMenu';
import BigResourcesMegaMenu from '../Components/Navbar/BigResourcesMegaMenu';
import BigCustomersMegaMenu from '../Components/Navbar/BigCustomersMegaMenu';
import BigCompanyMegaMenu from '../Components/Navbar/BigCompanyMegaMenu';
// Import the new Sales mega menu component
import BigSalesMegaMenu from '../Components/Navbar/BigSalesMegaMenu';

function IconTooltipButton({ title, label, IconComponent, onClick, sx, anchor }) {
  return (
    <Tooltip title={title} arrow>
      <IconButton
        onClick={onClick}
        sx={sx}
        aria-label={label}
        aria-haspopup="true"
        aria-expanded={Boolean(anchor)}
      >
        <IconComponent />
      </IconButton>
    </Tooltip>
  );
}

export default function MobileLoggedOutView({
  iconButtonStyles,
  handleHelpMenuOpen,
  helpAnchorEl,
  mobileAnchorEl,
  handleMobileMenuOpen,
}) {
  // Top-level actions (Help and Hamburger)
  const topActions = [
    {
      title: 'Open mobile menu',
      label: 'Open mobile menu',
      IconComponent: MenuIcon,
      onClick: handleMobileMenuOpen,
      anchor: mobileAnchorEl,
    },
  ];

  // Local anchor state for the hamburger menu
  const [hamburgerAnchor, setHamburgerAnchor] = useState(null);

  // Anchor states for mega menus
  const [productAnchorEl, setProductAnchorEl] = useState(null);
  const [solutionsAnchorEl, setSolutionsAnchorEl] = useState(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);
  const [customersAnchorEl, setCustomersAnchorEl] = useState(null);
  const [companyAnchorEl, setCompanyAnchorEl] = useState(null);
  // New: Anchor state for the Sales mega menu
  const [salesAnchorEl, setSalesAnchorEl] = useState(null);

  // Booleans for each mega menu
  const openProductMenu = Boolean(productAnchorEl);
  const openSolutionsMenu = Boolean(solutionsAnchorEl);
  const openResourcesMenu = Boolean(resourcesAnchorEl);
  const openCustomersMenu = Boolean(customersAnchorEl);
  const openCompanyMenu = Boolean(companyAnchorEl);
  const openSalesMenu = Boolean(salesAnchorEl);

  // Handlers for each mega menu
  const handleProductMenuOpen = (e) => setProductAnchorEl(e.currentTarget);
  const handleProductMenuClose = () => setProductAnchorEl(null);
  const handleSolutionsMenuOpen = (e) => setSolutionsAnchorEl(e.currentTarget);
  const handleSolutionsMenuClose = () => setSolutionsAnchorEl(null);
  const handleResourcesMenuOpen = (e) => setResourcesAnchorEl(e.currentTarget);
  const handleResourcesMenuClose = () => setResourcesAnchorEl(null);
  const handleCustomersMenuOpen = (e) => setCustomersAnchorEl(e.currentTarget);
  const handleCustomersMenuClose = () => setCustomersAnchorEl(null);
  const handleCompanyMenuOpen = (e) => setCompanyAnchorEl(e.currentTarget);
  const handleCompanyMenuClose = () => setCompanyAnchorEl(null);
  // Handlers for Sales mega menu
  const handleSalesMenuOpen = (e) => setSalesAnchorEl(e.currentTarget);
  const handleSalesMenuClose = () => setSalesAnchorEl(null);

  // Handlers for the hamburger menu
  const handleHamburgerClick = (event) => setHamburgerAnchor(event.currentTarget);
  const handleHamburgerClose = () => setHamburgerAnchor(null);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {/* Top-level icons */}
      {topActions.map((action) => (
        <IconTooltipButton
          key={action.title}
          title={action.title}
          label={action.label}
          IconComponent={action.IconComponent}
          onClick={
            action.label === 'Open mobile menu'
              ? handleHamburgerClick
              : action.onClick
          }
          sx={iconButtonStyles}
          anchor={action.anchor}
        />
      ))}

      {/* Hamburger Menu */}
      <Menu
        anchorEl={hamburgerAnchor}
        open={Boolean(hamburgerAnchor)}
        onClose={handleHamburgerClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            minWidth: 200,
            backgroundColor: '#212121',
            color: '#fff',
            '& .MuiMenuItem-root': {
              color: '#fff',
              '&:hover': { backgroundColor: '#424242' },
            },
            '& .MuiDivider-root': {
              backgroundColor: 'rgba(255,255,255,0.2)',
            },
          },
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleProductMenuOpen(e);
          }}
        >
          Product
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleSolutionsMenuOpen(e);
          }}
        >
          Solutions
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleResourcesMenuOpen(e);
          }}
        >
          Resources
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleCustomersMenuOpen(e);
          }}
        >
          Customers
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleCompanyMenuOpen(e);
          }}
        >
          Company
        </MenuItem>
        {/* New: Sales MenuItem replaces Schedule Meeting */}
        <MenuItem
          onClick={(e) => {
            handleHamburgerClose();
            handleSalesMenuOpen(e);
          }}
        >
          Contact Sales
        </MenuItem>
        <Divider sx={{ my: 1 }} />
        <MenuItem component={RouterLink} to="/signin" onClick={handleHamburgerClose}>
          Sign In
        </MenuItem>
        <MenuItem component={RouterLink} to="/signup" onClick={handleHamburgerClose}>
          Sign Up
        </MenuItem>
      </Menu>

      {/* Mega Menus */}
      <BigProductMegaMenu
        anchorEl={productAnchorEl}
        open={openProductMenu}
        onClose={handleProductMenuClose}
      />
      <BigSolutionsMegaMenu
        anchorEl={solutionsAnchorEl}
        open={openSolutionsMenu}
        onClose={handleSolutionsMenuClose}
      />
      <BigResourcesMegaMenu
        anchorEl={resourcesAnchorEl}
        open={openResourcesMenu}
        onClose={handleResourcesMenuClose}
      />
      <BigCustomersMegaMenu
        anchorEl={customersAnchorEl}
        open={openCustomersMenu}
        onClose={handleCustomersMenuClose}
      />
      <BigCompanyMegaMenu
        anchorEl={companyAnchorEl}
        open={openCompanyMenu}
        onClose={handleCompanyMenuClose}
      />
      <BigSalesMegaMenu
        anchorEl={salesAnchorEl}
        open={openSalesMenu}
        onClose={handleSalesMenuClose}
      />
    </Stack>
  );
}
