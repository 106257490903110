import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Paper,
  Alert,
  Grow,
  Card,
  CardContent,
  Divider,
  Button,
  Stack,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';

// Helper function to filter articles by search term
function filterArticles(articles, query) {
  if (!query.trim()) return articles;
  const lower = query.toLowerCase();
  return articles.filter(
    (article) =>
      (article.title && article.title.toLowerCase().includes(lower)) ||
      (article.content && article.content.toLowerCase().includes(lower))
  );
}

// A simple component to preview article content (truncated)
function ArticlePreview({ article, maxLength = 100 }) {
  const content = article.content || '';
  const isLong = content.length > maxLength;
  const preview = isLong ? content.slice(0, maxLength) + '...' : content;
  return (
    <Typography variant="body2" color="text.secondary">
      {preview}
    </Typography>
  );
}

export default function ArticleSearch() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch articles on mount
  useEffect(() => {
    async function fetchArticles() {
      try {
        const snap = await getDocs(collection(db, 'helpArticles'));
        const loaded = [];
        snap.forEach((doc) => {
          loaded.push({ id: doc.id, ...doc.data() });
        });
        setArticles(loaded);
      } catch (err) {
        console.error('Error loading articles:', err);
        setError('Unable to load articles. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    fetchArticles();
  }, []);

  // Filter articles using useMemo for performance
  const filteredArticles = useMemo(
    () => filterArticles(articles, searchTerm),
    [articles, searchTerm]
  );

  return (
    <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
      {/* Search Input */}
      <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
        <TextField
          fullWidth
          size="small"
          label="Search Articles"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <IconButton onClick={() => setSearchTerm('')} sx={{ p: 1 }}>
            <ClearIcon />
          </IconButton>
        )}
      </Stack>

      {/* Loading & Error States */}
      {loading ? (
        <Box textAlign="center" sx={{ py: 2 }}>
          <CircularProgress size={24} />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : filteredArticles.length === 0 ? (
        <Typography variant="body2" color="text.secondary" align="center">
          No articles found.
        </Typography>
      ) : (
        <>
          {/* Article Previews */}
          {filteredArticles.slice(0, 3).map((article, idx) => (
            <Grow
              in
              style={{ transformOrigin: '0 0 0' }}
              timeout={300 + idx * 100}
              key={article.id}
            >
              <Card variant="outlined" sx={{ mb: 1 }}>
                <CardContent>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    {article.title}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <ArticlePreview article={article} />
                  <Button
                    size="small"
                    component={Link}
                    to={`/knowledge-base/article/${article.id}`}
                    sx={{ mt: 1, textTransform: 'none' }}
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grow>
          ))}

          {/* Show All Articles Link */}
          {filteredArticles.length > 3 && (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Button
                variant="outlined"
                component={Link}
                to="/knowledge-base"
                sx={{ textTransform: 'none' }}
              >
                Show All Articles
              </Button>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
}
