// File: src/components/Navbar/BigProfileMegaMenu.jsx
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Menu,
  Fade,
  Grid,
  Typography,
  Button,
  Divider,
  Avatar,
  IconButton,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloseIcon from '@mui/icons-material/Close';
import { signOut } from 'firebase/auth';
import { AuthContext } from '/Users/willradley/orthinas-crm/src/context/AuthContext.js';
import { auth } from '/Users/willradley/orthinas-crm/src/firebase.js';

export default function BigProfileMegaMenu({ anchorEl, open, onClose }) {
  const { currentUser, userProfile } = useContext(AuthContext);
  const navigate = useNavigate();

  // Improvement 1: Fetch the latest custom claims so we know if the user is admin.
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (currentUser) {
      currentUser
        .getIdTokenResult(true)
        .then((idTokenResult) => {
          // Set isAdmin to true if the custom claim "isAdmin" is truthy.
          setIsAdmin(!!idTokenResult.claims.isAdmin);
        })
        .catch((error) => {
          console.error('Error fetching token result:', error);
          setIsAdmin(false);
        });
    }
  }, [currentUser]);

  // Determine display values using optional chaining.
  const displayName = userProfile?.displayName ?? currentUser?.email ?? 'User';
  const email = currentUser?.email ?? 'user@example.com';
  const userAvatar = userProfile?.avatarUrl ?? currentUser?.photoURL ?? '';
  const planLabel = userProfile?.planLabel; // Optional plan label

  // Improvement 2: Determine the account type.
  // If the user is admin (per the token claim), we display "Admin."
  // Otherwise, we use a stored accountType (if available) or default to "Standard".
  const accountType = isAdmin
    ? 'Admin'
    : userProfile?.accountType || 'Standard';

  // Handle logout.
  const handleLogout = async () => {
    try {
      await signOut(auth);
      onClose();
      navigate('/signin', { replace: true });
    } catch (error) {
      console.error('Error signing out:', error);
      // Optionally add UI feedback here.
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '95vw',
          p: 0, // We handle inner padding manually.
          borderRadius: 3,
          boxShadow: 8,
          maxHeight: '75vh',
          overflowY: 'auto',
        },
      }}
      aria-label="Profile menu"
    >
      {/* Header Section */}
      <Box
        sx={{
          position: 'relative',
          px: 3,
          py: 2,
          backgroundColor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {/* Close Icon */}
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 8, right: 8, color: 'text.secondary' }}
          aria-label="Close profile menu"
        >
          <CloseIcon />
        </IconButton>
        {/* User Info */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={userAvatar}
            alt={displayName}
            sx={{
              width: 64,
              height: 64,
              border: '2px solid',
              borderColor: 'grey.300',
            }}
          >
            {(!userAvatar && displayName) ? displayName.charAt(0) : null}
          </Avatar>
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {displayName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {email}
            </Typography>
            {planLabel && (
              <Typography
                variant="caption"
                color="primary"
                sx={{ display: 'block', mt: 0.5 }}
              >
                Current Plan: {planLabel}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Divider />

      {/* Action Grid */}
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<AccountCircleIcon />}
              component={Link}
              to="/account"
              onClick={onClose}
              sx={{ textTransform: 'none' }}
              aria-label="Go to your account"
            >
              Your Account
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<SettingsIcon />}
              component={Link}
              to="/settings"
              onClick={onClose}
              sx={{ textTransform: 'none' }}
              aria-label="Open settings"
            >
              Settings
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="error"
              startIcon={<ExitToAppIcon />}
              onClick={handleLogout}
              sx={{ textTransform: 'none' }}
              aria-label="Log out"
            >
              Logout
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {/* Improvement 3: Display the Account Type */}
        <Box textAlign="center" sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Account Type: {accountType}
          </Typography>
        </Box>

        {/* Optional Footer CTA */}
        <Box textAlign="center">
          <Button
            variant="text"
            component={Link}
            to="/dashboard"
            onClick={onClose}
            sx={{ textTransform: 'none', fontWeight: 600 }}
          >
            Go to Dashboard
          </Button>
        </Box>
      </Box>
    </Menu>
  );
}
