import React, { useState } from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  Box,
  Button,
  Divider,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotifications } from '/Users/willradley/orthinas-crm/src/hooks/useNotifications.js';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import { useNavigate } from 'react-router-dom';

export default function Notifications({ userId }) {
  const { notifications, loading } = useNotifications(userId);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Open/close the notifications menu
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // Count unread notifications
  const unreadCount = notifications.filter((n) => !n.read).length;

  // Mark a single notification as read
  const markAsRead = async (notificationId) => {
    try {
      const notifRef = doc(db, 'notifications', notificationId);
      await updateDoc(notifRef, { read: true });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  // Mark all notifications as read using a batch update
  const markAllAsRead = async () => {
    try {
      const batch = writeBatch(db);
      notifications.forEach((notification) => {
        if (!notification.read) {
          const notifRef = doc(db, 'notifications', notification.id);
          batch.update(notifRef, { read: true });
        }
      });
      await batch.commit();
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
    }
  };

  // Handle clicking a notification. If it has a link, navigate to it.
  const handleNotificationClick = async (notification) => {
    await markAsRead(notification.id);
    if (notification.link) {
      navigate(notification.link);
    }
    handleClose();
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick} aria-label="Notifications">
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: { width: 350, maxHeight: 400 },
        }}
      >
        {/* Header with title and "Mark All as Read" */}
        <Box
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Notifications</Typography>
          {notifications.length > 0 && unreadCount > 0 && (
            <Button onClick={markAllAsRead} size="small">
              Mark All as Read
            </Button>
          )}
        </Box>
        <Divider />

        {/* Notification Items */}
        {loading ? (
          <MenuItem>
            <Typography>Loading...</Typography>
          </MenuItem>
        ) : notifications.length === 0 ? (
          <MenuItem>
            <Typography>No notifications</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              sx={{
                backgroundColor: !notification.read ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              }}
            >
              <ListItemText
                primary={notification.message}
                secondary={
                  notification.timestamp
                    ? new Date(notification.timestamp.seconds * 1000).toLocaleString()
                    : ''
                }
              />
            </MenuItem>
          ))
        )}

     
      </Menu>
    </>
  );
}
