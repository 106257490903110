// File: src/pages/admin/UsersTab.jsx
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  TextField,
  TableSortLabel,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TablePagination,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';

const QuickActionBar = ({ onRefresh, onExport }) => (
  <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1} mb={2}>
    <Button variant="outlined" onClick={onRefresh} sx={{ mr: 1 }}>
      Refresh
    </Button>
    {onExport && (
      <Button variant="contained" color="primary" onClick={onExport}>
        Export
      </Button>
    )}
  </Box>
);

const getOnboardingStatus = (user) => {
  if (user.draft === false) return { label: 'Completed', color: 'success' };
  if (user.displayName || user.business || user.phone || user.businessType || (user.address && Object.values(user.address).some(val => !!val))) {
    return { label: 'Started', color: 'warning' };
  }
  return { label: 'Not Started', color: 'default' };
};

const OnboardingDialog = ({ user, open, onClose }) => {
  if (!user) return null;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Onboarding Details for {user.displayName || user.id}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography variant="subtitle1">
            <strong>Status:</strong> {getOnboardingStatus(user).label}
          </Typography>
          {/* Additional onboarding details can be rendered here */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default function UsersTab() {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState(null);
  const [userFilterText, setUserFilterText] = useState('');
  const [userSortConfig, setUserSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [openOnboardingUser, setOpenOnboardingUser] = useState(null);
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);

  const fetchUsers = async () => {
    setUsersLoading(true);
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, orderBy('createdAt', userSortConfig.direction));
      const querySnapshot = await getDocs(q);
      const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersData);
      setUsersError(null);
    } catch (err) {
      console.error(err);
      setUsersError('Failed to load user accounts.');
    } finally {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [userSortConfig]);

  const handleUserSort = useCallback((key) => {
    setUserSortConfig(prev => {
      const direction = prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  }, []);

  const filteredUsers = useMemo(() => {
    const lowerFilter = userFilterText.toLowerCase();
    return users.filter(user =>
      (user.id && user.id.toLowerCase().includes(lowerFilter)) ||
      (user.displayName && user.displayName.toLowerCase().includes(lowerFilter)) ||
      (user.email && user.email.toLowerCase().includes(lowerFilter))
    );
  }, [users, userFilterText]);

  const sortedUsers = useMemo(() => {
    const { key, direction } = userSortConfig;
    return [...filteredUsers].sort((a, b) => {
      let aValue = a[key], bValue = b[key];
      if (key === 'createdAt') {
        aValue = aValue ? new Date(aValue) : new Date(0);
        bValue = bValue ? new Date(bValue) : new Date(0);
      } else if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredUsers, userSortConfig]);

  const paginatedUsers = useMemo(() => {
    const start = userPage * userRowsPerPage;
    return sortedUsers.slice(start, start + userRowsPerPage);
  }, [sortedUsers, userPage, userRowsPerPage]);

  const exportUsers = () => {
    alert('Export not implemented.');
  };

  return (
    <Box>
      <Typography variant="h5">User Accounts</Typography>
      <QuickActionBar onRefresh={fetchUsers} onExport={exportUsers} />
      <TextField
        label="Search Users"
        variant="outlined"
        fullWidth
        value={userFilterText}
        onChange={(e) => setUserFilterText(e.target.value)}
        sx={{ mb: 2 }}
      />
      {usersLoading ? (
        <Box textAlign="center" my={4}>
          <CircularProgress />
        </Box>
      ) : usersError ? (
        <Alert severity="error">{usersError}</Alert>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={userSortConfig.key === 'id'}
                      direction={userSortConfig.direction}
                      onClick={() => handleUserSort('id')}
                    >
                      User ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={userSortConfig.key === 'displayName'}
                      direction={userSortConfig.direction}
                      onClick={() => handleUserSort('displayName')}
                    >
                      Display Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={userSortConfig.key === 'email'}
                      direction={userSortConfig.direction}
                      onClick={() => handleUserSort('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={userSortConfig.key === 'createdAt'}
                      direction={userSortConfig.direction}
                      onClick={() => handleUserSort('createdAt')}
                    >
                      Created At
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Staff Approval</TableCell>
                  <TableCell>Subscribe Updates</TableCell>
                  <TableCell>Onboarding</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.displayName || 'N/A'}</TableCell>
                    <TableCell>{user.email || 'N/A'}</TableCell>
                    <TableCell>{user.createdAt ? new Date(user.createdAt).toLocaleString() : 'N/A'}</TableCell>
                    <TableCell>{user.staffApproval ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{user.subscribeUpdates ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <Chip
                        label={getOnboardingStatus(user).label}
                        color={getOnboardingStatus(user).color}
                        onClick={() => setOpenOnboardingUser(user)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => alert('User deletion not implemented.')} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {paginatedUsers.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} align="center">No users found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={sortedUsers.length}
            page={userPage}
            onPageChange={(_, newPage) => setUserPage(newPage)}
            rowsPerPage={userRowsPerPage}
            onRowsPerPageChange={(e) => { setUserRowsPerPage(parseInt(e.target.value, 10)); setUserPage(0); }}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
          <OnboardingDialog user={openOnboardingUser} open={Boolean(openOnboardingUser)} onClose={() => setOpenOnboardingUser(null)} />
        </>
      )}
    </Box>
  );
}
