// File: src/routes/protectedRoutes.js

import React, { lazy } from 'react';
import ProtectedRoute from '../components/ProtectedRoute';

// ----- ADMIN / SPECIAL PAGES -----
const CreateBlogPost = lazy(() => import('../pages/Resources/Blog/CreateBlogPost.jsx'));
const AdminCreateNotification = lazy(() => import('../pages/Users/AdminOnly/AdminCreateNotification.jsx'));

// ----- RCF UNITY DASHBOARD (Admin Only) -----
const RcfUnityDashboard = lazy(() => import('../pages/Users/AdminOnly/RcfUnityDashboard.js/RcfUnityDashboard.js'));

// ----- SETTINGS / ACCOUNT -----
const Settings = lazy(() => import('../pages/Users/Settings/Settings.jsx'));
const YourAccount = lazy(() =>
  import('/Users/willradley/orthinas-crm/src/pages/Users/Settings/YourAccount.jsx')
);

// ----- DASHBOARD / DOCS -----
const Dashboard = lazy(() => import('../pages/Users/Dashboard/Dashboard.js'));
const ArticlesDocumentation = lazy(() =>
  import('../pages/Resources/support/ArticlesDocumentation.js')
);

// ----- OBJECT TYPES + OBJECT ITEMS -----
const ObjectTypeForm = lazy(() => import('../pages/Users/Objects/ObjectTypeForm.jsx'));
const ObjectItemForm = lazy(() => import('../pages/Users/Objects/ObjectItemForm.jsx'));
const ListObjectsPage = lazy(() => import('../pages/Users/Objects/ListObjectsPage.jsx'));

// ----- PUBLIC FORM EDITOR (Protected) -----
const FormEditorPage = lazy(() => import('../pages/Users/Objects/FormEditorPage.jsx'));

// ----- REPORTS -----
const ReportForm = lazy(() => import('../pages/Users/Reports/ReportForm.jsx'));
const ListReportsPage = lazy(() => import('../pages/Users/Reports/ListReportsPage.jsx'));

// ----- ENGAGEHUB -----
const EngageHubForm = lazy(() => import('../pages/Users/EngageHub/EngageHubForm.jsx'));

// ----- ORTHINAS CONNECT (Communication Page) -----
// New: Orthinas Connect lets businesses and team members communicate in real time.
const OrthinasConnectPage = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Users/OrthinasConnect/OrthinasConnectPage.jsx'));

// ----- LEARNING PORTAL / LESSONS -----
const Learning = lazy(() => import('../pages/Users/Learning/Learning.js'));
const LessonDetail = lazy(() => import('../pages/Users/Learning/LessonDetail.js'));

// ----- CRM FEATURES (Private Access) -----
const CrmFeaturesList = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Users/AdminOnly/CrmFeaturesList.jsx'));
const CrmFeatureForm = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Users/AdminOnly/CrmFeatureForm.jsx'));

export const protectedRoutes = [
  // Admin
  {
    path: '/admin/notifications/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <AdminCreateNotification />
      </ProtectedRoute>
    ),
  },
  {
    path: '/blog/create', // If you prefer to keep it here instead of public
    element: (
      <ProtectedRoute>
        <CreateBlogPost />
      </ProtectedRoute>
    ),
  },
  // RCF Unity Dashboard (Admin Only)
  {
    path: '/admin/rcf-dashboard',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <RcfUnityDashboard />
      </ProtectedRoute>
    ),
  },

  // Settings / Account
  {
    path: '/settings',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <YourAccount />
      </ProtectedRoute>
    ),
  },

  // Dashboard, Documentation
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/articles',
    element: (
      <ProtectedRoute>
        <ArticlesDocumentation />
      </ProtectedRoute>
    ),
  },

  // Object Types & Items
  {
    path: '/objectTypes/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectTypeForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/objectTypes/edit/:objectTypeId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectTypeForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/create/:objectType',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectItemForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/edit/:objectType/:objectId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ObjectItemForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/list/:objectType',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ListObjectsPage />
      </ProtectedRoute>
    ),
  },

  // Public Form Editor
  {
    path: '/formEditor/:objectType',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <FormEditorPage />
      </ProtectedRoute>
    ),
  },

  // Reports
  {
    path: '/reports',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ListReportsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ReportForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports/edit/:reportId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <ReportForm />
      </ProtectedRoute>
    ),
  },

  // EngageHub
  {
    path: '/engagehub/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <EngageHubForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/engagehub/edit/:engageHubId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <EngageHubForm />
      </ProtectedRoute>
    ),
  },

  // Orthinas Connect (Communication Page)
  {
    path: '/orthinas-connect',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <OrthinasConnectPage />
      </ProtectedRoute>
    ),
  },

  // Learning Portal / Lessons
  {
    path: '/learning',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Learning />
      </ProtectedRoute>
    ),
  },
  {
    path: '/learning/course/:courseId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <Learning />
      </ProtectedRoute>
    ),
  },
  {
    path: '/learning/lesson/:lessonId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <LessonDetail />
      </ProtectedRoute>
    ),
  },

  // ----- CRM FEATURES (Private Access) -----
  {
    path: '/crmFeatures',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CrmFeaturesList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/crmFeatures/create',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CrmFeatureForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/crmFeatures/edit/:featureId',
    element: (
      <ProtectedRoute skipSubscriptionCheck skipOnboardingCheck>
        <CrmFeatureForm />
      </ProtectedRoute>
    ),
  },
];
