// File: src/pages/admin/MessagesTab.jsx
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  TextField,
  TableSortLabel,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TablePagination,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';

const QuickActionBar = ({ onRefresh, onExport }) => (
  <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1} mb={2}>
    <Button variant="outlined" startIcon={<RefreshIcon />} onClick={onRefresh} sx={{ mr: 1 }}>
      Refresh
    </Button>
    {onExport && (
      <Button variant="contained" color="primary" onClick={onExport}>
        Export
      </Button>
    )}
  </Box>
);

const MessageDialog = ({ mail, open, onClose }) => {
  if (!mail) return null;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{mail.subject || 'Mail Submission'}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: mail.message?.html || 'No message content' }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default function MessagesTab() {
  const [mails, setMails] = useState([]);
  const [mailLoading, setMailLoading] = useState(true);
  const [mailError, setMailError] = useState(null);
  const [mailFilterText, setMailFilterText] = useState('');
  const [mailSortConfig, setMailSortConfig] = useState({ key: 'message', direction: 'asc' });
  const [openMail, setOpenMail] = useState(null);
  const [mailPage, setMailPage] = useState(0);
  const [mailRowsPerPage, setMailRowsPerPage] = useState(10);

  const fetchMails = async () => {
    setMailLoading(true);
    try {
      const mailRef = collection(db, 'mail');
      const q = query(mailRef, orderBy('message', mailSortConfig.direction));
      const querySnapshot = await getDocs(q);
      const mailsData = querySnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
      setMails(mailsData);
      setMailError(null);
    } catch (err) {
      console.error(err);
      setMailError('Failed to load mail submissions.');
    } finally {
      setMailLoading(false);
    }
  };

  useEffect(() => {
    fetchMails();
  }, [mailSortConfig]);

  const handleMailSort = useCallback((key) => {
    setMailSortConfig(prev => {
      const direction = prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  }, []);

  const filteredMails = useMemo(() => {
    if (!mailFilterText) return mails;
    return mails.filter(mail =>
      (mail.subject || '').toLowerCase().includes(mailFilterText.toLowerCase()) ||
      (mail.to || '').toLowerCase().includes(mailFilterText.toLowerCase())
    );
  }, [mails, mailFilterText]);

  const paginatedMails = useMemo(() => {
    const start = mailPage * mailRowsPerPage;
    return filteredMails.slice(start, start + mailRowsPerPage);
  }, [filteredMails, mailPage, mailRowsPerPage]);

  const handleDeleteMail = async (id) => {
    try {
      await deleteDoc(doc(db, 'mail', id));
      setMails(prev => prev.filter(mail => mail.id !== id));
    } catch (err) {
      console.error(err);
      alert('Error deleting mail.');
    }
  };

  const exportMessages = () => {
    alert('Export functionality not implemented.');
  };

  return (
    <Box>
      <Typography variant="h5">Messages</Typography>
      <QuickActionBar onRefresh={fetchMails} onExport={exportMessages} />
      <TextField
        label="Search Messages"
        variant="outlined"
        fullWidth
        value={mailFilterText}
        onChange={(e) => setMailFilterText(e.target.value)}
        sx={{ mb: 2 }}
      />
      {mailLoading ? (
        <Box textAlign="center" my={4}>
          <CircularProgress />
        </Box>
      ) : mailError ? (
        <Alert severity="error">{mailError}</Alert>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={mailSortConfig.key === 'id'}
                      direction={mailSortConfig.direction}
                      onClick={() => handleMailSort('id')}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={mailSortConfig.key === 'subject'}
                      direction={mailSortConfig.direction}
                      onClick={() => handleMailSort('subject')}
                    >
                      Subject
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Attempts</TableCell>
                  <TableCell>Error</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedMails.map(mail => (
                  <TableRow key={mail.id}>
                    <TableCell>{mail.id}</TableCell>
                    <TableCell>{mail.subject || 'N/A'}</TableCell>
                    <TableCell>{mail.to || 'N/A'}</TableCell>
                    <TableCell>{mail.attempts ?? 'N/A'}</TableCell>
                    <TableCell>{mail.error ? mail.error.substring(0, 40) + '...' : 'None'}</TableCell>
                    <TableCell>
                      <Chip label="View Message" color="primary" onClick={() => setOpenMail(mail)} sx={{ cursor: 'pointer' }} />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteMail(mail.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {paginatedMails.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">No messages found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredMails.length}
            page={mailPage}
            onPageChange={(_, newPage) => setMailPage(newPage)}
            rowsPerPage={mailRowsPerPage}
            onRowsPerPageChange={(e) => { setMailRowsPerPage(parseInt(e.target.value, 10)); setMailPage(0); }}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
          <MessageDialog mail={openMail} open={Boolean(openMail)} onClose={() => setOpenMail(null)} />
        </>
      )}
    </Box>
  );
}
