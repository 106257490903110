import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Menu,
  Fade,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Paper,
  LinearProgress,
  Chip,
  Collapse,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExploreIcon from '@mui/icons-material/Explore';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '/Users/willradley/orthinas-crm/src/context/AuthContext.js';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';

// Styled expand icon button that rotates when expanded.
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// Styled header box for the menu.
const HeaderBox = styled(Box)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(3),
}));

// Styled container for the progress tracker.
const ProgressContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  position: 'relative',
}));

// Styled task card with hover effect and dynamic elevation.
const TaskCard = styled(Card)(({ theme, completed, expanded }) => ({
  boxShadow: expanded ? theme.shadows[6] : theme.shadows[3],
  borderRadius: theme.spacing(1.5),
  transition: 'transform 0.2s, box-shadow 0.2s',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: completed ? theme.palette.success.light : theme.palette.background.paper,
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

// Function to return an appropriate icon for each task.
const getTaskIcon = (taskId) => {
  switch (taskId) {
    case 'update-business':
      return (
        <Tooltip title="Business Profile">
          <BusinessIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
        </Tooltip>
      );
    case 'import-data':
      return (
        <Tooltip title="Import Data">
          <FileUploadIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
        </Tooltip>
      );
    case 'customise-dashboard':
      return (
        <Tooltip title="Customise Dashboard">
          <DashboardIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
        </Tooltip>
      );
    case 'take-tour':
      return (
        <Tooltip title="Take a Tour">
          <ExploreIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
        </Tooltip>
      );
    case 'invite-team':
      return (
        <Tooltip title="Invite Your Team">
          <GroupAddIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
        </Tooltip>
      );
    default:
      return null;
  }
};

export default function SelectedForYou({ anchorEl, open, onClose, userActivity }) {
  const navigate = useNavigate();
  const { currentUser, userProfile } = useContext(AuthContext);

  // State for tracking the current user's data count.
  const [dataCount, setDataCount] = useState(0);
  // State to track which task cards are expanded.
  const [expandedTasks, setExpandedTasks] = useState({});

  // Listen for changes in the objects collection filtered by currentUser.uid.
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const q = query(collection(db, 'objects'), where('userId', '==', currentUser.uid));
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          // snapshot.size gives the number of documents in the snapshot.
          setDataCount(snapshot.size);
        },
        (error) => {
          console.error('Error listening to objects:', error);
        }
      );
      return () => unsubscribe();
    }
  }, [currentUser]);

  // Define tasks for the Guidance Centre.
  const tasks = [
    {
      id: 'update-business',
      title: 'Complete Your Business Profile',
      completed: Boolean(
        userProfile?.businessName &&
          userProfile?.industry &&
          userProfile?.businessAddress &&
          userProfile?.website
      ),
      description:
        'Fill in your business details to receive personalized recommendations and unlock additional features.',
      link: '/settings?tab=business',
    },
    {
      id: 'import-data',
      title: 'Import Your Data',
      completed: dataCount >= 30,
      description: `You currently have ${dataCount} ${dataCount === 1 ? 'item' : 'items'} in your CRM, which is less than what most customers have. Import your data via CSV to quickly populate your dashboard and get the most out of your account.`,
      link: '/dashboard?view=Content',
    },
    {
      id: 'customise-dashboard',
      title: 'Customise Your Dashboard',
      completed: Boolean(userProfile?.dashboardCustomized),
      description:
        'Personalise your dashboard with widgets, colors, and layouts that suit your workflow. Create a space that inspires productivity.',
      link: '/settings?tab=dashboard',
    },
    {
      id: 'take-tour',
      title: 'Take a Product Tour',
      completed: Boolean(userProfile?.hasTakenTour),
      description:
        "Get a guided tour of our key features and discover how to maximize your CRM's potential.",
      link: '/tour',
    },
    {
      id: 'invite-team',
      title: 'Invite Your Team',
      completed: Boolean(userProfile?.teamInvited),
      description:
        'Collaborate with your team by inviting members to join your CRM. Teamwork makes the dream work!',
      link: '/invite',
    },
  ];

  // Calculate progress (percentage of tasks completed).
  const totalTasks = tasks.length;
  const completedCount = tasks.filter((task) => task.completed).length;
  const progressPercentage = (completedCount / totalTasks) * 100;

  const handleTaskClick = (link) => {
    onClose(); // Close the menu before navigating.
    navigate(link);
  };

  const handleToggleTask = (taskId) => {
    setExpandedTasks((prev) => ({ ...prev, [taskId]: !prev[taskId] }));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        component: Paper,
        sx: {
          width: 440,
          maxWidth: '95vw',
          p: 3,
          borderRadius: 2,
          boxShadow: 6,
          backgroundColor: 'background.paper',
        },
      }}
    >
      {/* Header */}
      <HeaderBox>
        <Typography variant="h6" sx={{ fontWeight: 700, color: 'primary.main' }}>
          Guidance Centre
        </Typography>
      </HeaderBox>

      {/* Progress Bar with Percentage Overlay */}
      <ProgressContainer>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Progress: {completedCount}/{totalTasks} tasks completed
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progressPercentage}
          sx={{ height: 8, borderRadius: 4 }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}
        >
          <Typography variant="caption" sx={{ color: 'white', fontWeight: 700 }}>
            {Math.round(progressPercentage)}%
          </Typography>
        </Box>
      </ProgressContainer>

      {/* Task Cards */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {tasks.map((task) => (
          <TaskCard key={task.id} completed={task.completed} expanded={expandedTasks[task.id]}>
            {/* Header row that toggles the collapse */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                px: 2,
                py: 2, // Symmetric vertical padding for centering.
                minHeight: 56,
              }}
              onClick={() => handleToggleTask(task.id)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                {getTaskIcon(task.id)}
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'text.primary' }}>
                  {task.title}
                </Typography>
              </Box>
              <ExpandMore
                expand={expandedTasks[task.id] ? 1 : 0}
                aria-expanded={expandedTasks[task.id] ? true : false}
                aria-label="show more"
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleTask(task.id);
                }}
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
            {/* Collapsible content */}
            <Collapse in={expandedTasks[task.id]} timeout="auto" unmountOnExit>
              <CardContent sx={{ pt: 1, pb: 1, px: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  {task.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', px: 2, pb: 2 }}>
                {task.completed && (
                  <Chip label="Completed" color="success" size="small" sx={{ mr: 1 }} />
                )}
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleTaskClick(task.link)}
                  sx={{ textTransform: 'none', px: 3 }}
                >
                  Go
                </Button>
              </CardActions>
            </Collapse>
          </TaskCard>
        ))}
      </Box>

      <Divider sx={{ mt: 3, mb: 2 }} />

      {/* Full width call-to-action button with gradient background and tooltip */}
      <Box sx={{ mb: 2 }}>
        <Tooltip title="Book your free expert consultation" arrow>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            href="https://crm.orthinas.com/schedule-meeting"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textTransform: 'none',
              py: 1.5,
              fontWeight: 600,
              boxShadow: 3,
              borderRadius: 2,
              background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
              transition: 'background 0.3s',
              '&:hover': {
                background: 'linear-gradient(45deg, #1E88E5, #1EC0F6)',
              },
            }}
          >
            Schedule a Call with an Expert
          </Button>
        </Tooltip>
      </Box>

      <Box sx={{ textAlign: 'right' }}>
        <Button variant="outlined" size="small" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Menu>
  );
}
