// File: src/pages/admin/NotificationsTab.jsx
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  TextField,
  TableSortLabel,
  TablePagination,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { collection, getDocs, query, orderBy, deleteDoc, doc, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';

function CreateNotificationDialog({ open, onClose, onCreated }) {
  const [message, setMessage] = useState('');
  const [targetUserId, setTargetUserId] = useState('');
  const [companyWide, setCompanyWide] = useState(false);
  const [segment, setSegment] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const notificationData = {
        message,
        timestamp: serverTimestamp(),
        read: false,
      };
      if (targetUserId.trim()) notificationData.userId = targetUserId.trim();
      if (companyWide) notificationData.companyWide = true;
      if (segment.trim()) notificationData.segment = segment.trim();

      await addDoc(collection(db, 'notifications'), notificationData);
      setMessage('');
      setTargetUserId('');
      setCompanyWide(false);
      setSegment('');
      onCreated && onCreated();
      onClose();
    } catch (err) {
      console.error(err);
      setError('Error creating notification. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create Notification</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          <TextField
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            required
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Target User ID (optional)"
            value={targetUserId}
            onChange={(e) => setTargetUserId(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <input
              type="checkbox"
              checked={companyWide}
              onChange={(e) => setCompanyWide(e.target.checked)}
              style={{ marginRight: '8px' }}
              id="companyWide"
            />
            <label htmlFor="companyWide">Company Wide</label>
          </Box>
          <TextField
            label="Segment (optional)"
            value={segment}
            onChange={(e) => setSegment(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          {error && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} sx={{ mr: 1 }} disabled={loading}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'Creating...' : 'Create'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default function NotificationsTab() {
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState(true);
  const [notificationsError, setNotificationsError] = useState(null);
  const [notificationFilterText, setNotificationFilterText] = useState('');
  const [notificationSortConfig, setNotificationSortConfig] = useState({ key: 'timestamp', direction: 'desc' });
  const [notificationPage, setNotificationPage] = useState(0);
  const [notificationRowsPerPage, setNotificationRowsPerPage] = useState(10);
  const [openCreateNotification, setOpenCreateNotification] = useState(false);

  const fetchNotifications = async () => {
    setNotificationsLoading(true);
    try {
      const notificationsRef = collection(db, 'notifications');
      const q = query(notificationsRef, orderBy('timestamp', notificationSortConfig.direction));
      const querySnapshot = await getDocs(q);
      const notificationsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNotifications(notificationsData);
      setNotificationsError(null);
    } catch (err) {
      console.error(err);
      setNotificationsError('Failed to load notifications.');
    } finally {
      setNotificationsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [notificationSortConfig]);

  const handleNotificationSort = useCallback((key) => {
    setNotificationSortConfig(prev => {
      const direction = prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc';
      return { key, direction };
    });
  }, []);

  const filteredNotifications = useMemo(() => {
    if (!notificationFilterText) return notifications;
    return notifications.filter(notif =>
      (notif.message || '').toLowerCase().includes(notificationFilterText.toLowerCase())
    );
  }, [notifications, notificationFilterText]);

  const paginatedNotifications = useMemo(() => {
    const start = notificationPage * notificationRowsPerPage;
    return filteredNotifications.slice(start, start + notificationRowsPerPage);
  }, [filteredNotifications, notificationPage, notificationRowsPerPage]);

  const handleDeleteNotification = async (id) => {
    try {
      await deleteDoc(doc(db, 'notifications', id));
      setNotifications(prev => prev.filter(notif => notif.id !== id));
    } catch (err) {
      console.error(err);
      alert('Error deleting notification.');
    }
  };

  const exportNotifications = () => {
    alert('Export not implemented.');
  };

  return (
    <Box>
      <Typography variant="h5">Notifications</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={2}>
        <Button variant="contained" color="primary" onClick={() => setOpenCreateNotification(true)}>
          New Notification
        </Button>
        <Box>
          <Button variant="outlined" startIcon={<RefreshIcon />} onClick={fetchNotifications} sx={{ mr: 1 }}>
            Refresh
          </Button>
          <Button variant="contained" color="primary" onClick={exportNotifications}>
            Export
          </Button>
        </Box>
      </Box>
      <TextField
        label="Search Notifications"
        variant="outlined"
        fullWidth
        value={notificationFilterText}
        onChange={(e) => setNotificationFilterText(e.target.value)}
        sx={{ mb: 2 }}
      />
      {notificationsLoading ? (
        <Box textAlign="center" my={4}>
          <CircularProgress />
        </Box>
      ) : notificationsError ? (
        <Alert severity="error">{notificationsError}</Alert>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={notificationSortConfig.key === 'id'}
                      direction={notificationSortConfig.direction}
                      onClick={() => handleNotificationSort('id')}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={notificationSortConfig.key === 'message'}
                      direction={notificationSortConfig.direction}
                      onClick={() => handleNotificationSort('message')}
                    >
                      Message
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={notificationSortConfig.key === 'timestamp'}
                      direction={notificationSortConfig.direction}
                      onClick={() => handleNotificationSort('timestamp')}
                    >
                      Timestamp
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Read</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedNotifications.map(notif => (
                  <TableRow key={notif.id}>
                    <TableCell>{notif.id}</TableCell>
                    <TableCell>{notif.message}</TableCell>
                    <TableCell>
                      {notif.userId ? `User: ${notif.userId}` : notif.companyWide ? 'Company-wide' : notif.segment ? `Segment: ${notif.segment}` : 'Public'}
                    </TableCell>
                    <TableCell>{notif.timestamp ? new Date(notif.timestamp.seconds * 1000).toLocaleString() : 'N/A'}</TableCell>
                    <TableCell>{notif.read ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteNotification(notif.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {paginatedNotifications.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">No notifications found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredNotifications.length}
            page={notificationPage}
            onPageChange={(_, newPage) => setNotificationPage(newPage)}
            rowsPerPage={notificationRowsPerPage}
            onRowsPerPageChange={(e) => { setNotificationRowsPerPage(parseInt(e.target.value, 10)); setNotificationPage(0); }}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </>
      )}
      <CreateNotificationDialog
        open={openCreateNotification}
        onClose={() => setOpenCreateNotification(false)}
        onCreated={fetchNotifications}
      />
    </Box>
  );
}
