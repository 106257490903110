// File: src/pages/admin/FeaturesTab.jsx
import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  TextField,
  TablePagination,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert as MuiAlert,
  Autocomplete,
  Chip,
  TableSortLabel,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  collection,
  getDocs,
  query,
  orderBy as fbOrderBy,
  deleteDoc,
  updateDoc,
  addDoc,
  doc,
  where,
} from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
// Import Firebase Storage functions
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

// Define the available plan options – limiting the choices to only Basic, Pro, or Enterprise.
const planOptions = ["Basic", "Pro", "Enterprise"];

/**
 * ExtraDetailsInput Component
 * Allows you to add and remove extra detail entries as objects.
 * Each extra detail now has a required title (maximum 25 characters) and required detail text.
 */
function ExtraDetailsInput({ value, onChange }) {
  const [detailTitle, setDetailTitle] = useState('');
  const [detailText, setDetailText] = useState('');

  // Compute validation flags
  const trimmedTitle = detailTitle.trim();
  const trimmedText = detailText.trim();
  const isTitleValid = trimmedTitle.length > 0 && trimmedTitle.length <= 25;
  const isTextValid = trimmedText.length > 0;
  const canAddDetail = isTitleValid && isTextValid;

  const handleAddDetail = () => {
    if (canAddDetail) {
      onChange([...value, { title: trimmedTitle, text: trimmedText }]);
      setDetailTitle('');
      setDetailText('');
    }
  };

  const handleDeleteDetail = (indexToRemove) => {
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  return (
    <Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
        <TextField
          label="Detail Title"
          variant="outlined"
          value={detailTitle}
          onChange={(e) => setDetailTitle(e.target.value)}
          fullWidth
          inputProps={{ maxLength: 25 }}
          error={!isTitleValid && detailTitle !== ""}
          helperText={
            !isTitleValid && detailTitle !== ""
              ? "Title is required and must be ≤25 characters"
              : ""
          }
        />
        <TextField
          label="Detail Text"
          variant="outlined"
          value={detailText}
          onChange={(e) => setDetailText(e.target.value)}
          fullWidth
        />
        <Button onClick={handleAddDetail} variant="contained" color="primary" disabled={!canAddDetail}>
          Add
        </Button>
      </Stack>
      <Box sx={{ mt: 1 }}>
        {value.map((detail, index) => (
          <Chip
            key={index}
            label={`${detail.title}: ${detail.text}`}
            onDelete={() => handleDeleteDetail(index)}
            sx={{ mr: 1, mt: 1 }}
          />
        ))}
      </Box>
    </Box>
  );
}

/**
 * FeatureDialog Component
 * Renders a dialog for creating or editing a feature.
 * This version uses a file upload for the icon, supports extra details as an array of objects,
 * and now includes a new text field for the toolLink.
 */
function FeatureDialog({ open, onClose, feature, onSaved }) {
  // Basic fields
  const [title, setTitle] = useState(feature ? feature.title : '');
  const [description, setDescription] = useState(feature ? feature.description : '');
  // iconUrl will store the download URL from Firebase Storage.
  const [iconUrl, setIconUrl] = useState(feature ? feature.iconUrl : '');
  const [availableInPlans, setAvailableInPlans] = useState(
    feature && feature.availableInPlans ? feature.availableInPlans : []
  );
  // extraDetails now stores an array of objects: { title, text }
  const [extraDetails, setExtraDetails] = useState(
    feature && feature.extraDetails ? feature.extraDetails : []
  );
  // NEW: toolLink field for the CTA URL.
  const [toolLink, setToolLink] = useState(feature ? feature.toolLink : '');
  const [modulesOptions, setModulesOptions] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Initialize Firebase Storage.
  const storage = getStorage();

  // Handle file selection and upload.
  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Create a unique file reference in Firebase Storage.
      const fileRef = storageRef(storage, `icons/${Date.now()}-${file.name}`);
      try {
        // Upload the file.
        const snapshot = await uploadBytes(fileRef, file);
        // Get the download URL.
        const downloadURL = await getDownloadURL(snapshot.ref);
        setIconUrl(downloadURL);
      } catch (uploadError) {
        console.error("Error uploading file: ", uploadError);
        setError("Failed to upload icon.");
      }
    }
  };

  // Fetch modules (from solutions) once on mount.
  useEffect(() => {
    async function fetchModules() {
      try {
        const q = query(collection(db, 'solutions'), where("type", "==", "product"));
        const snapshot = await getDocs(q);
        const modules = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setModulesOptions(modules);
      } catch (err) {
        console.error('Error fetching modules:', err);
      }
    }
    fetchModules();
  }, []);

  // Update form values when the feature prop changes.
  useEffect(() => {
    if (feature) {
      setTitle(feature.title);
      setDescription(feature.description);
      setIconUrl(feature.iconUrl);
      setAvailableInPlans(feature.availableInPlans ? feature.availableInPlans : []);
      setExtraDetails(feature.extraDetails ? feature.extraDetails : []);
      setToolLink(feature.toolLink ? feature.toolLink : '');
      if (feature.modules && modulesOptions.length > 0) {
        const preSelected = modulesOptions.filter(opt =>
          feature.modules.includes(opt.title)
        );
        setSelectedModules(preSelected);
      }
    } else {
      setTitle('');
      setDescription('');
      setIconUrl('');
      setAvailableInPlans([]);
      setExtraDetails([]);
      setToolLink('');
      setSelectedModules([]);
    }
  }, [feature, modulesOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    // Prepare the feature data including the new toolLink field.
    const featureData = {
      title,
      description,
      iconUrl,
      availableInPlans,
      extraDetails,
      modules: selectedModules.map(m => m.title),
      toolLink,  // New field for the tool link CTA
    };
    try {
      if (feature && feature.id) {
        await updateDoc(doc(db, 'crmFeatures', feature.id), featureData);
      } else {
        await addDoc(collection(db, 'crmFeatures'), featureData);
      }
      if (onSaved) onSaved();
      onClose();
    } catch (err) {
      console.error(err);
      setError('Failed to save feature.');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{feature ? 'Edit Feature' : 'Create Feature'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          <TextField
            label="Title"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            label="Description"
            fullWidth
            margin="normal"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            multiline
            rows={3}
          />
          {/* File upload for Icon */}
          <Box sx={{ my: 2 }}>
            <Button variant="outlined" component="label">
              Upload Icon
              <input type="file" accept="image/*" hidden onChange={handleFileChange} />
            </Button>
            {iconUrl && (
              <Box mt={1}>
                <img src={iconUrl} alt="Icon preview" style={{ maxHeight: 100 }} />
              </Box>
            )}
          </Box>
          {/* NEW: Tool Link input field */}
          <TextField
            label="Tool Link"
            fullWidth
            margin="normal"
            value={toolLink}
            onChange={(e) => setToolLink(e.target.value)}
            placeholder="Enter the URL for the tool (e.g., start building reports)"
            helperText="This link will be used as a CTA for users to start using the feature."
          />
          {/* Autocomplete for plan selection */}
          <Autocomplete
            multiple
            freeSolo={false}
            options={planOptions}
            value={availableInPlans}
            onChange={(event, newValue) => setAvailableInPlans(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Available In Plans"
                placeholder="Select plans"
                margin="normal"
                fullWidth
              />
            )}
          />
          {/* Autocomplete for Modules */}
          <Autocomplete
            multiple
            options={modulesOptions}
            getOptionLabel={(option) => option.title}
            value={selectedModules}
            onChange={(event, newValue) => setSelectedModules(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Modules"
                placeholder="Select modules"
                margin="normal"
                fullWidth
              />
            )}
          />
          {/* Extra Details Input */}
          <ExtraDetailsInput value={extraDetails} onChange={setExtraDetails} />
          <MuiAlert severity="info" sx={{ mt: 1 }}>
            Extra details will be saved as individual items.
          </MuiAlert>
          {error && <MuiAlert severity="error" sx={{ my: 2 }}>{error}</MuiAlert>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={onClose} sx={{ mr: 1 }} disabled={loading}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'Saving...' : feature ? 'Update Feature' : 'Create Feature'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default function FeaturesTab() {
  const [features, setFeatures] = useState([]);
  const [featuresLoading, setFeaturesLoading] = useState(true);
  const [featuresError, setFeaturesError] = useState(null);
  const [featureFilterText, setFeatureFilterText] = useState('');
  const [featurePage, setFeaturePage] = useState(0);
  const [featureRowsPerPage, setFeatureRowsPerPage] = useState(10);
  const [editingFeature, setEditingFeature] = useState(null);
  const [openFeatureDialog, setOpenFeatureDialog] = useState(false);

  // Sorting state.
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');

  // Column visibility state.
  const initialVisibleColumns = {
    id: true,
    title: true,
    modules: true,
    description: true,
    availableInPlans: true,
  };
  const [visibleColumns, setVisibleColumns] = useState(initialVisibleColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const openColumnsMenu = Boolean(anchorEl);

  const handleColumnsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColumnsClose = () => {
    setAnchorEl(null);
  };

  const handleToggleColumn = (columnId) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const fetchFeatures = async () => {
    setFeaturesLoading(true);
    try {
      const featuresRef = collection(db, 'crmFeatures');
      const q = query(featuresRef, fbOrderBy('title', 'asc'));
      const querySnapshot = await getDocs(q);
      const featuresData = querySnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
      setFeatures(featuresData);
      setFeaturesError(null);
    } catch (err) {
      console.error(err);
      setFeaturesError('Failed to load features.');
    } finally {
      setFeaturesLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  // Filter features based on search input.
  const filteredFeatures = useMemo(() => {
    if (!featureFilterText) return features;
    const lowerFilter = featureFilterText.toLowerCase();
    return features.filter(feature =>
      (feature.title && feature.title.toLowerCase().includes(lowerFilter)) ||
      (feature.description && feature.description.toLowerCase().includes(lowerFilter))
    );
  }, [features, featureFilterText]);

  // Sorting functions.
  const descendingComparator = (a, b, orderBy) => {
    let aValue = a[orderBy];
    let bValue = b[orderBy];
    // If the value is an array, join it into a string.
    if (Array.isArray(aValue)) aValue = aValue.join(', ');
    if (Array.isArray(bValue)) bValue = bValue.join(', ');
    if (bValue < aValue) return -1;
    if (bValue > aValue) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Apply sorting.
  const sortedFeatures = useMemo(() => {
    const comparator = getComparator(order, orderBy);
    return filteredFeatures.slice().sort(comparator);
  }, [filteredFeatures, order, orderBy]);

  const paginatedFeatures = useMemo(() => {
    const start = featurePage * featureRowsPerPage;
    return sortedFeatures.slice(start, start + featureRowsPerPage);
  }, [sortedFeatures, featurePage, featureRowsPerPage]);

  const handleDeleteFeature = async (id) => {
    try {
      await deleteDoc(doc(db, 'crmFeatures', id));
      setFeatures(prev => prev.filter(feature => feature.id !== id));
    } catch (err) {
      console.error(err);
      alert('Error deleting feature.');
    }
  };

  const exportFeatures = () => {
    alert('Export not implemented.');
  };

  // Define header cells.
  const headCells = [
    { id: 'id', label: 'ID' },
    { id: 'title', label: 'Title' },
    { id: 'modules', label: 'Modules' },
    { id: 'description', label: 'Description' },
    { id: 'availableInPlans', label: 'Plans' },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>Features</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => { setEditingFeature(null); setOpenFeatureDialog(true); }}
        >
          New Feature
        </Button>
        <Box>
          <Button variant="outlined" onClick={fetchFeatures} sx={{ mr: 1 }}>
            Refresh
          </Button>
          <Button variant="contained" color="primary" onClick={exportFeatures} sx={{ mr: 1 }}>
            Export
          </Button>
          {/* Columns visibility toggle */}
          <Button variant="outlined" onClick={handleColumnsClick}>
            Columns
          </Button>
          <Menu anchorEl={anchorEl} open={openColumnsMenu} onClose={handleColumnsClose}>
            {headCells.map(headCell => (
              <MenuItem key={headCell.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleColumns[headCell.id]}
                      onChange={() => handleToggleColumn(headCell.id)}
                    />
                  }
                  label={headCell.label}
                />
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <TextField
        label="Search Features"
        variant="outlined"
        fullWidth
        value={featureFilterText}
        onChange={(e) => setFeatureFilterText(e.target.value)}
        sx={{ mb: 2 }}
      />
      {featuresLoading ? (
        <Box textAlign="center" my={4}>
          <CircularProgress />
        </Box>
      ) : featuresError ? (
        <Alert severity="error">{featuresError}</Alert>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.filter(cell => visibleColumns[cell.id]).map(cell => (
                    <TableCell key={cell.id}>
                      <TableSortLabel
                        active={orderBy === cell.id}
                        direction={orderBy === cell.id ? order : 'asc'}
                        onClick={() => handleRequestSort(cell.id)}
                      >
                        {cell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedFeatures.map(feature => (
                  <TableRow key={feature.id}>
                    {visibleColumns.id && <TableCell>{feature.id}</TableCell>}
                    {visibleColumns.title && <TableCell>{feature.title}</TableCell>}
                    {visibleColumns.modules && (
                      <TableCell>
                        {feature.modules ? feature.modules.join(', ') : '-'}
                      </TableCell>
                    )}
                    {visibleColumns.description && (
                      <TableCell>
                        <Tooltip title={feature.description || ''}>
                          <span>
                            {feature.description && feature.description.length > 50
                              ? feature.description.substring(0, 50) + '...'
                              : feature.description}
                          </span>
                        </Tooltip>
                      </TableCell>
                    )}
                    {visibleColumns.availableInPlans && (
                      <TableCell>
                        {feature.availableInPlans ? feature.availableInPlans.join(', ') : '-'}
                      </TableCell>
                    )}
                    <TableCell>
                      <IconButton onClick={() => { setEditingFeature(feature); setOpenFeatureDialog(true); }} color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteFeature(feature.id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {paginatedFeatures.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={headCells.filter(cell => visibleColumns[cell.id]).length + 1} align="center">
                      No features found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={filteredFeatures.length}
            page={featurePage}
            onPageChange={(_, newPage) => setFeaturePage(newPage)}
            rowsPerPage={featureRowsPerPage}
            onRowsPerPageChange={(e) => { setFeatureRowsPerPage(parseInt(e.target.value, 10)); setFeaturePage(0); }}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
          <FeatureDialog
            open={openFeatureDialog}
            onClose={() => setOpenFeatureDialog(false)}
            feature={editingFeature}
            onSaved={fetchFeatures}
          />
        </>
      )}
    </Box>
  );
}
