// src/components/Navbar/ProfileMenu.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Grow,
  Avatar,
  Stack,
  LinearProgress,
  Chip,
  IconButton,
  Tooltip,
  Skeleton,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  Dashboard as DashboardIcon,
  BarChart as BarChartIcon,
  HelpOutline as HelpOutlineIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';

/** ------------------------------------------------------------------
 * A) Utilities and Configuration
 * ------------------------------------------------------------------*/

/** i18n simulation: a small helper to simulate translations. 
 *  In a real app, you'd use react-i18next or similar. */
const t = (key) => {
  const dict = {
    'user.role.user': 'User',
    'user.role.admin': 'Admin',
    'menu.home': 'Home',
    'menu.reports': 'Reports',
    'menu.helpSupport': 'Help & Support',
    'menu.settings': 'Settings',
    'menu.logout': 'Logout',
    'menu.copyEmail': 'Copy Email',
    'menu.copied': 'Copied!',
    'plan.trial': 'Trial',
    'plan.daysLeft': 'day(s) left',
  };
  return dict[key] || key;
};

/**
 * getRoleProps
 * Returns an object with { label, color } based on the user's role.
 */
function getRoleProps(role) {
  switch (role.toLowerCase()) {
    case 'admin':
      return {
        label: t('user.role.admin'),
        color: 'warning.main',
      };
    case 'user':
    default:
      return {
        label: t('user.role.user'),
        color: 'success.main',
      };
  }
}

/**
 * getPlanProgress
 *  - For a trial plan, calculates how many days are left
 *    and returns an object with { displayLabel, progressValue }.
 */
function getPlanProgress(trialDaysRemaining, trialLength = 14) {
  const daysUsed = trialLength - trialDaysRemaining;
  const progressValue = Math.round((daysUsed / trialLength) * 100);
  const label = `${t('plan.trial')} (${trialDaysRemaining} ${t('plan.daysLeft')})`;
  return { displayLabel: label, progressValue };
}

/**
 * Example config for your menu items.
 * You can easily add or remove items as needed.
 */
const MENU_ITEMS = [
  {
    icon: DashboardIcon,
    label: 'menu.home',
    to: '/dashboard',
  },
  {
    icon: BarChartIcon,
    label: 'menu.reports',
    to: '/reports',
  },
  {
    icon: HelpOutlineIcon,
    label: 'menu.helpSupport',
    to: '/support/help-center',
  },
  {
    icon: SettingsIcon,
    label: 'menu.settings',
    to: '/settings',
  },
];

/** ------------------------------------------------------------------
 * B) Styled Components
 * ------------------------------------------------------------------*/

// Example gradient header container for user info
const GradientHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  // A slight gradient for a subtle brand feel
  background: `linear-gradient(150deg, ${theme.palette.primary.light} 0%, ${theme.palette.background.paper} 100%)`,
}));

// A styled Avatar with fallback color
const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  marginRight: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
}));

/** ------------------------------------------------------------------
 * C) Sub-Components
 * ------------------------------------------------------------------*/

/**
 * UserHeader
 *  - Renders the top gradient area with:
 *    1) Avatar (with initials)
 *    2) Username & email
 *    3) Copy-to-clipboard icon for email
 *    4) User role label
 */
function UserHeader({
  userNameOrEmail,
  userEmail,
  userRole,
  copyTooltipOpen,
  onCopyEmail,
}) {
  // Helper to create avatar letters from name or email
  const getAvatarLetters = () => {
    if (!userNameOrEmail) return '?';
    return userNameOrEmail
      .split(' ')
      .map((part) => part[0]?.toUpperCase())
      .join('')
      .slice(0, 2);
  };

  const { label: roleLabel, color: roleColor } = getRoleProps(userRole);

  return (
    <GradientHeader>
      <ProfileAvatar alt={userNameOrEmail}>
        {getAvatarLetters()}
      </ProfileAvatar>
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', lineHeight: 1.2 }}>
          {userNameOrEmail}
        </Typography>

        {userEmail && (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography
              variant="caption"
              color="text.secondary"
              noWrap
              sx={{ maxWidth: 140, textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {userEmail}
            </Typography>

            {/* Copy Email Icon */}
            <Tooltip
              title={t('menu.copied')}
              placement="top"
              open={copyTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <IconButton
                size="small"
                onClick={onCopyEmail}
                sx={{ ml: 0.5 }}
                aria-label={t('menu.copyEmail')}
              >
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}

        {roleLabel && (
          <Typography variant="caption" sx={{ display: 'block', mt: 0.5, color: roleColor }}>
            {roleLabel}
          </Typography>
        )}
      </Box>
    </GradientHeader>
  );
}

/**
 * PlanInfo
 *  - Shows a skeleton (if plan info is loading),
 *    a trial progress bar (if on Trial),
 *    or simply a plan label for paid plans.
 */
function PlanInfo({ planLoading, currentPlan, trialDaysRemaining }) {
  if (planLoading) {
    return (
      <Skeleton
        variant="text"
        width={80}
        height={20}
        sx={{ mt: 1, bgcolor: 'grey.300' }}
      />
    );
  }

  // If plan is not found or no plan assigned, show nothing
  if (!currentPlan) return null;

  // If user is on a Trial plan
  if (currentPlan.toLowerCase() === 'trial' && trialDaysRemaining !== null) {
    const { displayLabel, progressValue } = getPlanProgress(trialDaysRemaining, 14);

    return (
      <Box sx={{ mt: 1 }}>
        <Chip label={displayLabel} size="small" color="primary" sx={{ fontWeight: 'bold' }} />
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{ mt: 1, height: 6, borderRadius: 1 }}
        />
      </Box>
    );
  }

  // Otherwise, show the plan name (e.g., "Starter", "Pro", "Enterprise")
  return (
    <Typography
      variant="caption"
      color="primary"
      sx={{ display: 'block', mt: 1, fontWeight: 'bold' }}
    >
      {currentPlan}
    </Typography>
  );
}

/**
 * MenuLinkItem
 *  - Renders a MenuItem with:
 *    1) An icon
 *    2) A localized label
 *    3) A Link to the specified route
 */
function MenuLinkItem({ icon: IconComponent, label, to, onClose }) {
  return (
    <MenuItem component={Link} to={to} onClick={onClose}>
      {IconComponent && <IconComponent fontSize="small" sx={{ mr: 1 }} />}
      {t(label)}
    </MenuItem>
  );
}

/**
 * LogoutItem
 *  - Renders the logout MenuItem with red text indicating a destructive action.
 */
function LogoutItem({ onLogout }) {
  return (
    <MenuItem onClick={onLogout} sx={{ color: 'error.main' }}>
      <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
      {t('menu.logout')}
    </MenuItem>
  );
}

/** ------------------------------------------------------------------
 * D) Main ProfileMenu Component
 * ------------------------------------------------------------------*/
/**
 * ProfileMenu
 *  - Displays user information (avatar, email, role) and plan status.
 *  - Provides navigation items (home, reports, etc.) and a logout option.
 *  - Showcases RCF Orthinas’s approach to user management, reflecting
 *    your AI-forward brand with future extensibility for more advanced
 *    plan handling or roles.
 */
export default function ProfileMenu({
  anchorEl,
  open,
  onClose,
  userNameOrEmail,
  userEmail,
  currentPlan,
  planLoading,
  trialDaysRemaining,
  userRole = 'User',
  handleSignOut,
}) {
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);

  // Copy user's email to clipboard, with a small tooltip to confirm
  const onCopyEmail = async () => {
    if (!userEmail) return;
    try {
      await navigator.clipboard.writeText(userEmail);
      setCopyTooltipOpen(true);
      setTimeout(() => setCopyTooltipOpen(false), 1500);
    } catch (err) {
      console.error('Failed to copy email:', err);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Grow}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{
        '& .MuiMenu-paper': {
          width: 280,
          p: 0,
          borderRadius: 2,
          overflow: 'hidden',
        },
      }}
      aria-label="Profile menu"
    >
      {/* A) User Header (avatar, name, email copy, role) */}
      <UserHeader
        userNameOrEmail={userNameOrEmail}
        userEmail={userEmail}
        userRole={userRole}
        copyTooltipOpen={copyTooltipOpen}
        onCopyEmail={onCopyEmail}
      />

      {/* B) Plan Info (Trial progress or plan name) */}
      <Box sx={{ px: 2, pt: 1, pb: 2 }}>
        <PlanInfo
          planLoading={planLoading}
          currentPlan={currentPlan}
          trialDaysRemaining={trialDaysRemaining}
        />
      </Box>

      <Divider />

      {/* C) Menu Items (from config) */}
      {MENU_ITEMS.map(({ icon, label, to }) => (
        <MenuLinkItem
          key={label}
          icon={icon}
          label={label}
          to={to}
          onClose={onClose}
        />
      ))}

      <Divider />

      {/* D) Logout */}
      <LogoutItem onLogout={handleSignOut} />
    </Menu>
  );
}
