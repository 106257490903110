// File: src/components/Navbar/MobileLoggedInView.jsx

import React from 'react';
import { Stack, Box, Tooltip, IconButton } from '@mui/material';
import { Dashboard as DashboardIcon, Menu as MenuIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import NavbarSearchBar from '/Users/willradley/orthinas-crm/src/components/Navbar/NavbarSearchBar.jsx';

function IconTooltipButton({ title, label, IconComponent, onClick, sx, anchor }) {
  return (
    <Tooltip title={title} arrow>
      <IconButton onClick={onClick} sx={{ ...sx, color: 'white' }} aria-label={label} aria-haspopup="true" aria-expanded={Boolean(anchor)}>
        <IconComponent />
      </IconButton>
    </Tooltip>
  );
}

export default function MobileLoggedInView({ iconButtonStyles, mobileAnchorEl, handleMobileMenuOpen, userId }) {
  const navigate = useNavigate();

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ px: 1 }}>
      {userId && <NavbarSearchBar userId={userId} mobile />} {/* This will render the collapsed search icon */}
      <IconTooltipButton
        title="Dashboard"
        label="Dashboard"
        IconComponent={DashboardIcon}
        onClick={() => navigate('/dashboard')}
        sx={iconButtonStyles}
      />
      <IconTooltipButton
        title="Module Dashboards"
        label="Module Dashboards"
        IconComponent={MenuIcon}
        onClick={handleMobileMenuOpen}
        sx={iconButtonStyles}
        anchor={mobileAnchorEl}
      />
    </Stack>
  );
}
