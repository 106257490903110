// File: src/context/AuthContext.js
import React, { createContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { CircularProgress } from '@mui/material';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const unsubAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setAuthLoading(false);
      setUserProfile(null); // clear any previous user profile

      if (user) {
        setProfileLoading(true);
        const docRef = doc(db, 'users', user.uid);
        const unsubDoc = onSnapshot(
          docRef,
          (snap) => {
            if (snap.exists()) {
              setUserProfile(snap.data());
            } else {
              setUserProfile(null);
            }
            setProfileLoading(false);
          },
          (err) => {
            console.error("Error fetching user doc:", err);
            setError('Error loading user profile');
            setProfileLoading(false);
          }
        );
        // Clean up the Firestore listener on unmount or when user changes
        return () => unsubDoc();
      } else {
        setUserProfile(null);
      }
    });

    return () => unsubAuth();
  }, []);

  const overallLoading = authLoading || profileLoading;

  if (overallLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <CircularProgress aria-label="Loading user/auth data" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ color: 'red', textAlign: 'center', marginTop: '2rem' }}>
        {error}
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser, userProfile }}>
      {children}
    </AuthContext.Provider>
  );
};
