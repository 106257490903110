// File: src/components/LoggingOutPage.jsx
import React, { useEffect, useState } from 'react';
import { Container, Paper, Box, Typography, CircularProgress } from '@mui/material';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '/Users/willradley/orthinas-crm/src/firebase.js';

const securityTips = [
  "Remember: Use strong, unique passwords for each account.",
  "Tip: Enable two-factor authentication for extra security.",
  "Keep your account safe: Log out when using public devices.",
  "Regularly update your software to protect against vulnerabilities.",
  "Monitor your account activity for any suspicious behavior."
];

export default function LoggingOutPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  // Pick a random tip when the component mounts.
  const randomTip = securityTips[Math.floor(Math.random() * securityTips.length)];

  useEffect(() => {
    const performLogout = async () => {
      try {
        await signOut(auth);
        // Wait 2 seconds so the user can read the tip.
        setTimeout(() => {
          navigate('/signin', { replace: true });
        }, 2000);
      } catch (err) {
        console.error("Logout error:", err);
        setError("Failed to log out. Please try again.");
      }
    };

    performLogout();
  }, [navigate]);

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Logging You Out...
        </Typography>
        {error ? (
          <Typography variant="body1" color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        ) : (
          <>
            <CircularProgress sx={{ mb: 2 }} />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              {randomTip}
            </Typography>
          </>
        )}
      </Paper>
    </Container>
  );
}
