// File: src/components/Navbar/DesktopLoggedOutRightItems.jsx

import React, { useState } from 'react';
import { Tooltip, IconButton, Button, Stack, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
// NEW: Import SearchIcon
import { HelpOutline as HelpOutlineIcon, Search as SearchIcon } from '@mui/icons-material';

import BigSalesMegaMenu from '/Users/willradley/orthinas-crm/src/components/layout/Components/Navbar/BigSalesMegaMenu.jsx'; // Import the Sales mega menu

// -----------------------------------------------------------------------------
// Define a UK flag icon component using the provided Firebase Storage URL.
function UKFlagIcon(props) {
  return (
    <img
      src="https://firebasestorage.googleapis.com/v0/b/finli-efed8.firebasestorage.app/o/united-kingdom.png?alt=media&token=aa6503e9-8d12-448c-871f-96d3cdfdedaa"
      alt="UK Flag"
      style={{ width: 24, height: 24, display: 'block' }}
      {...props}
    />
  );
}

// -----------------------------------------------------------------------------
// Renders an IconButton wrapped in a Tooltip with a subtle scale animation.
function IconTooltipButton({
  tooltip,
  ariaLabel,
  onClick,
  icon: IconComponent,
  anchorEl,
  sx,
}) {
  return (
    <Tooltip title={tooltip} arrow>
      <IconButton
        onClick={onClick}
        sx={{
          ...sx,
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        aria-label={ariaLabel}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
      >
        <IconComponent />
      </IconButton>
    </Tooltip>
  );
}

// -----------------------------------------------------------------------------
// Renders an outlined Button link wrapped in a Tooltip.
function OutlinedButtonLink({
  tooltip,
  ariaLabel,
  to,
  label,
  buttonStyles,
  removeBorder,
  onClick,
}) {
  return (
    <Tooltip title={tooltip} arrow>
      <Button
        {...(to ? { component: Link, to } : { onClick })}
        variant="outlined"
        sx={{
          ...buttonStyles,
          textTransform: 'none',
          fontWeight: 'bold',
          backgroundColor: 'transparent',
          border: removeBorder ? 'none' : undefined,
          borderColor: removeBorder ? 'transparent' : 'rgba(255,255,255,0.7)',
          transition: 'background-color 0.3s, border-color 0.3s, transform 0.3s',
          '&:hover': {
            border: removeBorder ? 'none' : undefined,
            borderColor: removeBorder ? 'transparent' : '#fff',
            backgroundColor: 'rgba(255,255,255,0.2)',
            transform: 'scale(1.05)',
          },
        }}
        aria-label={ariaLabel}
      >
        {label}
      </Button>
    </Tooltip>
  );
}

// -----------------------------------------------------------------------------
// Main Component
export default function DesktopLoggedOutRightItems({
  buttonStyles,
  iconButtonStyles,
  handleHelpMenuOpen,
  helpAnchorEl,
}) {
  // State for the Sales mega menu anchor.
  const [salesAnchorEl, setSalesAnchorEl] = useState(null);
  const handleSalesMenuOpen = (event) => setSalesAnchorEl(event.currentTarget);
  const handleSalesMenuClose = () => setSalesAnchorEl(null);

  // Configuration for the authentication buttons.
  const authButtons = [
    {
      tooltip: 'Sign in to your account',
      ariaLabel: 'Sign in',
      to: '/signin',
      label: 'Sign In',
    },
    {
      tooltip: 'Create a new account',
      ariaLabel: 'Sign up',
      to: '/signup',
      label: 'Sign Up',
    },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* "Contact Sales" Button that opens the Sales mega menu */}
      <OutlinedButtonLink
        tooltip="Explore our sales opportunities"
        ariaLabel="Sales Opportunities"
        onClick={handleSalesMenuOpen}
        label="Contact Sales"
        buttonStyles={buttonStyles}
        removeBorder={true}
      />

      {/* UK Flag Icon linking to /company/ukBuilt */}
      <Link to="/company/ukBuilt">
        <Tooltip title="Built in Britain" arrow>
          <IconButton
            sx={{
              transition: 'transform 0.3s',
              '&:hover': { transform: 'scale(1.1)' },
            }}
            aria-label="Built in Britain"
          >
            <UKFlagIcon />
          </IconButton>
        </Tooltip>
      </Link>

      {/* Sales Mega Menu (positioned relative to the "Contact Sales" button) */}
      <BigSalesMegaMenu
        anchorEl={salesAnchorEl}
        open={Boolean(salesAnchorEl)}
        onClose={handleSalesMenuClose}
      />

      {/* Vertical divider for clarity */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: 'rgba(255,255,255,0.3)' }}
      />

      {/* NEW: Search Icon that goes to /search */}
      <Tooltip title="Search Solutions & Resources" arrow>
        <IconButton
          component={Link}
          to="/search"
          sx={{
            color: 'inherit',
            transition: 'transform 0.3s',
            '&:hover': { transform: 'scale(1.1)' },
          }}
          aria-label="Search"
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>

      {/* Render Sign In/Sign Up buttons */}
      {authButtons.map(({ tooltip, ariaLabel, to, label }) => (
        <OutlinedButtonLink
          key={label}
          tooltip={tooltip}
          ariaLabel={ariaLabel}
          to={to}
          label={label}
          buttonStyles={buttonStyles}
        />
      ))}
    </Stack>
  );
}
