// File: src/components/AdminAddHelpArticlePage.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  MenuItem,
  Button,
  Alert,
  CircularProgress,
} from '@mui/material';
import { collection, addDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';

// (A) IMPORT your Markdown editor
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css'; // Editor styling (important!)

const CATEGORY_OPTIONS = [
  'help-docs',
  'communities',
  'customer-success',
];

export default function AdminAddHelpArticlePage() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  // (B) content is now Markdown text:
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  // NEW: Related page state – an optional identifier for the page where the article should appear
  const [relatedPage, setRelatedPage] = useState('');
  // NEW: Linked feature state – the feature id from the crmFeatures collection
  const [linkedFeature, setLinkedFeature] = useState('');
  // NEW: Features list from Firestore
  const [features, setFeatures] = useState([]);
  const [featuresLoading, setFeaturesLoading] = useState(false);

  // UI states
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  // Fetch the list of features from the crmFeatures collection on mount.
  useEffect(() => {
    const fetchFeatures = async () => {
      setFeaturesLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'crmFeatures'));
        const featuresData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFeatures(featuresData);
      } catch (err) {
        console.error('Error fetching features:', err);
      } finally {
        setFeaturesLoading(false);
      }
    };
    fetchFeatures();
  }, []);

  // (C) Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!title.trim() || !content.trim()) {
      setErrorMsg('Title and content are required.');
      return;
    }
    try {
      setLoading(true);
      setErrorMsg('');
      setSuccessMsg('');

      // Save to Firestore, including the new linkedFeature field
      await addDoc(collection(db, 'helpArticles'), {
        title: title.trim(),
        description: description.trim() || '',
        // store the *raw Markdown* string
        content: content.trim(),
        category: category || '',
        relatedPage: relatedPage.trim() || '',
        // Save the linked feature id (or an empty string if not set)
        linkedFeature: linkedFeature || '',
        createdAt: serverTimestamp(),
      });

      // Reset form
      setTitle('');
      setDescription('');
      setContent('');
      setCategory('');
      setRelatedPage('');
      setLinkedFeature('');
      setSuccessMsg('Article created successfully!');
    } catch (err) {
      console.error('Error creating help article:', err);
      setErrorMsg('Failed to create article. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper sx={{ p: 3 }} elevation={3}>
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Create a New Help Article
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={3}>
          Enter article details and click "Save Article".
        </Typography>

        {/* Alerts */}
        {errorMsg && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMsg}
          </Alert>
        )}
        {successMsg && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMsg}
          </Alert>
        )}

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {/* Title */}
          <TextField
            label="Title"
            variant="outlined"
            required
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          {/* Description */}
          <TextField
            label="Short Description (optional)"
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          {/* Category */}
          <TextField
            select
            label="Category (optional)"
            fullWidth
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            helperText="Select a category or leave blank"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {CATEGORY_OPTIONS.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </TextField>

          {/* Related Page */}
          <TextField
            label="Related Page (optional)"
            variant="outlined"
            fullWidth
            value={relatedPage}
            onChange={(e) => setRelatedPage(e.target.value)}
            helperText="Enter the page identifier (e.g., 'onboarding') where this article is relevant."
          />

          {/* NEW: Linked Feature */}
          {featuresLoading ? (
            <CircularProgress size={24} />
          ) : (
            <TextField
              select
              label="Linked Feature (optional)"
              variant="outlined"
              fullWidth
              value={linkedFeature}
              onChange={(e) => setLinkedFeature(e.target.value)}
              helperText="Select a feature to link this article to"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {features.map((feature) => (
                <MenuItem key={feature.id} value={feature.id}>
                  {feature.title}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* Content => Markdown Editor */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Content (Markdown)
            </Typography>
            <SimpleMDE
              value={content}
              onChange={(value) => setContent(value)}
              options={{
                spellChecker: false,
                maxHeight: '300px',
                placeholder: 'Write your article in Markdown...',
              }}
            />
          </Box>

          {/* Submit */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ alignSelf: 'flex-start' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Save Article'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
