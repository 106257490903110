// File: src/components/Navbar/BigSalesMegaMenu.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Menu,
  Fade,
  Grid,
  Typography,
  Button,
  Divider,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function BigSalesMegaMenu({ anchorEl, open, onClose }) {
  const [salesItems, setSalesItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch sales items from Firestore ("contactSales" collection)
  useEffect(() => {
    async function fetchSalesItems() {
      setLoading(true);
      setError(null);
      try {
        const snap = await getDocs(collection(db, 'contactSales'));
        const loaded = [];
        snap.forEach(doc => {
          loaded.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setSalesItems(loaded);
      } catch (err) {
        console.error('Error fetching sales items:', err);
        setError('Unable to load sales resources. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
    if (open) {
      fetchSalesItems();
    }
  }, [open]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: '800px',
          maxWidth: '95vw',
          p: 2,
          borderRadius: 2,
          boxShadow: 8,
          maxHeight: '80vh',
          overflowY: 'auto',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Contact Sales
        </Typography>

        {loading && (
          <Box textAlign="center" py={2}>
            <CircularProgress />
          </Box>
        )}

        {error && <Alert severity="error">{error}</Alert>}

        {!loading && !error && (
          <>
            {salesItems.length > 0 ? (
              <Grid container spacing={2} alignItems="stretch">
                {salesItems.map((item) => (
                  <Grid item xs={12} sm={6} md={6} key={item.id}>
                    <Box
                      component={Link}
                      to={item.link || '/schedule-meeting'}
                      onClick={onClose}
                      sx={{
                        p: 2,
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        textDecoration: 'none',
                        color: 'text.primary',
                        transition: 'box-shadow 0.3s, background-color 0.3s',
                        '&:hover': {
                          boxShadow: 4,
                          backgroundColor: 'action.hover',
                        },
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {item.title}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                No sales opportunities available at the moment.
              </Typography>
            )}

            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                component={Link}
                to="/schedule-meeting"
                endIcon={<ArrowForwardIcon />}
                onClick={onClose}
                aria-label="Schedule a Meeting"
              >
                Schedule a Meeting
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Menu>
  );
}
