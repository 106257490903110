import React, { createContext, useState, useEffect, useMemo } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { deepmerge } from '@mui/utils';

// Create our ThemeContext to provide toggling functions
export const ThemeContext = createContext();

/**
 * Checks if localStorage is available.
 * Helps avoid errors in SSR or restricted environments.
 */
const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
};

/**
 * Returns a simple contrast text color based on approximate luminance.
 * (You may later replace this with a more robust WCAG-based solution.)
 */
function getContrastText(bgColor, lightText = '#ffffff', darkText = '#000000') {
  const color = bgColor.replace('#', '');
  const r = parseInt(color.substr(0, 2), 16) / 255;
  const g = parseInt(color.substr(2, 2), 16) / 255;
  const b = parseInt(color.substr(4, 2), 16) / 255;
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 0.5 ? darkText : lightText;
}

const ThemeProviderComponent = ({ children }) => {
  const storageAvailable = isLocalStorageAvailable();

  // Detect system preference for dark mode
  const prefersDark = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches;

  // Load saved preferences (if available) or fallback to system defaults
  const savedTheme = storageAvailable
    ? localStorage.getItem('appTheme') || (prefersDark ? 'dark' : 'light')
    : (prefersDark ? 'dark' : 'light');

  const savedLargeText = storageAvailable
    ? localStorage.getItem('largeTextEnabled') === 'true'
    : false;

  const [mode, setMode] = useState(savedTheme); // 'light' or 'dark'
  const [largeTextEnabled, setLargeTextEnabled] = useState(savedLargeText);
  const [locale, setLocale] = useState('en-US');

  // Listen for changes to the system color scheme
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleSystemChange = (e) => {
      // Only update if the user hasn't explicitly set a theme
      if (!storageAvailable || !localStorage.getItem('appTheme')) {
        setMode(e.matches ? 'dark' : 'light');
      }
    };

    mediaQuery.addEventListener('change', handleSystemChange);
    return () => mediaQuery.removeEventListener('change', handleSystemChange);
  }, [storageAvailable]);

  // Persist theme and text size preferences to localStorage
  useEffect(() => {
    if (storageAvailable) {
      localStorage.setItem('appTheme', mode);
      localStorage.setItem('largeTextEnabled', largeTextEnabled);
    }
  }, [mode, largeTextEnabled, storageAvailable]);

  // Toggle handlers for theme and text size
  const toggleTheme = () => setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
  const toggleLargeText = () => setLargeTextEnabled((prev) => !prev);

  // Define custom palette colors
  const primaryColor = '#0052cc';
  const secondaryColor = '#009688';
  const successColor = '#28a745';
  const warningColor = '#ff9800';
  const errorColor   = '#f44336';
  const infoColor    = '#2196f3';

  // Extended greys for more nuance in your design
  const greyColor = {
    50:  '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  };

  // Optionally force the main background to white (good for CRMs)
  const forceWhiteBackground = true;

  // Base font size based on user preference for larger text
  const baseFontSize = largeTextEnabled ? 18 : 14;

  // Build the theme using useMemo so it's only recalculated when dependencies change
  const theme = useMemo(() => {
    let newTheme = createTheme({
      palette: {
        mode,
        primary: {
          main: primaryColor,
          contrastText: getContrastText(primaryColor),
        },
        secondary: {
          main: secondaryColor,
          contrastText: getContrastText(secondaryColor),
        },
        success: {
          main: successColor,
          contrastText: getContrastText(successColor),
        },
        warning: {
          main: warningColor,
          contrastText: getContrastText(warningColor),
        },
        error: {
          main: errorColor,
          contrastText: getContrastText(errorColor),
        },
        info: {
          main: infoColor,
          contrastText: getContrastText(infoColor),
        },
        grey: greyColor,
        ...(forceWhiteBackground
          ? {
              background: {
                default: '#ffffff',
                paper: '#f9f9f9',
              },
              text: {
                primary: '#333333',
                secondary: '#555555',
              },
            }
          : {
              background: {
                default: mode === 'dark' ? '#121212' : '#fafafa',
                paper: mode === 'dark' ? '#1f1f1f' : '#ffffff',
              },
              text: {
                primary: mode === 'dark' ? '#ffffff' : '#333333',
                secondary: mode === 'dark' ? '#cccccc' : '#555555',
              },
            }
        ),
      },
      shape: {
        borderRadius: 10,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
      typography: {
        fontSize: baseFontSize,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: { fontWeight: 700 },
        h2: { fontWeight: 700 },
        h3: { fontWeight: 600 },
        h4: { fontWeight: 600 },
        button: {
          textTransform: 'none',
          fontWeight: 500,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              scrollBehavior: 'smooth',
            },
            body: {
              transition: 'background-color 0.3s ease, color 0.3s ease',
              ...(forceWhiteBackground && {
                backgroundColor: '#ffffff',
              }),
            },
            a: {
              textDecoration: 'none',
              color: primaryColor,
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 8,
              transition: 'background-color 0.3s, color 0.3s',
              '@media (max-width:600px)': {
                padding: '8px 16px',
                fontSize: '0.875rem',
              },
            },
            containedPrimary: {
              '&:hover': {
                backgroundColor: '#003da5',
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: 12,
              ...(forceWhiteBackground && {
                backgroundColor: '#ffffff',
              }),
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              marginTop: 8,
              marginBottom: 8,
              '@media (max-width:600px)': {
                marginTop: 4,
                marginBottom: 4,
              },
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              '@media (max-width:600px)': {
                width: '75vw',
              },
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              '@media (max-width:600px)': {
                height: 56,
              },
              transition: 'height 0.3s ease',
            },
          },
        },
      },
      // Custom properties (can be accessed in components via theme.custom)
      custom: {
        locale,
        dateFormat: 'MM/dd/yyyy',
        timeFormat: 'HH:mm',
      },
    });

    // Make typography responsive with a defined scaling factor
    newTheme = responsiveFontSizes(newTheme, { factor: 2.0 });

    // Adjust heading and body font sizes on smaller screens via deepmerge
    newTheme = deepmerge(newTheme, {
      typography: {
        h1: {
          [newTheme.breakpoints.down('sm')]: { fontSize: '2rem' },
        },
        h2: {
          [newTheme.breakpoints.down('sm')]: { fontSize: '1.75rem' },
        },
        h3: {
          [newTheme.breakpoints.down('sm')]: { fontSize: '1.5rem' },
        },
        body1: {
          [newTheme.breakpoints.down('sm')]: { fontSize: `${baseFontSize - 2}px` },
        },
      },
    });

    return newTheme;
  }, [
    mode,
    largeTextEnabled,
    baseFontSize,
    primaryColor,
    secondaryColor,
    successColor,
    warningColor,
    errorColor,
    infoColor,
    greyColor,
    forceWhiteBackground,
    locale,
  ]);

  return (
    <ThemeContext.Provider
      value={{
        mode,
        toggleTheme,
        largeTextEnabled,
        toggleLargeText,
        locale,
        setLocale,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderComponent;
