// File: src/components/Navbar/BigCompanyMegaMenu.jsx

import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Menu,
  Fade,
  Typography,
  Grow,
  Divider,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Button,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  TextField,
  InputAdornment,
  Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js'; // Adjust path as needed

// Icons (feel free to choose your preferred icons)
import InfoIcon from '@mui/icons-material/Info';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PublicIcon from '@mui/icons-material/Public';
import WorkIcon from '@mui/icons-material/Work';
import ArticleIcon from '@mui/icons-material/Article';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';

/* ------------------------------------------------------------------
   1) Custom Hook: useDebounce
   ------------------------------------------------------------------ */
function useDebounce(value, delay = 300) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

/* ------------------------------------------------------------------
   2) Configuration & Helpers
   ------------------------------------------------------------------ */

/**
 * Tabs for the "Company" mega menu—each should match a Firestore doc "type".
 * An additional "All" tab shows all items.
 */
const COMPANY_TABS = [
  { label: 'All',     value: 'all',     icon: <EmojiObjectsIcon /> },
  { label: 'About',   value: 'about',   icon: <InfoIcon /> },
  { label: 'Values',  value: 'values',  icon: <FavoriteIcon /> },
  { label: 'Impact',  value: 'impact',  icon: <PublicIcon /> },
  { label: 'Careers', value: 'careers', icon: <WorkIcon /> },
  { label: 'News',    value: 'news',    icon: <ArticleIcon /> },
];

/**
 * Filter documents by tab. If the active tab is "all", return all docs.
 */
function filterDocsByTab(docs, tabValue) {
  if (tabValue === 'all') return docs;
  return docs.filter((doc) => doc.type === tabValue);
}

/**
 * Further filter documents by search query (matching title or description).
 */
function filterDocsBySearch(docs, query) {
  if (!query.trim()) return docs;
  const lowerQuery = query.toLowerCase();
  return docs.filter(
    (doc) =>
      doc.title.toLowerCase().includes(lowerQuery) ||
      (doc.linkDescription && doc.linkDescription.toLowerCase().includes(lowerQuery))
  );
}

/* ------------------------------------------------------------------
   3) Reusable Components
   ------------------------------------------------------------------ */

/**
 * CompanySearchBar lets users filter company pages.
 */
function CompanySearchBar({ searchTerm, onSearchChange, onClear }) {
  return (
    <TextField
      size="small"
      placeholder="Search company info..."
      value={searchTerm}
      onChange={onSearchChange}
      fullWidth
      sx={{
        backgroundColor: (theme) => theme.palette.grey[50],
        boxShadow: 2,
        borderRadius: 1,
        mb: 2,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
        endAdornment: searchTerm && (
          <InputAdornment
            position="end"
            sx={{ cursor: 'pointer' }}
            onClick={onClear}
          >
            <Typography variant="caption" color="primary">
              Clear
            </Typography>
          </InputAdornment>
        ),
      }}
    />
  );
}

/**
 * Tabs component for the company menu.
 */
function CompanyMenuTabs({ activeTab, onTabChange }) {
  return (
    <Tabs
      value={activeTab}
      onChange={(e, newTab) => onTabChange(newTab)}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="Company Menu Tabs"
      sx={{ mb: 2 }}
    >
      {COMPANY_TABS.map(({ label, value, icon }) => (
        <Tab
          key={value}
          label={label}
          value={value}
          icon={icon}
          iconPosition="start"
          sx={{ textTransform: 'none', fontWeight: 'medium' }}
        />
      ))}
    </Tabs>
  );
}

/**
 * Renders each "Company" item as a clickable card.
 *  
 * If a document has a "linkUrl" field, it uses that:
 *  - External URLs (starting with 'http') open in a new tab.
 *  - Internal links (starting with '/') use react-router.
 * Otherwise, it falls back to a dynamic route based on the doc's slug.
 */
function CompanyCard({ title, description, slug, linkUrl, onClose, index }) {
  const isExternalLink = linkUrl?.startsWith('http');

  let actionProps;
  if (linkUrl) {
    if (isExternalLink) {
      actionProps = {
        component: 'a',
        href: linkUrl,
        target: '_blank',
        rel: 'noopener noreferrer',
      };
    } else {
      actionProps = {
        component: Link,
        to: linkUrl,
      };
    }
  } else {
    actionProps = {
      component: Link,
      to: `/company/${slug}`,
    };
  }

  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} timeout={500 + index * 100}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': { transform: 'scale(1.02)', boxShadow: 4 },
        }}
      >
        <CardActionArea {...actionProps} onClick={onClose}>
          <CardContent>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  );
}

/* ------------------------------------------------------------------
   4) Main Mega Menu Component
   ------------------------------------------------------------------ */

export default function BigCompanyMegaMenu({ anchorEl, open, onClose }) {
  const [activeTab, setActiveTab] = useState('about');
  const [searchTerm, setSearchTerm] = useState('');
  const [companyDocs, setCompanyDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Debounce the search term to limit filtering frequency.
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Ref to ensure data is fetched only once per session.
  const fetchedOnceRef = useRef(false);

  // Fetch company documents from Firestore when the menu opens.
  useEffect(() => {
    if (!open || fetchedOnceRef.current) return;

    async function fetchCompanyData() {
      setLoading(true);
      setError(null);
      try {
        const snap = await getDocs(collection(db, 'company'));
        const docsArray = snap.docs.map((doc) => ({
          id: doc.id,
          slug: doc.id, // fallback if doc.data().slug is not present
          ...doc.data(),
        }));
        setCompanyDocs(docsArray);
        fetchedOnceRef.current = true;
      } catch (err) {
        console.error('Error fetching company documents:', err);
        setError('Unable to load company information. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    fetchCompanyData();
  }, [open]);

  // Filter documents first by active tab and then by search query.
  const filteredItems = useMemo(() => {
    const byTab = filterDocsByTab(companyDocs, activeTab);
    return filterDocsBySearch(byTab, debouncedSearchTerm);
  }, [companyDocs, activeTab, debouncedSearchTerm]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: '900px',
          maxWidth: '95vw',
          p: 0,
          borderRadius: 3,
          boxShadow: 8,
          maxHeight: '75vh',
          overflowY: 'auto',
        },
      }}
    >
      <Box
        sx={{
          p: 3,
          background: (theme) =>
            `linear-gradient(120deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[100]} 100%)`,
        }}
      >
     
        {/* Search Bar */}
        <CompanySearchBar
          searchTerm={searchTerm}
          onSearchChange={(e) => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm('')}
        />

        {/* Tabs Row */}
        <CompanyMenuTabs activeTab={activeTab} onTabChange={setActiveTab} />

        {/* Loading State: Show Skeletons */}
        {loading ? (
          <Grid container spacing={2}>
            {Array.from(new Array(6)).map((_, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={4}>
                <Skeleton variant="rectangular" height={150} sx={{ borderRadius: 2 }} />
              </Grid>
            ))}
          </Grid>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          // Main Content: Company Cards
          <Fade in timeout={350}>
            <Box>
              {filteredItems.length > 0 ? (
                <Grid container spacing={2}>
                  {filteredItems.map((docItem, idx) => (
                    <Grid key={docItem.id} item xs={12} sm={6} md={4}>
                      <CompanyCard
                        title={docItem.title}
                        description={docItem.linkDescription}
                        slug={docItem.slug}
                        linkUrl={docItem.linkUrl}
                        onClose={onClose}
                        index={idx}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  sx={{ py: 2 }}
                >
                  No pages found under “{activeTab}” matching your search.
                </Typography>
              )}
            </Box>
          </Fade>
        )}

        {/* Divider & CTA Buttons */}
        <Divider sx={{ mt: 4, mb: 3 }} />
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            component={Link}
            to="/signup"
            endIcon={<ArrowForwardIcon />}
          >
            Start Free
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            component={Link}
            to="/contact-sales"
            endIcon={<ArrowForwardIcon />}
          >
            Contact Sales
          </Button>
        </Box>
      </Box>
    </Menu>
  );
}
