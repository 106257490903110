import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Popover,
  useTheme,
  TextField,
  Divider,
  Slide,
  Collapse,
  Stack,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '/Users/willradley/orthinas-crm/src/firebase.js';
import { AuthContext } from '/Users/willradley/orthinas-crm/src/context/AuthContext.js';

const AppFooter = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);

  // --- POPUP STATE FOR TASKS & NOTES ---
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'workspace-popover' : undefined;

  // --- POPUP STATE FOR FEEDBACK ---
  const [feedbackAnchorEl, setFeedbackAnchorEl] = useState(null);
  const feedbackOpen = Boolean(feedbackAnchorEl);
  const feedbackId = feedbackOpen ? 'feedback-popover' : undefined;

  // Feedback states:
  const [feedbackRating, setFeedbackRating] = useState(null); // 'like' or 'dislike'
  const [feedbackText, setFeedbackText] = useState('');
  const [allowResponse, setAllowResponse] = useState(true);

  // Tabs state: "tasks" or "notes"
  const [activeTab, setActiveTab] = useState('tasks');

  // --- TASKS STATE & LOGIC ---
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({
    subject: '',
    description: '',
    assigned_to: '',
    due_date: '',
  });
  const [filter, setFilter] = useState('all');
  const [showCreateTaskForm, setShowCreateTaskForm] = useState(false);

  // Listen to tasks from Firestore (objectType: tasks-vSZfXz)
  useEffect(() => {
    if (!currentUser) return;
    const q = query(
      collection(db, 'objects'),
      where('userId', '==', currentUser.uid),
      where('objectType', '==', 'tasks-vSZfXz')
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const tasksData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTasks(tasksData);
    });
    return () => unsubscribe();
  }, [currentUser]);

  // Optimize filtering with useMemo.
  const today = useMemo(() => new Date().toISOString().slice(0, 10), []);
  const filteredTasks = useMemo(() => {
    return tasks.filter((task) => {
      if (filter === 'all') return true;
      if (filter === 'today') return task.due_date === today;
      if (filter === 'overdue') return task.due_date < today && !task.completed;
      return true;
    });
  }, [tasks, filter, today]);

  const handleNewTaskChange = (field, value) => {
    setNewTask((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddTask = async () => {
    if (!newTask.subject.trim()) return; // Require at least a subject.
    try {
      await addDoc(collection(db, 'objects'), {
        subject: newTask.subject.trim(),
        description: newTask.description.trim(),
        assigned_to: newTask.assigned_to.trim(),
        due_date: newTask.due_date,
        userId: currentUser.uid,
        objectType: 'tasks-vSZfXz',
        completed: false,
        createdAt: serverTimestamp(),
      });
      setNewTask({
        subject: '',
        description: '',
        assigned_to: '',
        due_date: '',
      });
      setShowCreateTaskForm(false);
      setSnackbar({
        open: true,
        message: 'Task added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding task:', error);
      setSnackbar({
        open: true,
        message: 'Error adding task. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleMarkComplete = async (taskId) => {
    try {
      const taskRef = doc(db, 'objects', taskId);
      await updateDoc(taskRef, {
        completed: true,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error marking task complete:', error);
    }
  };

  // --- NOTES STATE & LOGIC ---
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState({ subject: '', content: '' });
  const [showCreateNoteForm, setShowCreateNoteForm] = useState(false);

  // Listen to notes from Firestore (objectType: note-vSZfXz)
  useEffect(() => {
    if (!currentUser) return;
    const q = query(
      collection(db, 'objects'),
      where('userId', '==', currentUser.uid),
      where('objectType', '==', 'note-vSZfXz')
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotes(notesData);
    });
    return () => unsubscribe();
  }, [currentUser]);

  const handleAddNote = async () => {
    if (!newNote.subject.trim()) return; // Require a subject.
    try {
      await addDoc(collection(db, 'objects'), {
        subject: newNote.subject.trim(),
        content: newNote.content.trim(),
        userId: currentUser.uid,
        objectType: 'note-vSZfXz',
        createdAt: serverTimestamp(),
      });
      setNewNote({ subject: '', content: '' });
      setShowCreateNoteForm(false);
      setSnackbar({
        open: true,
        message: 'Note added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error adding note:', error);
      setSnackbar({
        open: true,
        message: 'Error adding note. Please try again.',
        severity: 'error',
      });
    }
  };

  // Snackbar state for feedback messages.
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // --- OPEN/CLOSE HANDLERS FOR TASKS & NOTES POPUP ---
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // When closing, hide both task and note forms.
  const handleClose = () => {
    setAnchorEl(null);
    setShowCreateTaskForm(false);
    setShowCreateNoteForm(false);
  };

  // --- OPEN/CLOSE HANDLERS FOR FEEDBACK POPUP ---
  const handleFeedbackClick = (event) => {
    setFeedbackAnchorEl(event.currentTarget);
  };

  const handleFeedbackClose = () => {
    setFeedbackAnchorEl(null);
  };

  // --- SUBMIT FEEDBACK ---
  const handleSubmitFeedback = async () => {
    if (!feedbackRating) return; // Require a rating selection.
    try {
      await addDoc(collection(db, 'feedback'), {
        rating: feedbackRating, // 'like' or 'dislike'
        additionalFeedback: feedbackText.trim(),
        allowResponse,
        userId: currentUser.uid,
        createdAt: serverTimestamp(),
      });
      // Reset feedback states.
      setFeedbackRating(null);
      setFeedbackText('');
      setAllowResponse(true);
      handleFeedbackClose();
      setSnackbar({
        open: true,
        message: 'Feedback submitted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSnackbar({
        open: true,
        message: 'Error submitting feedback. Please try again.',
        severity: 'error',
      });
    }
  };

  return (
    <>
      {/* Placeholder element to create a gap between page content and the fixed footer.
          Adjust the height (e.g., 64px) as needed. */}
      <Box sx={{ height: '64px' }} />

      {/* Slim, sticky footer */}
      <Box
        component="footer"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: '48px',
          backgroundColor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          alignItems: 'center',
          px: 3,
          boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
          zIndex: theme.zIndex.appBar,
        }}
      >
        <Button
          onClick={handleClick}
          startIcon={<ListAltIcon />}
          color="primary"
          size="small"
          sx={{ textTransform: 'none' }}
          aria-haspopup="true"
          aria-controls={id}
          aria-label="Open tasks and notes workspace"
        >
          Tasks &amp; Notes
        </Button>
        <Button
          onClick={handleFeedbackClick}
          color="primary"
          size="small"
          sx={{ textTransform: 'none', ml: 2 }}
          aria-haspopup="true"
          aria-controls={feedbackId}
          aria-label="Open feedback form"
        >
          Leave Feedback
        </Button>
      </Box>

      {/* Popover that slides up from the footer for Tasks & Notes */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        PaperProps={{
          sx: {
            width: 350,
            p: 2,
            borderRadius: 2,
            boxShadow: theme.shadows[5],
            mt: 1,
            position: 'relative',
            minHeight: 750,
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: -8,
              left: 24,
              width: 16,
              height: 16,
              bgcolor: 'background.paper',
              transform: 'rotate(45deg)',
              zIndex: -1,
              border: `1px solid ${theme.palette.divider}`,
            },
          },
        }}
      >
        <Box>
          {/* Header */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Tasks &amp; Notes
            </Typography>
            <IconButton onClick={handleClose} size="small" aria-label="Close workspace">
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />

          {/* Tabs to switch between Tasks and Notes */}
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            sx={{ mb: 2 }}
          >
            <Tab label="To Do List" value="tasks" />
            <Tab label="Notes" value="notes" />
          </Tabs>

          {/* --- Tasks Section --- */}
          {activeTab === 'tasks' && (
            <>
              {/* Filter Buttons & Toggle Create Task Icon */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant={filter === 'all' ? 'contained' : 'outlined'}
                    size="small"
                    onClick={() => setFilter('all')}
                    sx={{ textTransform: 'none' }}
                  >
                    All
                  </Button>
                  <Button
                    variant={filter === 'today' ? 'contained' : 'outlined'}
                    size="small"
                    onClick={() => setFilter('today')}
                    sx={{ textTransform: 'none' }}
                  >
                    Due Today
                  </Button>
                  <Button
                    variant={filter === 'overdue' ? 'contained' : 'outlined'}
                    size="small"
                    onClick={() => setFilter('overdue')}
                    sx={{ textTransform: 'none' }}
                  >
                    Overdue
                  </Button>
                </Stack>
                <IconButton
                  onClick={() => setShowCreateTaskForm((prev) => !prev)}
                  aria-label={showCreateTaskForm ? 'Cancel create task' : 'Create new task'}
                >
                  {showCreateTaskForm ? <CloseIcon /> : <AddIcon />}
                </IconButton>
              </Box>

              {/* Create Task Form (collapsible) */}
              <Collapse in={showCreateTaskForm}>
                <Stack spacing={2} mb={2}>
                  <TextField
                    label="Subject"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newTask.subject}
                    onChange={(e) => handleNewTaskChange('subject', e.target.value)}
                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1 }}
                  />
                  <TextField
                    label="Description"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newTask.description}
                    onChange={(e) => handleNewTaskChange('description', e.target.value)}
                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1 }}
                  />
                  <TextField
                    label="Assigned To"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newTask.assigned_to}
                    onChange={(e) => handleNewTaskChange('assigned_to', e.target.value)}
                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1 }}
                  />
                  <TextField
                    label="Due Date"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="date"
                    value={newTask.due_date}
                    onChange={(e) => handleNewTaskChange('due_date', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddTask}
                    sx={{ textTransform: 'none' }}
                  >
                    Add Task
                  </Button>
                </Stack>
              </Collapse>

              {/* Task List */}
              <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
                {filteredTasks.length > 0 ? (
                  filteredTasks.map((task) => (
                    <ListItem
                      key={task.id}
                      secondaryAction={
                        !task.completed && (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleMarkComplete(task.id)}
                            startIcon={<CheckIcon />}
                            sx={{ textTransform: 'none' }}
                          >
                            Complete
                          </Button>
                        )
                      }
                      sx={{
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        py: 1,
                        '&:hover': { backgroundColor: theme.palette.action.hover },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link
                            to={`/edit/${task.objectType}/${task.id}`}
                            onClick={handleClose}
                            style={{
                              textDecoration: task.completed ? 'line-through' : 'none',
                              color: task.completed ? theme.palette.text.disabled : theme.palette.text.primary,
                            }}
                          >
                            {task.subject || 'Untitled Task'}
                          </Link>
                        }
                        secondary={task.due_date ? `Due: ${task.due_date}` : ''}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No tasks available.
                  </Typography>
                )}
              </List>
            </>
          )}

          {/* --- Notes Section --- */}
          {activeTab === 'notes' && (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Notes
                </Typography>
                <IconButton
                  onClick={() => setShowCreateNoteForm((prev) => !prev)}
                  aria-label={showCreateNoteForm ? 'Cancel create note' : 'Create new note'}
                >
                  {showCreateNoteForm ? <CloseIcon /> : <AddIcon />}
                </IconButton>
              </Box>

              {/* Create Note Form (collapsible) */}
              <Collapse in={showCreateNoteForm}>
                <Stack spacing={2} mb={2}>
                  <TextField
                    label="Subject"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newNote.subject}
                    onChange={(e) => setNewNote({ ...newNote, subject: e.target.value })}
                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1 }}
                  />
                  <TextField
                    label="Content"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    rows={4}
                    value={newNote.content}
                    onChange={(e) => setNewNote({ ...newNote, content: e.target.value })}
                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNote}
                    sx={{ textTransform: 'none' }}
                  >
                    Add Note
                  </Button>
                </Stack>
              </Collapse>

              {/* Notes List */}
              <List sx={{ maxHeight: 200, overflowY: 'auto' }}>
                {notes.length > 0 ? (
                  notes.map((note) => (
                    <ListItem
                      key={note.id}
                      sx={{
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        py: 1,
                        '&:hover': { backgroundColor: theme.palette.action.hover },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Link
                            to={`/edit/${note.objectType}/${note.id}`}
                            onClick={handleClose}
                            style={{
                              textDecoration: 'none',
                              color: theme.palette.text.primary,
                            }}
                          >
                            {note.subject || 'Untitled Note'}
                          </Link>
                        }
                        secondary={
                          note.content
                            ? note.content.length > 50
                              ? note.content.slice(0, 50) + '...'
                              : note.content
                            : ''
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No notes available.
                  </Typography>
                )}
              </List>
            </>
          )}
        </Box>
      </Popover>

      {/* Popover for Feedback Form */}
      <Popover
        id={feedbackId}
        open={feedbackOpen}
        anchorEl={feedbackAnchorEl}
        onClose={handleFeedbackClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{
          sx: {
            width: 300,
            p: 2,
            borderRadius: 2,
            boxShadow: theme.shadows[5],
          },
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Feedback
            </Typography>
            <IconButton onClick={handleFeedbackClose} size="small" aria-label="Close feedback form">
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          {/* Rating Buttons */}
          <Stack direction="row" spacing={1} mb={2}>
            <Button
              variant={feedbackRating === 'like' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setFeedbackRating('like')}
              sx={{ textTransform: 'none' }}
            >
              Like it
            </Button>
            <Button
              variant={feedbackRating === 'dislike' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setFeedbackRating('dislike')}
              sx={{ textTransform: 'none' }}
            >
              Don't like
            </Button>
          </Stack>
          {/* Additional Feedback Box */}
          <TextField
            label="Additional Feedback (optional)"
            multiline
            rows={3}
            variant="outlined"
            size="small"
            fullWidth
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1, mb: 2 }}
          />
          {/* Permission Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={allowResponse}
                onChange={(e) => setAllowResponse(e.target.checked)}
                color="primary"
              />
            }
            label="Allow our team to respond to my feedback"
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitFeedback}
            sx={{ textTransform: 'none' }}
            fullWidth
          >
            Submit Feedback
          </Button>
        </Box>
      </Popover>

      {/* Snackbar for user feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AppFooter;
