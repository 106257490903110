// File: src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import useUserProfileCheck from '../hooks/useUserProfileCheck';
import { useSubscription } from '../context/SubscriptionContext';

// A simple loading spinner (customize as needed)
function LoadingSpinner({ message = 'Loading...' }) {
  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <p>{message}</p>
    </div>
  );
}

/**
 * ProtectedRoute
 * 
 * Props:
 * - children: The protected content to render.
 * - contacts (optional): Additional contacts for profile checking.
 * - skipSubscriptionCheck (optional, default false): Bypass the subscription check.
 * - skipOnboardingCheck (optional, default false): Bypass the profile/staffApproval check.
 */
export default function ProtectedRoute({
  children,
  contacts = [],
  skipSubscriptionCheck = false,
  skipOnboardingCheck = false,
}) {
  // Get authentication state from Firebase.
  const [user, loadingAuth, authError] = useAuthState(auth);

  // Listen for the user's staff approval status from Firestore.
  const [staffApproval, setStaffApproval] = useState(null);
  const [loadingStaffApproval, setLoadingStaffApproval] = useState(true);

  useEffect(() => {
    if (!user) {
      setLoadingStaffApproval(false);
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        // Use staffApproval if it’s a boolean; otherwise default to true.
        setStaffApproval(
          typeof data.staffApproval === 'boolean' ? data.staffApproval : true
        );
      } else {
        // No document? Assume approved (legacy users).
        setStaffApproval(true);
      }
      setLoadingStaffApproval(false);
    });

    return () => unsubscribe();
  }, [user]);

  // Unconditionally call useUserProfileCheck.
  const userProfile = useUserProfileCheck({
    user,
    loadingAuth,
    authError,
    db,
    contacts,
  });
  // Override the values if skipping onboarding.
  const profileChecked = skipOnboardingCheck ? true : userProfile.profileChecked;
  const profileError = skipOnboardingCheck ? null : userProfile.profileError;

  // Get subscription status.
  const { isSubscribed, loading: subscriptionLoading } = useSubscription();

  // Show a loading spinner if any asynchronous check is pending.
  if (loadingAuth || loadingStaffApproval || !profileChecked) {
    return <LoadingSpinner message="Loading..." />;
  }

  // If not authenticated, redirect to /signin.
  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  // If a profile error occurred, display it.
  if (profileError) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem', color: 'red' }}>
        <p>{profileError}</p>
      </div>
    );
  }



  // Check subscription status if required.
  if (!skipSubscriptionCheck) {
    if (subscriptionLoading) {
      return <LoadingSpinner message="Checking subscription..." />;
    }
    if (!isSubscribed) {
      return <Navigate to="/subscribe" replace />;
    }
  }

  // All checks passed; render the protected content.
  return <>{children}</>;
}
