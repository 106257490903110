import React, { useState } from 'react';
import {
  Stack,
  Tooltip,
  IconButton,
  Avatar,
  Divider,
  Button,
  Collapse,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AddBox } from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import BigProfileMegaMenu from '../Components/Navbar/BigProfileMegaMenu';
import BigSupportMegaMenu from '../Components/Navbar/BigSupportMegaMenu';
import BigGuidanceCentreMegaMenu from '../Components/Navbar/BigGuidanceCentreMegaMenu';
import NavbarSearchBar from '/Users/willradley/orthinas-crm/src/components/Navbar/NavbarSearchBar.jsx';
import GlobalActionsMenu from '/Users/willradley/orthinas-crm/src/components/Navbar/GlobalActionsMenu.jsx';
import Notifications from '../../Navbar/Notifications';
import { Link } from 'react-router-dom';

export default function DesktopLoggedInRightItems({
  userName,
  userEmail,
  userAvatar,
  planLabel,
  userId, // Logged-in user's ID
  selectedModules, // (Optional) Selected modules for Guidance Centre recommendations
}) {
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [supportAnchorEl, setSupportAnchorEl] = useState(null);
  const [globalActionsAnchorEl, setGlobalActionsAnchorEl] = useState(null);
  const [guidanceAnchorEl, setGuidanceAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleProfileMenuOpen = (e) => setProfileAnchorEl(e.currentTarget);
  const handleProfileMenuClose = () => setProfileAnchorEl(null);

  const handleSupportMenuOpen = (e) => setSupportAnchorEl(e.currentTarget);
  const handleSupportMenuClose = () => setSupportAnchorEl(null);

  const handleGlobalActionsOpen = (e) => setGlobalActionsAnchorEl(e.currentTarget);
  const handleGlobalActionsClose = () => setGlobalActionsAnchorEl(null);

  const handleGuidanceMenuOpen = (e) => setGuidanceAnchorEl(e.currentTarget);
  const handleGuidanceMenuClose = () => setGuidanceAnchorEl(null);

  const displayName = userName || '';
  const fallbackLetter = userName && userName !== 'User Name' ? userName.charAt(0) : '';

  // Define a consistent style for all IconButtons and icons
  const iconButtonStyle = { p: 0.3, color: 'white' };
  const iconStyle = { fontSize: 24 };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* Collapsed Search Bar */}
      {searchOpen ? (
        <Collapse in={searchOpen} orientation="horizontal">
          <Stack direction="row" spacing={1} alignItems="center">
            <NavbarSearchBar userId={userId} />
            <IconButton onClick={() => setSearchOpen(false)} sx={iconButtonStyle}>
              <CloseIcon sx={iconStyle} />
            </IconButton>
          </Stack>
        </Collapse>
      ) : (
        <IconButton onClick={() => setSearchOpen(true)} sx={iconButtonStyle}>
          <SearchIcon sx={iconStyle} />
        </IconButton>
      )}

      {/* Global Actions Icon Button */}
      <Tooltip title="Global Actions" arrow>
        <IconButton onClick={handleGlobalActionsOpen} sx={iconButtonStyle}>
          <AddBox sx={iconStyle} />
        </IconButton>
      </Tooltip>
      <GlobalActionsMenu
        anchorEl={globalActionsAnchorEl}
        open={Boolean(globalActionsAnchorEl)}
        onClose={handleGlobalActionsClose}
      />

      {/* Analysis & Reports Icon Button */}
      <Tooltip title="Analysis & Reports" arrow>
        <IconButton component={Link} to="/reports" sx={iconButtonStyle}>
          <BarChartIcon sx={iconStyle} />
        </IconButton>
      </Tooltip>

      {/* Notifications Component */}
      <Notifications userId={userId} />

      <Divider orientation="vertical" flexItem sx={{ mx: 1, borderColor: 'grey.300' }} />

      {/* Guidance Centre Icon Button */}
      <Tooltip title="Guidance Centre" arrow>
        <IconButton onClick={handleGuidanceMenuOpen} sx={iconButtonStyle}>
          <LightbulbCircleIcon sx={iconStyle} />
        </IconButton>
      </Tooltip>
      <BigGuidanceCentreMegaMenu
        anchorEl={guidanceAnchorEl}
        open={Boolean(guidanceAnchorEl)}
        onClose={handleGuidanceMenuClose}
        selectedModules={selectedModules}
      />

      {/* Orthinas Connect Icon Button */}
      <Tooltip title="Orthinas Connect" arrow>
        <IconButton component={Link} to="/orthinas-connect" sx={iconButtonStyle}>
          <ChatBubbleOutlineIcon sx={iconStyle} />
        </IconButton>
      </Tooltip>

      {/* Support Icon Button */}
      <Tooltip title="Help & Support" arrow>
        <IconButton onClick={handleSupportMenuOpen} sx={iconButtonStyle}>
          <HelpOutlineIcon sx={iconStyle} />
        </IconButton>
      </Tooltip>
      <BigSupportMegaMenu
        anchorEl={supportAnchorEl}
        open={Boolean(supportAnchorEl)}
        onClose={handleSupportMenuClose}
      />

      {/* Upgrade Button */}
      <Tooltip title="Upgrade your account" arrow>
        <Button
          component={Link}
          to="/account"
          variant="contained"
          size="small"
          startIcon={<RocketLaunchIcon sx={iconStyle} />}
          sx={{
            textTransform: 'none',
            borderRadius: '20px',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            color: 'white',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
            px: 2,
            py: 0.5,
            fontWeight: 'bold',
            '&:hover': {
              background: 'linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)',
            },
          }}
        >
          Upgrade
        </Button>
      </Tooltip>

      {/* Profile Icon Button */}
      <Tooltip title="Open profile menu" arrow>
        <IconButton onClick={handleProfileMenuOpen} sx={iconButtonStyle}>
          <Avatar src={userAvatar} alt={displayName}>
            {userAvatar ? '' : fallbackLetter}
          </Avatar>
        </IconButton>
      </Tooltip>
      <BigProfileMegaMenu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={handleProfileMenuClose}
      />
    </Stack>
  );
}
