import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PieChartIcon from '@mui/icons-material/PieChart';
import AssignmentIcon from '@mui/icons-material/Assignment'; // Icon for "New Task"
import { Link } from 'react-router-dom';

const GlobalActionsMenu = ({ anchorEl, open, onClose, onNewTaskClick }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          boxShadow: 3,
          borderRadius: 2,
          mt: 1,
          minWidth: 220,
        },
      }}
    >
      <MenuItem
        component={Link}
        to="/create/tasks-vSZfXz"
        onClick={onClose}
      >
        <ListItemIcon>
          <AssignmentIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="New Task" />
      </MenuItem>




      <MenuItem
        component={Link}
        to="/create/contact-vSZfXz"
        onClick={onClose}
      >
        <ListItemIcon>
          <PersonAddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Create Contact" />
      </MenuItem>



      <MenuItem
        component={Link}
        to="/create/opportunity-vSZfXz"
        onClick={onClose}
      >
        <ListItemIcon>
          <BusinessCenterIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Create Opportunity" />
      </MenuItem>



      <MenuItem
        component={Link}
        to="/reports/create"
        onClick={onClose}
      >
        <ListItemIcon>
          <PieChartIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Create Report" />
      </MenuItem>


      {/* Add more items with corresponding links as needed */}
    </Menu>
  );
};

export default GlobalActionsMenu;
