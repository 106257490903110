// File: src/routes/publicRoutes.js

import React, { lazy } from 'react';

// Admin pages (publicly accessible for some reason?)
import AdminAddHelpArticlePage from '../pages/Users/AdminOnly/AdminAddHelpArticlePage.jsx';
import AdminDashboard from '../pages/Users/AdminOnly/AdminDashboard.jsx';

// Lazy load main public pages
const Home = lazy(() => import('../pages/Resources/Sales/Home/Home.js'));
const SignIn = lazy(() => import('../pages/Auth/SignIn'));
const SignUp = lazy(() => import('../pages/Auth/SignUpWizard.jsx'));
const ContactSales = lazy(() => import('../pages/Resources/Sales/ContactSales.js'));
const PrivacyPolicyPage = lazy(() => import('../pages/Resources/Policy/PrivacyPolicyPage.js'));
const TermsOfServicePage = lazy(() => import('../pages/Resources/Policy/TermsOfServicePage.js'));
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'));
const Solutions = lazy(() => import('../pages/Resources/Solutions/SolutionsMainPage/Solutions.js'));
const Resources = lazy(() => import('../pages/Resources/Resources.js'));
const FeaturesOverviewPage = lazy(() =>
  import('../pages/Resources/Sales/Features/FeaturesOverviewPage.js')
);
const FeatureDetail = lazy(() =>
  import('../pages/Resources/Sales/Features/FeatureDetail/FeatureDetailPage.js')
);
const ROICalculator = lazy(() => import('../pages/Resources/Sales/Calculators/ROICalculator.js'));
const KnowledgeBase = lazy(() =>
  import('../pages/Resources/support/KnowledgeBaseArticleDetailPage.jsx')
);
const KnowledgeBaseArticleDetailPage = lazy(() =>
  import('../pages/Resources/support/KnowledgeBaseArticleDetailPage.jsx')
);
const SponsorDownloads = lazy(() => import('../pages/Resources/Downloads/SponsorDownloads.js'));
const ScheduleMeeting = lazy(() =>
  import('../pages/Resources/Sales/ScheduleMeeting/ScheduleMeeting.js')
);
const SolutionDetailPage = lazy(() =>
  import('../pages/Resources/Solutions/SolutionDetailPage.jsx')
);
const Blog = lazy(() => import('../pages/Resources/Blog/Blog.js'));
const PartnerProgram = lazy(() =>
  import('../pages/Resources/Sales/PartnerProgram/PartnerProgram.js')
);
const BlogDetail = lazy(() => import('../pages/Resources/Blog/BlogDetail.jsx'));
const BlogByCategory = lazy(() => import('../pages/Resources/Blog/BlogByCategory.jsx'));
const OpenTicketPage = lazy(() => import('../pages/Resources/support/OpenTicketPage.jsx'));
const ContactSupportPage = lazy(() => import('../pages/Resources/support/ContactSupportPage.jsx'));
const HelpCenterPage = lazy(() => import('../pages/Resources/support/HelpCenterPage.jsx'));
const CustomerListPage = lazy(() => import('../pages/Resources/Customers/CustomerListPage.jsx'));
const CustomerDetailPage = lazy(() => import('../pages/Resources/Customers/CustomerDetailPage.jsx'));
const CompanyDetailPage = lazy(() => import('../pages/Resources/company/CompanyDetailPage.jsx'));
const SolutionPricingPage = lazy(() => import('../pages/Resources/Solutions/SolutionPricingPage.jsx'));
const SearchPage = lazy(() => import('/Users/willradley/orthinas-crm/src/pages/Resources/SearchPage.jsx'));

// Orthinaut Community routes
const OrthinautCommunity = lazy(() =>
  import('../pages/Resources/OrthinautCommunity/OrthinautCommunity.jsx')
);
const QuestionDetail = lazy(() =>
  import('../pages/Resources/OrthinautCommunity/QuestionDetail.jsx')
);

// Orthinaut Apps routes
const OrthinautApps = lazy(() => import('../pages/Resources/OrthinautApps/OrthinautApps.jsx'));
const OrthinautAppDetail = lazy(() =>
  import('../pages/Resources/OrthinautApps/OrthinautAppDetail.jsx')
);

// Public EngageHub
const PublicEngageHubPage = lazy(() =>
  import('../pages/Users/EngageHub/PublicEngageHubPage.jsx')
);

// NEW: Public Form Page
const PublicFormPage = lazy(() =>
  import('/Users/willradley/orthinas-crm/src/pages/Users/Objects/PublicFormPage.jsx')
);

export const publicRoutes = [
  // Admin / special public routes
  { path: '/admin/help-articles/create', element: <AdminAddHelpArticlePage /> },
  { path: '/admin/dashboard', element: <AdminDashboard /> },
  {
    path: '/solutions/:slug/pricing',
    element: <SolutionPricingPage />,
  },
  // Core public pages
  { path: '/', element: <Home /> },
  { path: '/signin', element: <SignIn /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/search', element: <SearchPage /> },

  // Sales / marketing pages
  { path: '/contact-sales', element: <ContactSales /> },
  { path: '/roi-calculator', element: <ROICalculator /> },
  { path: '/schedule-meeting', element: <ScheduleMeeting /> },
  { path: '/partnerprogram', element: <PartnerProgram /> },

  // Blogs
  { path: '/blog', element: <Blog /> },
  { path: '/blog/:postId', element: <BlogDetail /> },
  { path: '/blog/category/:categorySlug', element: <BlogByCategory /> },

  // Solutions
  { path: '/solutions', element: <Solutions /> },
  { path: '/solutions/:slug', element: <SolutionDetailPage /> },

  // Features
  { path: '/features', element: <FeaturesOverviewPage /> },
  { path: '/features/:id', element: <FeatureDetail /> },

  // Company / customers
  { path: '/company/:slug', element: <CompanyDetailPage /> },
  { path: '/customers', element: <CustomerListPage /> },
  { path: '/customers/:slug', element: <CustomerDetailPage /> },

  // Policies
  { path: '/policy/privacy', element: <PrivacyPolicyPage /> },
  { path: '/policy/terms', element: <TermsOfServicePage /> },

  // Support
  { path: '/support/help-center', element: <HelpCenterPage /> },
  { path: '/support/open-ticket', element: <OpenTicketPage /> },
  { path: '/support/contact', element: <ContactSupportPage /> },
  { path: '/knowledgebase', element: <KnowledgeBase /> },
  { path: '/knowledge-base/article/:articleId', element: <KnowledgeBaseArticleDetailPage /> },

  // Resources
  { path: '/resources', element: <Resources /> },
  { path: '/downloads', element: <SponsorDownloads /> },

  // Orthinaut Community
  { path: '/orthinaut-community', element: <OrthinautCommunity /> },
  { path: '/orthinaut-community/:questionId', element: <QuestionDetail /> },

  // Orthinaut Apps
  { path: '/orthinaut-apps', element: <OrthinautApps /> },
  { path: '/orthinaut-apps/:appId', element: <OrthinautAppDetail /> },

  // EngageHub (public)
  { path: '/engagehub/:slug', element: <PublicEngageHubPage /> },

  {
    path: '/public/:objectType/:ownerId',
    element: <PublicFormPage />,
  },
];
