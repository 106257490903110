// File: src/pages/admin/AdminDashboard.jsx
import React, { useState } from 'react';
import { Container, AppBar, Toolbar, Tabs, Tab } from '@mui/material';
import MessagesTab from './MessagesTab';
import UsersTab from './UsersTab';
import MeetingsTab from './MeetingsTab';
import NotificationsTab from './NotificationsTab';
import FeaturesTab from './FeaturesTab';
import ArticlesTab from './ArticlesTab';

export default function AdminDashboard() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <AppBar position="static" sx={{ mb: 3 }}>
        <Toolbar>
          <Tabs value={activeTab} onChange={handleTabChange} textColor="inherit" indicatorColor="secondary">
            <Tab label="Messages" />
            <Tab label="Users" />
            <Tab label="Meetings" />
            <Tab label="Notifications" />
            <Tab label="Features" />
            <Tab label="Articles" />  
          </Tabs>
        </Toolbar>
      </AppBar>
      {activeTab === 0 && <MessagesTab />}
      {activeTab === 1 && <UsersTab />}
      {activeTab === 2 && <MeetingsTab />}
      {activeTab === 3 && <NotificationsTab />}
      {activeTab === 4 && <FeaturesTab />}
      {activeTab === 5 && <ArticlesTab />}

    </Container>
  );
}
