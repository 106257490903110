// File: src/context/SubscriptionContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { db, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';

export const SubscriptionContext = createContext();

const TRIAL_PERIOD_DAYS = 30;

function getDaysSince(date) {
  if (!date) return Infinity;
  const now = new Date();
  return Math.floor((now - new Date(date)) / (1000 * 60 * 60 * 24));
}

export function SubscriptionProvider({ children }) {
  const [user, loadingUser] = useAuthState(auth);
  const [subscriptions, setSubscriptions] = useState([]);
  const [planLoading, setPlanLoading] = useState(true);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    if (loadingUser) return;
    if (!user) {
      setSubscriptions([]);
      setTrialDaysRemaining(null);
      setCurrentPlan(null);
      setPlanLoading(false);
      return;
    }

    const fetchSubscriptions = async () => {
      try {
        const subRef = collection(db, 'customers', user.uid, 'subscriptions');
        const q = query(subRef, where('status', 'in', ['trialing', 'active']));
        const snap = await getDocs(q);

        if (!snap.empty) {
          const userSubs = snap.docs.map((docSnap) => {
            const data = docSnap.data();
            let modules = [];
            let tier = null;
            let totalSeats = 0;
            const items = Array.isArray(data.items) ? data.items : [];

            items.forEach((item) => {
              const meta = item?.price?.metadata || item?.plan?.metadata || {};
              const modsString = meta.modules || meta.stripe_metadata_modules;
              if (modsString) {
                const splitted = modsString.split(',').map((m) => m.trim()).filter(Boolean);
                modules.push(...splitted);
              }
              if (!tier) {
                tier = meta.tier || meta.stripe_metadata_tier || null;
              }
              if (typeof item.quantity === 'number') {
                totalSeats += item.quantity;
              }
            });

            modules = [...new Set(modules)];
            return {
              id: docSnap.id,
              ...data,
              modules,
              tier,
              seatCount: totalSeats,
            };
          });

          setSubscriptions(userSubs);
          setTrialDaysRemaining(null);

          const activeSub = userSubs.find(
            (s) => s.status === 'active' || s.status === 'trialing'
          );

          if (activeSub) {
            let foundPlan = activeSub.tier;
            if (!foundPlan && activeSub.modules.length > 0) {
              foundPlan = activeSub.modules[0];
            }
            setCurrentPlan(foundPlan ?? null);
          } else {
            setCurrentPlan(null);
          }
        } else {
          const creationTime = user.metadata?.creationTime;
          if (creationTime) {
            const daysSince = getDaysSince(creationTime);
            if (daysSince < TRIAL_PERIOD_DAYS) {
              setTrialDaysRemaining(TRIAL_PERIOD_DAYS - daysSince);
            } else {
              setTrialDaysRemaining(0);
            }
          } else {
            setTrialDaysRemaining(0);
          }
          setSubscriptions([]);
          setCurrentPlan(null);
        }
      } catch (err) {
        console.error('Error fetching subscriptions:', err);
        setSubscriptions([]);
        setCurrentPlan(null);
      } finally {
        setPlanLoading(false);
      }
    };

    fetchSubscriptions();
  }, [user, loadingUser]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions,
        planLoading,
        trialDaysRemaining,
        currentPlan,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

// Create and export a hook for accessing subscription data.
export function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  // Determine subscription status.
  const { currentPlan, trialDaysRemaining, planLoading } = context;
  const isSubscribed = currentPlan !== null || (trialDaysRemaining !== null && trialDaysRemaining > 0);
  return { isSubscribed, loading: planLoading };
}
